import { BrowserRouter, Route, Routes, useParams } from "react-router-dom";
import "./App.css";
import "./assets/css/about-template.css";
// import "./frontend-UI/invoice/eway-bill.css"

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SignIn from "./Pages/Auth/SignIn";
import SignUp from "./Pages/Auth/SignUp";
import DashBoard from "./Pages/DashBoard/DashBoard";
import AddBlog from "./Pages/E-Commerce/Blog/AddBlog";
import Blog from "./Pages/E-Commerce/Blog/Blog";
import EditBlog from "./Pages/E-Commerce/Blog/EditBlog";
import AddBlogType from "./Pages/E-Commerce/BlogType/AddBlogType";
import BlogType from "./Pages/E-Commerce/BlogType/BlogType";
import EditBlogType from "./Pages/E-Commerce/BlogType/EditBlogType";
import AddCoupon from "./Pages/E-Commerce/Coupon/AddCoupon";
import Coupon from "./Pages/E-Commerce/Coupon/Coupon";
import EditCoupon from "./Pages/E-Commerce/Coupon/EditCoupon";
import AddTax from "./Pages/E-Commerce/Tax/AddTax";
import EditTax from "./Pages/E-Commerce/Tax/EditTax";
import Tax from "./Pages/E-Commerce/Tax/Tax";
import Account from "./Pages/hrms/Account/Account";
import Career from "./Pages/hrms/Carrer/Career";
import CareerView from "./Pages/hrms/Carrer/CareerView";
import AddCategory from "./Pages/hrms/Categories/AddCategory";
import Category from "./Pages/hrms/Categories/Category";
import EditCategory from "./Pages/hrms/Categories/EditCategory";
import Dealer from "./Pages/hrms/Dealer/Dealer";
import EditDealer from "./Pages/hrms/Dealer/EditDealer";
import Dispatch from "./Pages/hrms/Dispatch/Dispatch";
import DispatchView from "./Pages/hrms/Dispatch/DispatchView";
import ViewDispatch from "./Pages/hrms/Dispatch/ViewDispatch";
import ViewMergeDispatch from "./Pages/hrms/Dispatch/ViewMergeDispatch";
import Enquiry from "./Pages/hrms/Enquiry/Enquiry";
import EnquiryView from "./Pages/hrms/Enquiry/EnquiryView";
import AddGallery from "./Pages/hrms/Gallery/AddGallery";
import EditGallery from "./Pages/hrms/Gallery/EditGallery";
import Gallery from "./Pages/hrms/Gallery/Gallery";
import AddLogisticItems from "./Pages/hrms/Logistic/AddLogisticItems";
import Logistic from "./Pages/hrms/Logistic/Logistic";
import ViewLogisticItems from "./Pages/hrms/Logistic/ViewLogisticItems";
import AddNMStaff from "./Pages/hrms/NMStaff/AddNMStaff";
import EditNMStaff from "./Pages/hrms/NMStaff/EditNMStaff";
import NMStaff from "./Pages/hrms/NMStaff/NMStaff";
import AddOrder from "./Pages/hrms/Order/AddOrder";
import EditOrder from "./Pages/hrms/Order/EditOrder";
import Order from "./Pages/hrms/Order/Order";
import OrderView from "./Pages/hrms/Order/OrderView";
import AddPermission from "./Pages/hrms/Permission/AddPermission";
import EditPermission from "./Pages/hrms/Permission/EditPermission";
import Permission from "./Pages/hrms/Permission/Permission";
import EditProduct from "./Pages/hrms/Product/EditProduct";
import Product from "./Pages/hrms/Product/Product";
import AddRolePermission from "./Pages/hrms/RolePermission/AddRolepermission";
import EditRolePermission from "./Pages/hrms/RolePermission/EditRolePermission";
import AddRoles from "./Pages/hrms/Roles/AddRoles";
import RolePermission from "./Pages/hrms/Roles/RolePermission";
import Roles from "./Pages/hrms/Roles/Roles";
import AddSalesTarget from "./Pages/hrms/SalesTarget/AddSalesTarget";
import EditSalesTarget from "./Pages/hrms/SalesTarget/EditSalesTarget";
import SalesTargetList from "./Pages/hrms/SalesTarget/SalesTargetList";
import AddSeason from "./Pages/hrms/Season/AddSeason";
import EditSeason from "./Pages/hrms/Season/EditSeason";
import Season from "./Pages/hrms/Season/Season";
import EditSettings from "./Pages/hrms/Settings/EditSettings";
import Settings from "./Pages/hrms/Settings/Settings";
import UserPermission from "./Pages/hrms/UserPermissions/UserPermission";
import Edituserrole from "./Pages/hrms/UserRole/Edituserrole";
import Userrole from "./Pages/hrms/UserRole/Userrole";
import Userprofile from "./Pages/hrms/Userprofile/Userprofile";
import ProjectDashboard from "./Pages/project/Dashboard/Dashboard";
import AlertState from "./context/Alert/AlertState";
import AuthState from "./context/Auth/AuthState";
import LoaderState from "./context/loader/LoaderState";
import AuthMain from "./context/components/Layouts/AuthMain";
import Main from "./context/components/Layouts/Main";
import AddressForm from "./frontend-UI/AddressForm";
import ThankYouPage from "./frontend-UI/TnankYouPage";
import CareerPage from "./frontend-UI/carrerpage/CareerPage";
import Cart from "./frontend-UI/cart";
import ContactUsPage from "./frontend-UI/contactuspage/ContactUsPage";
import HomePage from "./frontend-UI/homepage/HomePage";
import ProductDetail from "./frontend-UI/productDetail";
import ProductPage from "./frontend-UI/productPage";

import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import Customer from "./Pages/E-Commerce/Customer/Customer";
import EditCustomer from "./Pages/E-Commerce/Customer/EditCustomer";
import EOrderView from "./Pages/E-Commerce/Orders/EOrderView";
import EditOrders from "./Pages/E-Commerce/Orders/EditOrders";
import Orders from "./Pages/E-Commerce/Orders/Orders";
import AddProductDetail from "./Pages/E-Commerce/ProductDetail/AddProductDetail";
import EditProductDetail from "./Pages/E-Commerce/ProductDetail/EditProductDetail";
import ProductDetails from "./Pages/E-Commerce/ProductDetail/ProductDetails";
import ViewAccount from "./Pages/hrms/Account/ViewAccount";
import ViewMergeAccount from "./Pages/hrms/Account/ViewMergeAccount";
import Reports from "./Pages/hrms/Reports/Reports";
import AboutTemplate from "./Pages/website/About-template/about-page-listing";
import AddAboutTemplate from "./Pages/website/About-template/add-about-template";
import AddLeadershipTemplate from "./Pages/website/Leadership/add-leadership-page-template";
import LeadershipTemplate from "./Pages/website/Leadership/leadership-page-template";
import MenuCreation from "./Pages/website/Menus/add-menu";
import MenuList from "./Pages/website/Menus/menu-listing";
import BlogCreation from "./Pages/website/blog/add-blog";
import BlogListing from "./Pages/website/blog/blog-listing";
import EditBlogTemplate from "./Pages/website/blog/edit-blog";
import CommonTemplate from "./Pages/website/common-template";
import AddHomeTemplate from "./Pages/website/home-template/add-home-template";
import HomeTemplate from "./Pages/website/home-template/home-template";
import "./assets/css/about-template.css";
import AboutUsPage from "./frontend-UI/aboutuspage/AboutUsPage";
import BlogPage from "./frontend-UI/blog/Blog";
import BlogDetail from "./frontend-UI/blog/BlogDetail";
import Combined from "./frontend-UI/combined";
import Dashboard from "./frontend-UI/dashboard/Dashboard";
import NewsEvents from "./frontend-UI/news&events";
import Leadership from "./frontend-UI/leadership/Leadership";
import AddProductVariant from "./Pages/E-Commerce/ProductDetail/AddProductVariant";
import NewsEventsTemplate from "./Pages/website/News & Events/news-events-listing";
import AddNewsEventsTemplate from "./Pages/website/News & Events/add-news-events";
import CartCountState from "./context/CartCount/CartCountState";
import PaymentIntegration from "./frontend-UI/paymentIntergration";
import ProductGallery from "./frontend-UI/gallery/Gallary";
import ReturnOrders from "./Pages/E-Commerce/ReturnOrder/ReturnOrder";
import AuditLogs from "./Pages/E-Commerce/audit-logs";
import TaxInvoice from './frontend-UI/invoice/tax-invoice'
import EwayInvoice from './frontend-UI/invoice/eway-bill'
import ReturnOrdersView from "./Pages/E-Commerce/ReturnOrder/ReturnOrderView";
import UserData from "./frontend-UI/dashboard/UserData";
import DashboardOrders from "./frontend-UI/dashboard/DashboardOrders";
import DashboardPeronalDetail from "./frontend-UI/dashboard/DashboardPeronalDetail";
import UserDashboard from "./frontend-UI/dashboard/UserDashboard";
import PaymentFailedPage from "./frontend-UI/PaymentFailedPage";
import Invoices from "./Pages/hrms/Invoices/Invoices";
import PriceCircular from "./Pages/hrms/PriceCircular/PriceCircular";
import AddEditPriceCircular from "./Pages/hrms/PriceCircular/AddEditPriceCircular";
import EditDispatch from "./Pages/hrms/Dispatch/EditDispatch";


// import LoaderComponent from './../src/loader/loader';
// import { LoaderProvider } from './context/loader/LoaderContext';

function App() {
  return (
    <BrowserRouter>
      <ToastContainer
        position="top-center"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <LoaderState>
        <AuthState>
          <AlertState>
            <Routes>
              <Route path="/user-panel" element={<AuthMain />}>
                <Route path="/user-panel/signin" element={<SignIn />} />
                <Route path="/user-panel/signup" element={<SignUp />} />
              </Route>
              <Route path="/admin" element={<Main />}>
                <Route path="audit-logs" element={<AuditLogs/>} />
                <Route path="dashboard" element={<DashBoard />} />

                <Route path="users" element={<NMStaff />} />
                <Route path="users/add" element={<AddNMStaff />} />
                <Route path="users/edit/:id" element={<EditNMStaff />} />
                <Route path="price-circular" element={<PriceCircular />} />
                <Route path="price-circular/add" element={<AddEditPriceCircular/>} />
                <Route path="price-circular/edit/:id" element={<AddEditPriceCircular/>} />

                <Route path="userprofile/:id" element={<Userprofile />} />
                     
                <Route path="dealer" element={<Dealer />} />
                <Route path="dealer/edit/:id" element={<EditDealer />} />

                <Route path="account" element={<Account />} />
                <Route path="invoices" element={<Invoices />} />
                <Route
                  path="account/view/:split_id/:order_id"
                  element={<ViewAccount />}
                />
                <Route
                  path="account/view/merge/:merge_id"
                  element={<ViewMergeAccount />}
                />

                <Route path="dispatch" element={<Dispatch />} />
                <Route
                  path="dispatch/view/:split_id/:order_id"
                  element={<ViewDispatch />}
                />
                <Route
                  path="dispatch/edit/:split_id/:merge_id"
                  element={<EditDispatch />}
                />
                <Route
                  path="dispatch/view/merge/:merge_id"
                  element={<ViewMergeDispatch />}
                />
                <Route path="dispatch/view/:id" element={<DispatchView />} />

                <Route path="enquiry" element={<Enquiry />} />
                <Route path="enquiry/view/:id" element={<EnquiryView />} />

                <Route path="sales-target" element={<SalesTargetList />} />
                <Route path="sales-target/add" element={<AddSalesTarget />} />
                <Route
                  path="sales-target/edit/:id/:year"
                  element={<EditSalesTarget />}
                />
                <Route path="career" element={<Career />} />
                <Route path="career/view/:id" element={<CareerView />} />

                <Route path="roles" element={<Roles />} />
                <Route path="roles/add" element={<AddRoles />} />
                <Route
                  path="roles/permissions/:roleId"
                  element={<RolePermission />}
                />
                {/* <Route path="roles/edit/:id" element={<EditRoles />} /> */}

                <Route path="category" element={<Category />} />
                <Route path="category/add" element={<AddCategory />} />
                <Route path="category/edit/:id" element={<EditCategory />} />

                <Route
                  path="permissions/:userId/:roleId"
                  element={<UserPermission />}
                />

                <Route path="permission" element={<Permission />} />
                <Route path="permission/add" element={<AddPermission />} />
                <Route
                  path="permission/edit/:id"
                  element={<EditPermission />}
                />

                <Route path="gallery" element={<Gallery />} />
                <Route path="gallery/add" element={<AddGallery />} />
                <Route path="gallery/edit/:id" element={<EditGallery />} />

                <Route path="logistics" element={<Logistic />} />
                <Route path="logistics/splite" element={<AddLogisticItems />} />
                <Route
                  path="logistics/view/:order_id/:item_id"
                  element={<ViewLogisticItems />}
                />

                <Route path="userrole" element={<Userrole />} />
                <Route path="userrole/edit/:id" element={<Edituserrole />} />

                {/* <Route path="rolepermission" element={<RolePermission />} /> */}
                <Route
                  path="rolepermission/add"
                  element={<AddRolePermission />}
                />
                <Route
                  path="rolepermission/edit/:id"
                  element={<EditRolePermission />}
                />

                <Route path="product" element={<Product />} />
                <Route path="product/edit/:id" element={<EditProduct />} />

                <Route path="season" element={<Season />} />
                <Route path="season/add" element={<AddSeason />} />
                <Route path="season/edit/:id" element={<EditSeason />} />

                <Route path="order" element={<Order />} />
                <Route path="order/add" element={<AddOrder />} />
                <Route path="order/edit/:id" element={<EditOrder />} />
                <Route path="order/view/:id" element={<OrderView />} />

                <Route path="return-order" element={<ReturnOrders/>}/>
                <Route path="return-order/view/:id" element={<ReturnOrdersView/>}/>

                <Route
                  path="project-dashboard"
                  element={<ProjectDashboard />}
                />

                <Route path="settings" element={<Settings />} />
                <Route path="settings/edit/:id" element={<EditSettings />} />

                <Route path="settings" element={<EditSettings />} />
                {/* <Route
								path="jobportal-settings"
								element={
									<JobPortalSettings
										statisticsClose="true"
										friendListClose="true"
									/>
								}
							/> */}

                {/* Reports */}
                <Route path="reports" element={<Reports />} />

                {/* E-commerce Route */}

                <Route path="tax" element={<Tax />} />
                <Route path="tax/add" element={<AddTax />} />
                <Route path="tax/edit/:id" element={<EditTax />} />

                <Route path="blogtype" element={<BlogType />} />
                <Route path="blogtype/add" element={<AddBlogType />} />
                <Route path="blogtype/edit/:id" element={<EditBlogType />} />

                <Route path="blog" element={<Blog />} />
                <Route path="blog/add" element={<AddBlog />} />
                <Route path="blog/edit/:id" element={<EditBlog />} />

                <Route path="coupon" element={<Coupon />} />
                <Route path="coupon/add" element={<AddCoupon />} />
                <Route path="coupon/edit/:id" element={<EditCoupon />} />

                <Route path="Ecom-orders" element={<Orders />} />
                <Route path="Ecom-orders/edit/:id" element={<EditOrders />} />
                <Route path="Ecom-orders/view/:id" element={<EOrderView />} />

                <Route path="productdetail" element={<ProductDetails />} />
                <Route
                  path="productdetail/add"
                  element={<AddProductDetail />}
                />
                <Route
                  path="productdetail/add/variant/:id"
                  element={<AddProductVariant />}
                />
                <Route
                  path="productdetail/edit/:id"
                  element={<EditProductDetail />}
                />
                <Route
                  path="productdetail/edit/variant/:id"
                  element={<AddProductVariant />}
                />

                <Route path="customer" element={<Customer />} />
                <Route path="customer/edit/:id" element={<EditCustomer />} />

                {/* Website Template Routes */}
                <Route path="home-template" element={<HomeTemplate />} />
                <Route path="about-template" element={<AboutTemplate />} />
                <Route path="home-template/add" element={<AddHomeTemplate />} />
                <Route
                  path="home-template/edit/:id"
                  element={<AddHomeTemplate />}
                />
                <Route
                  path="about-template/add"
                  element={<AddAboutTemplate />}
                />
                <Route
                  path="about-template/edit/:id"
                  element={<AddAboutTemplate />}
                />
                <Route path="blog-listing" element={<BlogListing />} />
                <Route path="add-blog" element={<BlogCreation />} />
                <Route path="edit-blog/:id" element={<BlogCreation />} />
                <Route
                  path="news-events-template"
                  element={<NewsEventsTemplate />}
                />
                <Route
                  path="news-events-template/add"
                  element={<AddNewsEventsTemplate />}
                />
                <Route
                  path="news-events-template/edit/:id"
                  element={<AddNewsEventsTemplate />}
                />

                <Route
                  path="leadership-template"
                  element={<LeadershipTemplate />}
                />
                <Route
                  path="leadership-template/add"
                  element={<AddLeadershipTemplate />}
                />
                <Route
                  path="leadership-template/edit/:id"
                  element={<AddLeadershipTemplate />}
                />
                <Route path="menu-listing" element={<MenuList />} />
                <Route path="menu/add" element={<MenuCreation />} />
                <Route path="menu/edit/:id" element={<MenuCreation />} />
              </Route>
              <Route path="/user" element={<Combined />}>
                <Route path="dashboard" element={<Dashboard />} />
                <Route path="my-dashboard" element={<UserDashboard/>} />
                <Route path="personal-detail" element={<DashboardPeronalDetail/>} />
                <Route path="orders" element={<DashboardOrders/>} />
              </Route>
            </Routes>
          </AlertState>
        </AuthState>
      </LoaderState>
      {/* <AuthState>
        <Routes>
        </Routes>
      </AuthState> */}
      <AlertState>
        <CartCountState>
          <Routes>
            <Route path="/" element={<Combined />}>
              {/* <Route path="/" element={<HomePage />} /> */}
              {/* <Route path="/about-us" element={<AboutUsPage />} /> */}
              {/* <Route path="news-events" element={<NewsEvents />} /> */}
              <Route path="/contact-us" element={<ContactUsPage />} />
              <Route path="/productdetail/:id" element={<ProductDetail />} />
              <Route path="/careers" element={<CareerPage />} />
              <Route path="/products" element={<ProductPage />} />
              <Route path="/address" element={<AddressForm />} />
              <Route path="/thank-you/:id" element={<ThankYouPage />} />
              <Route exact path="/cart" element={<Cart />} />
              <Route path="/gallery-list" element={<ProductGallery/>}/>
              {/* <Route path="/leadership" element={<Leadership />} /> */}
              {/* <Route path="blogs" element={<BlogPage />} /> */}
              <Route path="blog-detail" element={<BlogDetail />} />
              <Route path="news-events" element={<NewsEvents />} />
              <Route path="/paymentIntegration" element={<PaymentIntegration />} />
              <Route path="/TaxInvoice" element={<TaxInvoice />} />
              <Route path="/EwayInvoice" element={<EwayInvoice />} />
              <Route path="/:slug" element={<CommonTemplate />} />
              <Route path="/" element={<CommonTemplate />} />
              {/* <Route path="/:slug" element={<Dashboard />} /> */}
              <Route path="/payment-failed" element={<PaymentFailedPage />} />
            </Route>
          </Routes>
        </CartCountState>
      </AlertState>
      {/* <LoaderProvider>
          <LoaderComponent />
      </LoaderProvider> */}
    </BrowserRouter>
  );
}

export default App;
