import { useContext, useEffect, useState } from "react";
import { BiShow } from "react-icons/bi";
import { FaFileExcel, FaGreaterThan } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import "react-toastify/dist/ReactToastify.css";
import AlertContext from "../../../context/Alert/AlertContext";
import AuthContext from "../../../context/Auth/AuthContext";
import MergeModal from "../../../context/components/Modals/MergeModel";
import axiosPrivate from "../../../hooks/axiosPrivate";
import "./logistic.css";
import LoaderContext from "../../../context/loader/LoaderContext";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import * as XLSX from "xlsx";
import { ImCross } from "react-icons/im";
import { showErrorToast, showSuccessToast } from "../../../Toster";
import styles from "./Logistic.module.css";

const Logistic = () => {
  const { setIsLoading } = useContext(LoaderContext);
  const { checkPermissionExists, authPermissions, userDetails } =
    useContext(AuthContext);
  const { alertMessage, setAlertMessage } = useContext(AlertContext);
  const [logistics, setLogisticsState] = useState([]);
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(false);
  //State For selected items start
  const [selectedItems, setSelectedItems] = useState([]);
  const [mergerButton, setMergerButton] = useState(false);
  //State for selected items end
  const [filteredLogistics, setFilteredLogistics] = useState([]);
  const [searchQuery, setSearchQuery] = useState(""); // State for search query
  //State For Merge Modal start
  const [showModal, setShowModal] = useState(false);
  //State For Merge Modal end
  const [visible, setVisible] = useState(false);
  const [itemId, setItemId] = useState("");
  const [remainingQuantity, setRemainingQuantity] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const [toggle, setToggle] = useState(0);
  // validation
  var validationRules = {};
  // add data to api (after check validation)
  const formik = useFormik({
    initialValues: {
      remark: "",
    },
    validationSchema: Yup.object(validationRules),

    onSubmit: async (values, { setSubmitting, setFieldError }) => {
      setIsLoading(true);
      setVisible(false);
      values.dispatch_status = 2;
      values.order_item_id = itemId;
      values.cancel_quantity = remainingQuantity;
      axiosPrivate
        .post("logistics/update/cancel-status", values)
        .then((response) => {
          showSuccessToast("Item Cancelled successfully!");
          getLogisticList();
          formik.setFieldValue("remark", "");
        })
        .catch((error) => {
          if (typeof error?.response?.data?.errors == "object") {
            showErrorToast(error?.response?.data?.errors[0]?.msg);
          } else {
            showErrorToast(error?.response?.data?.errors[0]?.msg);
          }
        })
        .finally(() => {
          setIsLoading(false);
          setVisible(false);
        });
    },
  });

  const getLogisticList = () => {
    setIsLoading(true);
    if (userDetails.id != undefined) {
      axiosPrivate
        .get("logistics/list/items", {
          data: { current_user_id: userDetails.id },
        })
        .then((response) => {
          setFilteredLogistics(response?.data?.data);
          setLogisticsState(response?.data?.data);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
        });
    }
  };
  useEffect(() => {
    getLogisticList();
  }, [authPermissions, userDetails, refresh]);

  useEffect(() => {
    var acc = document.getElementsByClassName("accordion-custome");
    var i;

    for (i = 0; i < acc.length; i++) {
      acc[i].addEventListener("click", function () {
        this.classList.toggle("active-custome");
        var panel = this.nextElementSibling;
        if (panel.style.display === "block") {
          panel.style.display = "none";
        } else {
          panel.style.display = "block";
        }
      });
    }
  }, [logistics]);

  useEffect(() => {
    if (alertMessage != null) {
      toast(alertMessage.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setAlertMessage(null);
    }
  }, [alertMessage]);

  //Function to handle and add selected id start
  const selectItems = async (event, id) => {
    let oldLogisticSelectedIds = new Set(selectedItems);
    let selectedItemsArray = selectedItems;
    if (event.target.checked && oldLogisticSelectedIds.has(id) != true) {
      selectedItemsArray.push(id);
      setSelectedItems(selectedItemsArray);
    } else {
      let index = selectedItemsArray.indexOf(id);
      if (index > -1) {
        selectedItemsArray.splice(index, 1);
        setSelectedItems(selectedItemsArray);
        event.target.checked = false;
      }
    }
    if (selectedItemsArray.length > 0) {
      setMergerButton(true);
    } else {
      setMergerButton(false);
    }
    return true;
  };
  //Function to handle and add selected id end

  //Function to remove the selected items start
  const removeSelectedItems = () => {
    //Loop and remove checked items start
    document.querySelectorAll(".select-merge").forEach(function (checkBox) {
      checkBox.checked = false;
    });
    //Loop and remove checked items end

    setSelectedItems([]);
    setMergerButton(false);
    return true;
  };
  //Function to remove the selected items end

  //Merge Open Modal function start
  const openModal = (event) => {
    event.preventDefault();
    setShowModal(true);
  };
  //Merge Open Modal function end

  //Merge Close Modal function start
  const closeModal = () => {
    setShowModal(false);
    setRefresh(true);
  };
  //Merge Close Modal function end

  //Function to handle modal form submission start
  const handleConfirm = async () => {
    closeModal();
    removeSelectedItems();

    // Display a success toast message
    toast.success("Merge operation successful!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

    navigate(`/admin/logistics`);
    setRefresh(true);
    return true;
  };
  //Function to handle modal form submission end

  var acc = document.getElementsByClassName("accordion-custome");
  var i;

  for (i = 0; i < acc.length; i++) {
    acc[i].addEventListener("click", function () {
      this.classList.toggle("active-custome");
      var panel = this.nextElementSibling;
      if (panel.style.display === "block") {
        panel.style.display = "none";
      } else {
        panel.style.display = "block";
      }
    });
  }

  useEffect(() => {
    const filterData = logistics.map((logistic) => ({
      ...logistic,
      group_items: logistic.group_items.filter((item) =>
        item.order_items.some(
          (order) =>
            order?.product_name
              ?.toLowerCase()
              ?.includes(searchQuery?.toLowerCase()) ||
            order?.group?.order_no?.includes(searchQuery) ||
            order?.group?.dealer_name
              ?.toLowerCase()
              ?.includes(searchQuery?.toLowerCase()) ||
            order?.shipping_state
              ?.toLowerCase()
              ?.includes(searchQuery?.toLowerCase())
        )
      ),
    }));
    setFilteredLogistics(
      filterData.filter((logistic) => logistic.group_items?.length > 0)
    );
  }, [searchQuery, logistics]);

  useEffect(() => {
    const filteredData = filteredLogistics
      .map((logistic) => ({
        ...logistic,
        group_items: logistic?.group_items
          ?.map((item) => ({
            ...item,
            order_items: item.order_items.filter((orderItem) =>
              toggle === 0
                ? (orderItem.dispatch_status !== 2 && item?.group?.order_status === "to_logistics")
                : orderItem.dispatch_status === 2
            ),
          }))
          .filter((item) => item.order_items.length > 0), // Ensure group_items with valid order_items only
      }))
      .filter((logistic) => logistic.group_items.length > 0); // Ensure logistics with valid group_items only

    setFilteredData(filteredData);
  }, [filteredLogistics, toggle]);

  const handleToggle = () => setToggle((prev) => (prev === 0 ? 1 : 0));

  const handleDownloadExcel = () => {
    // const data = filteredData?.map((logistic) => ({
    //   "Dealer Name": logistic?.group_items
    //     .map((item) => item?.group?.dealer_name)
    //     .join(", "),
    //   "Order Number": logistic.group_items
    //     .map((item) => item.group?.order_no)
    //     .join(", "),
    //   "Product Names": logistic.group_items
    //     .flatMap((item) => item.order_items.map((order) => order.product_name))
    //     .join(", "),
    //   "Remaining Quantity": logistic.group_items
    //     .flatMap((item) =>
    //       item.order_items.map((order) => order.remaining_quantity)
    //     )
    //     .join(", "),
    // }));

    // const worksheet = XLSX.utils.json_to_sheet(data);
    // const workbook = XLSX.utils.book_new();
    // XLSX.utils.book_append_sheet(workbook, worksheet, "Logistics");

    // XLSX.writeFile(workbook, "Logistic-Items.xlsx");

    // Define headers for the data
    const header = [
      "Dealer Name.",
      "Order Number",
      "Product Names",
      "Remaining Quantity",
    ];

    // Prepare rows based on the specific fields you mentioned
    const finalData = filteredData
      ?.flatMap((item, index) => {
        return item?.group_items;
      })
      ?.flatMap((item, index) => {
        return item?.order_items;
      });

    const rows = finalData?.map((item, index) => [
      item?.group?.dealer_name,
      item.group?.order_no,
      item?.product_name,
      item?.remaining_quantity,
    ]);

    // Create a worksheet and include the header and data rows
    const ws = XLSX.utils.aoa_to_sheet([header, ...rows]);

    // Create a workbook
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // Download the file
    XLSX.writeFile(wb, "Logistic-Items.xlsx");
  };

  return (
    <>
      <div className="section-body ">
        <div className="container-fluid">
          {/* hearder breadcrumb */}
          <div className="d-lg-flex justify-content-between align-items-center">
            <div className="card-header">
              <strong className="top-left-heading">Logistics</strong>

              <ol className="headerroutes breadcrumb m-0 bg-none ">
                <li className="item color-gray">|</li>
                <li className="item">
                  <Link
                    to="/admin/dashboard"
                    className="route-heading item mx-2"
                  >
                    Dashboard
                  </Link>
                </li>
                <li className="item">
                  <FaGreaterThan className="grater-than-route" />
                </li>
                <li className="route-heading item mx-2 mt-1">Logistics</li>
              </ol>
            </div>
            <div className="d-flex align-items-center">
              <Button
                icon={<FaFileExcel />}
                label="Download Excel"
                className="p-button-success"
                onClick={handleDownloadExcel}
              />
              <InputText
                placeholder="Search ..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="ml-3"
              />
            </div>
          </div>
          <div className="tab-content ">
            <div
              className="tab-pane fade show active"
              id="user-list"
              role="tabpanel"
            >
              <MergeModal
                isOpen={showModal}
                onConfirm={handleConfirm}
                onCancel={closeModal}
                orderItemsIds={selectedItems}
              />
              <div className="card my-0 py-0">
                {mergerButton && authPermissions.includes("Logistic-Create") ? (
                  <div className="card-header">
                    <div className="col-md-6 d-flex flex-row justify-content-end">
                      <button
                        className={`btn btn-sm btn-round btn-primary`}
                        onClick={(event) => openModal(event)}
                      >
                        Merge
                      </button>
                      <button
                        type="button"
                        className="btn btn-sm btn-round btn-default1 ml-2"
                        onClick={(event) => {
                          removeSelectedItems();
                        }}
                      >
                        Clear Selected
                      </button>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <div className="card-body">
                  <div className={styles.toggleContainer}>
                    <div>Pending</div>
                    <div className={styles.toggleSwitch} onClick={handleToggle}>
                      <div
                        className={`${styles.knob} ${
                          toggle === 1 ? styles.active : ""
                        }`}
                      ></div>
                    </div>
                    <div>Cancelled</div>
                  </div>
                  {filteredData?.length > 0
                    ? filteredData.map((logistic, index) => {
                        const isDispatchableItem = logistic?.group_items
                          ?.flatMap((item) => item?.order_items)
                          ?.filter((item) => item?.dispatch_status !== 2);

                        return logistic.group_items.length > 0 ? (
                          <div
                            key={`${logistic.group_name}-${index}`}
                            style={{ padding: "0px 0px 6px 0px" }}
                          >
                            <button className="accordion-custome">
                              <div className="d-flex">
                                <span className="mr-auto p-2">
                                  Dealers Location {logistic.group_name}
                                </span>
                                {isDispatchableItem?.length > 0 &&
                                authPermissions.includes("Logistic-Create") ? (
                                  <button
                                    onClick={(event) => {
                                      event.preventDefault();
                                      navigate(
                                        `/admin/logistics/splite?slug=${logistic.group_name}`
                                      );
                                    }}
                                    className="btn btn-sm btn-primary px-2 mt-1 mx-2"
                                    style={{
                                      padding: "-0.8rem 0.5rem !important",
                                      height: "100%",
                                    }}
                                  >
                                    Add Dispatch
                                  </button>
                                ) : null}
                              </div>
                            </button>
                            <div className="panel">
                              <div className="flex">
                                {(() => {
                                  let htmlDataFirst = [];
                                  for (
                                    let i = 0;
                                    i < logistic.group_items.length;
                                    i++
                                  ) {
                                    htmlDataFirst.push(
                                      <div className="caret">
                                        <span style={{ display: "none" }}>
                                          {
                                            logistic.group_items[i].group
                                              .dealer_name
                                          }{" "}
                                          (Order No: #
                                          {
                                            logistic.group_items[i].group
                                              .order_no
                                          }
                                          )
                                        </span>
                                        <div>
                                          <table className="table">
                                            <thead>
                                              <tr>
                                                <td>
                                                  <b>
                                                    {
                                                      logistic.group_items[i]
                                                        .group.dealer_name
                                                    }{" "}
                                                  </b>
                                                  <b>
                                                    (Order No: #
                                                    {
                                                      logistic.group_items[i]
                                                        .group.order_no
                                                    }
                                                  </b>
                                                  )
                                                </td>

                                                <td>
                                                  <b>Product</b>
                                                </td>
                                                <td>
                                                  <b>Remaining Quantity</b>
                                                </td>

                                                <td>
                                                  <b>Order Remarks</b>
                                                </td>
                                                <td>
                                                  {toggle === 1 && (
                                                    <b>Cancellation Remarks</b>
                                                  )}
                                                </td>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {(() => {
                                                let htmlData = [];
                                                for (
                                                  let m = 0;
                                                  m <
                                                  logistic.group_items[i]
                                                    .order_items.length;
                                                  m++
                                                ) {
                                                  htmlData.push(
                                                    <tr>
                                                      {logistic.group_items[i]
                                                        .order_items[m]
                                                        .dispatch_type ==
                                                        null ||
                                                      logistic.group_items[i]
                                                        .order_items[m]
                                                        .dispatch_type ===
                                                        "splited" ? (
                                                        <>
                                                          <td></td>
                                                          <td>
                                                            {logistic
                                                              .group_items[i]
                                                              .order_items[m]
                                                              .dispatch_status !==
                                                              2 && (
                                                              <input
                                                                type="checkbox"
                                                                onChange={(
                                                                  event
                                                                ) =>
                                                                  selectItems(
                                                                    event,
                                                                    logistic
                                                                      .group_items[
                                                                      i
                                                                    ]
                                                                      .order_items[
                                                                      m
                                                                    ]
                                                                      .order_item_id
                                                                  )
                                                                }
                                                                className={`select-merge select-merge-checked-${logistic.group_items[i].order_items[m].order_item_id}`}
                                                                name="select-merger"
                                                                defaultChecked={
                                                                  false
                                                                }
                                                              />
                                                            )}

                                                            <span>
                                                              {" "}
                                                              {
                                                                logistic
                                                                  .group_items[
                                                                  i
                                                                ].order_items[m]
                                                                  .product_name
                                                              }
                                                            </span>
                                                          </td>
                                                          <td>
                                                            {
                                                              logistic
                                                                .group_items[i]
                                                                .order_items[m]
                                                                .remaining_quantity
                                                            }
                                                          </td>

                                                          <Dialog
                                                            header="Remarks"
                                                            visible={visible}
                                                            onHide={() => {
                                                              if (!visible)
                                                                return;
                                                              setVisible(false);
                                                            }}
                                                          >
                                                            <form
                                                              onSubmit={
                                                                formik.handleSubmit
                                                              }
                                                            >
                                                              <textarea
                                                                id="remark"
                                                                name="remark"
                                                                onChange={
                                                                  formik.handleChange
                                                                }
                                                                onBlur={
                                                                  formik.handleBlur
                                                                }
                                                                value={
                                                                  formik.values
                                                                    .remark
                                                                }
                                                                placeholder="Enter Remarks"
                                                                className="form-group col-12 col-md-12 col-sm-12 col-lg-12"
                                                                rows={5}
                                                              ></textarea>
                                                              <div className="row clearfix">
                                                                {/* button close and submit */}
                                                                <div className="col-md-12 col-sm-12 submit-btn">
                                                                  <div className="form-group">
                                                                    <button
                                                                      type="button"
                                                                      className="btn btn-secondary"
                                                                      data-dismiss="modal"
                                                                      onClick={() =>
                                                                        setVisible(
                                                                          false
                                                                        )
                                                                      }
                                                                    >
                                                                      Close
                                                                    </button>
                                                                    <button
                                                                      type="submit"
                                                                      className="btn btn-primary ml-2"
                                                                    >
                                                                      Submit
                                                                    </button>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </form>
                                                          </Dialog>
                                                          <td>
                                                            {m == 0
                                                              ? logistic
                                                                  .group_items[
                                                                  i
                                                                ].group.remark
                                                              : ""}
                                                          </td>
                                                          <td
                                                            style={{
                                                              textAlign:
                                                                logistic
                                                                  .group_items[
                                                                  i
                                                                ].order_items[m]
                                                                  .dispatch_status !==
                                                                2
                                                                  ? "center"
                                                                  : "left",
                                                            }}
                                                          >
                                                            {logistic
                                                              .group_items[i]
                                                              .order_items[m]
                                                              .dispatch_status !==
                                                            2 ? (
                                                              <button
                                                                onClick={() => {
                                                                  setVisible(
                                                                    true
                                                                  );
                                                                  setRemainingQuantity(
                                                                    logistic
                                                                      .group_items[
                                                                      i
                                                                    ]
                                                                      .order_items[
                                                                      m
                                                                    ]
                                                                      .remaining_quantity
                                                                  );
                                                                  setItemId(
                                                                    logistic
                                                                      .group_items[
                                                                      i
                                                                    ]
                                                                      .order_items[
                                                                      m
                                                                    ]
                                                                      .order_item_id
                                                                  );
                                                                }}
                                                                style={{
                                                                  border:
                                                                    "none",
                                                                  backgroundColor:
                                                                    "transparent",
                                                                }}
                                                                title="Cancel Item"
                                                              >
                                                                <ImCross color="#c82333" />
                                                              </button>
                                                            ) : (
                                                              logistic
                                                                .group_items[i]
                                                                .order_items[m]
                                                                .cancellation_remarks
                                                            )}
                                                          </td>
                                                        </>
                                                      ) : (
                                                        <>
                                                          <td></td>
                                                          <td>
                                                            <span>
                                                              *{" "}
                                                              {
                                                                logistic
                                                                  .group_items[
                                                                  i
                                                                ].order_items[m]
                                                                  .product_name
                                                              }
                                                            </span>
                                                          </td>
                                                          <td>
                                                            $
                                                            {
                                                              logistic
                                                                .group_items[i]
                                                                .order_items[m]
                                                                .remaining_quantity
                                                            }
                                                          </td>
                                                        </>
                                                      )}
                                                    </tr>
                                                  );
                                                }
                                                return htmlData;
                                              })()}
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                    );
                                  }
                                  return htmlDataFirst;
                                })()}
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        );
                      })
                    : "No data found"}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Logistic;
