import { useContext, useEffect, useState } from "react";
import { BiEdit } from "react-icons/bi";
import { FaDoorClosed, FaGreaterThan } from "react-icons/fa";
import { RiDeleteBinLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { showErrorToast } from "../../../Toster";
import AlertContext from "../../../context/Alert/AlertContext";
import AuthContext from "../../../context/Auth/AuthContext";
import DataTable2 from "../../../context/components/DataTable2";
import axiosPrivate from "../../../hooks/axiosPrivate";
import LoaderContext from "../../../context/loader/LoaderContext";
import Swal from "sweetalert2";
import DeleteConfirm from "../../../delete-confirmation/DeleteConfirm";
const PriceCircular = ({ authPermission }) => {
  const { setIsLoading } = useContext(LoaderContext);
  const { checkPermissionExists, authPermissions } = useContext(AuthContext);
  const { alertMessage, setAlertMessage } = useContext(AlertContext);
  const [priceCircular, setPriceCircular] = useState([]);
  const [confirm, setConfirm] = useState(false);
  const [id, setId] = useState("");
  const headers = [
    { name: "NAME", field: "product_name", sortable: true, classKey: "" },
    {
      name: "PACKING SIZE",
      field: "packing_size",
      sortable: true,
      classKey: "",
    },
    {
      name: "BILLING PRICE",
      field: "billing_price",
      sortable: true,
      classKey: "",
    },
    {
      name: "MRP PER UNIT",
      field: "mrp_per_unit",
      sortable: true,
      classKey: "",
    },
    { name: "STATE CODE", field: "state_code", sortable: true, classKey: "" },
    { name: "ACTION", field: "action", classKey: "" },
  ];

  const searchItems = [
    "product_name",
    "packing_size",
    "billing_price",
    "mrp_per_unit",
    "state_code",
  ];
  const placeholder = [
    "product_name",
    "packing_size",
    "billing_price",
    "mrp_per_unit",
    "state_code",
  ];

  //alert message
  useEffect(() => {
    if (alertMessage != null) {
      toast(alertMessage.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setAlertMessage(null);
    }
  }, [alertMessage]);

  // delete  user function
  const priceDeleteHandler = async () => {
    axiosPrivate
      .delete(`price-circular/delete/${id}`)
      .then(async (response) => {
        if (response.data.code === 200) {
          setPriceCircular([]);
          await refreshTable();
        }
      })
      .catch((error) => {
        if (typeof error.response.data.errors == "array") {
          showErrorToast(error?.response?.data?.errors[0]?.msg);
        } else {
          showErrorToast(error?.response?.data?.errors?.msg);
        }
      });
  };

  // get All Price Circular list
  useEffect(() => {
    setIsLoading(true);
    axiosPrivate
      .get("price-circular/list")
      .then((price) => {
        const priceData = price?.data?.data?.map((value, key) => {
          let buttons = [];
          if (true && authPermissions.includes("Price Circular-Edit"))
            buttons.push(
              <Link
                key="editButton##1"
                type="button"
                to={`/admin/price-circular/edit/${value.id}`}
                className="btn btn-icon"
                title="Edit"
              >
                <BiEdit style={{ color: "green" }} size={18} />
              </Link>
            );
          if (true && authPermissions.includes(`Price Circular-Delete`))
            buttons.push(
              <button
                key="deleteButton##1"
                type="button"
                data-id={value.id}
                onClick={() => {
                  setId(value.id);
                  setConfirm(true);
                }}
                className="btn btn-icon js-sweetalert"
                title="Delete"
              >
                <RiDeleteBinLine style={{ color: "red" }} size={17} />
              </button>
            );
          value["action"] = buttons.length > 0 ? buttons : "-";
          return value;
        });

        setPriceCircular(priceData);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  }, []);

  //after delete.... refresh nmStaff list
  const refreshTable = () => {
    setIsLoading(true);
    axiosPrivate
      .get("price-circular/list")
      .then((price) => {
        const priceData = price?.data?.data?.map((value, key) => {
          let buttons = [];
          if (true && authPermissions.includes("Price Circular-Edit"))
            buttons.push(
              <Link
                key="editButton##1"
                type="button"
                to={`/admin/price-circular/edit/${value.id}`}
                className="btn btn-icon"
                title="Edit"
              >
                <BiEdit style={{ color: "green" }} size={18} />
              </Link>
            );
          if (true && authPermissions.includes(`Price Circular-Delete`))
            buttons.push(
              <button
                key="deleteButton##1"
                type="button"
                data-id={value.id}
                onClick={() => {
                  setId(value.id);
                  setConfirm(true);
                }}
                className="btn btn-icon js-sweetalert"
                title="Delete"
              >
                <RiDeleteBinLine style={{ color: "red" }} size={17} />
              </button>
            );
          value["action"] = buttons.length > 0 ? buttons : "-";
          return value;
        });

        setPriceCircular(priceData);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  return (
    <>
      {confirm && (
        <DeleteConfirm
          deleteMethod={priceDeleteHandler}
          setConfirm={setConfirm}
          text={"By clicking 'Yes', this user will be permanently deleted."}
          success={"User has been deleted."}
          cancel={"User deletion cancelled."}
        />
      )}
      <div className="section-body ">
        <div className="container-fluid">
          <div className="mx-4">
            <div className="row justify-content-between align-items-center text-center ">
              {/* hearder breadcrumb */}
              <div className="card-header col1">
                <strong className="top-left-heading">Price Circular</strong>

                <ol className="headerroutes breadcrumb m-0 bg-none ">
                  <li className="item color-gray">|</li>
                  <li className="item">
                    <Link
                      to="/admin/dashboard"
                      className="route-heading item mx-2"
                    >
                      Dashboard
                    </Link>
                  </li>
                  <li className="item">
                    <FaGreaterThan className="grater-than-route" />
                  </li>
                  <li className="route-heading item mx-2 mt-1">
                    Price Circular
                  </li>
                </ol>
              </div>
              {/* right side add button */}
              <div className="header-action m-3 col2">
                <Link
                  to="/admin/price-circular/add"
                  className="text-decoration-none"
                >
                  <button
                    type="button"
                    className="btn btn-primary add_btn"
                    data-toggle="modal"
                    data-target="#exampleModal"
                  >
                    <i className="fe fe-plus mr-2" />
                    Add
                  </button>
                </Link>
                {/* ) : null} */}
              </div>
            </div>
          </div>
        </div>

        <div className="section-body ">
          <div className="container-fluid">
            <div className="tab-content">
              <div
                className="tab-pane fade show active"
                id="user-list"
                role="tabpanel"
              >
                <div className="card">
                  <div className="card-body">
                    <div className="table-responsive">
                      {
                        <DataTable2
                          lists={priceCircular}
                          headers={headers}
                          searchItems={searchItems}
                          placeholder={placeholder}
                        />
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PriceCircular;
