import { useContext, useEffect, useState } from "react";
import { FaGreaterThan } from "react-icons/fa";
import { Link, useNavigate, useParams } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import AlertContext from "../../../context/Alert/AlertContext";
import axiosPrivate from "../../../hooks/axiosPrivate";
import "../Logistic/logistic.css";
import LoaderContext from "../../../context/loader/LoaderContext";
import { useFormik } from "formik";
import * as Yup from "yup";
import classes from "./Dispatch.module.css"
import { showErrorToast } from "../../../Toster";

const EditDispatch = () => {
  const { setIsLoading } = useContext(LoaderContext);
  const { split_id, merge_id } = useParams();
  const { alertMessage, setAlertMessage } = useContext(AlertContext);
  const [orderData, setOrderData] = useState({});
  const navigate = useNavigate();

  const validationRules = {
    transporter_name: Yup.string().required("Transporter name is required"),
    driver_name: Yup.string().required("Driver name is required"),
    driver_phone: Yup.string()
      .test(
        "starts-not-with-0-to-5",
        "Driver phone no is not valid",
        function (value) {
          if (!value) return true;
          return !/^[0-5]/.test(value);
        }
      )
      .required("Driver phone no is required"),
    lr_no: Yup.string().required("LR no is required"),
    freight: Yup.string().required("Freight is required"),
    freight_amount: Yup.string().required("Freight amount is required"),
    vehicle_no: Yup.string().required("Vehicle no is required"),
    
  };


  const formik = useFormik({
    initialValues: {
      order_item_id: "",
      transporter_name: "",
      driver_name: "",
      split_id: "",
      merge_id: "",
      driver_phone: "",
      vehicle_no: "",
      lr_no: "",
      freight: "",
      freight_amount: 0,
    },
    validationSchema: Yup.object(validationRules),
    onSubmit: async (values, { setSubmitting, setFieldError }) => {
      // setIsLoading(true);
      var newValues = {
        driver_name: values.driver_name,
        order_item_id: values.id,
        transporter_name: values.transporter_name,
        driver_phone: values.driver_phone,
        split_id: values.split_id,
        merge_id: values.merge_id,
        vehicle_no: values.vehicle_no,
        lr_no: values.lr_no,
        freight: values.freight,
        freight_amount: values.freight_amount,
      };

      axiosPrivate
          .post(`dispatch/update-transporter-details`, newValues)
          .then((response) => {
            setAlertMessage({ message: "Transporter Details Update Successfully" });
            navigate("/admin/dispatch");
          })
          .catch((error) => {
            if (error.response) {
              if (typeof error.response.data.errors == "array") {
                showErrorToast(error.response.data.errors[0]?.msg);
              } else {
                showErrorToast(error.response.data.errors?.msg);
              }
            }
          }).finally(() => {
            setIsLoading(false);
          });
    },
  });
  useEffect(() => {
    setIsLoading(true);
    axiosPrivate
      .post("dispatch/get-transporter-details", {
        split_id: split_id,
        merge_id: merge_id,
      })
      .then((response) => {
        // setOrderData(response?.data?.data);
        
        formik.setValues(response?.data?.data[0]);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  }, []);


const numberFilter = (event) => {
    const inputValue = event.target.value;

    const numericValue = inputValue.replace(/\D/g, "");
    formik.setFieldValue(event.target.name, numericValue);
  };

  const nameFilter = (event) => {
    const inputValue = event.target.value;
    if (/^[A-Za-z\s'-]+$/.test(inputValue) || inputValue === "") {
      formik.setFieldValue(event.target.name, inputValue);
    }
  };

  return (
    <div className="section-body">
      <div className="container-fluid">
        {/* hearder breadcrumb */}
        <div className="card-header">
          <strong className="top-left-heading">Edit Transporter Details</strong>

          <ol className="headerroutes breadcrumb m-0 bg-none ">
            <li className="item color-gray">|</li>
            <li className="item">
              <Link to="/admin/dashboard" className="route-heading item mx-2">
                Dashboard
              </Link>
            </li>
            <li className="item">
              <FaGreaterThan className="grater-than-route" />
            </li>
            <li className="route-heading item mx-2 mt-1">
                <Link to="/admin/dispatch">Dispatch</Link>
              </li>
            <li className="item">
              <FaGreaterThan className="grater-than-route" />
            </li>

            <li className="route-heading item mx-2 mt-1">Edit Transporters Details</li>
          </ol>
        </div>

        <div className="tab-content ">
          <form onSubmit={formik.handleSubmit}>
            <div
              className="tab-pane fade show active"
              id="user-add"
              role="tabpanel"
            >
              <div className="card">
                {/* table */}
                <div className={`card-body ${classes.tableWrapper}`}>
                  <div className="row">
                    {/* Transporter name */}
                    <div className="col-md-4">
                      <label>
                        Transporter Name<span className="required">*</span>
                      </label>
                      <input
                        id="transporter_name"
                        type="text"
                        className="form-control"
                        name={`transporter_name`}
                        placeholder="Enter Transporter Name"
                        value={formik.values.transporter_name}
                        onChange={(event) => {
                          const regex = /^[A-Za-z0-9\s]*$/; // Allow only alphanumeric characters
                          let value = event.target.value;
                          if (regex.test(value)) {
                            formik.setFieldValue("transporter_name", value); // Update Formik value if valid
                          }
                        }}
                      />
                      {formik.touched.transporter_name &&
                      formik.errors.transporter_name != undefined ? (
                        <div className={classes.invalidDataError}>
                          {formik.errors.transporter_name}
                        </div>
                      ) : null}
                    </div>
                    {/* Driver name */}
                    <div className="col-md-4">
                      <label>
                        Driver Name<span className="required">*</span>
                      </label>
                      <input
                        id="driver_name"
                        type="text"
                        className="form-control"
                        name={`driver_name`}
                        placeholder="Enter Driver Name"
                        value={formik.values.driver_name}
                        onChange={(event) => {
                          nameFilter(event);
                        }}
                      />
                      {formik.touched.driver_name &&
                      formik.errors.driver_name != undefined ? (
                        <div className={classes.invalidDataError}>
                          {formik.errors.driver_name}
                        </div>
                      ) : null}
                    </div>
                    {/* Driver phone */}
                    <div className="col-md-4">
                      <label>
                        Driver Phone No.<span className="required">*</span>
                      </label>
                      <input
                        id="driver_phone"
                        type="text"
                        maxLength={10}
                        className="form-control"
                        name={`driver_phone`}
                        placeholder="Enter Driver Phone No."
                        value={formik.values.driver_phone}
                        onChange={(event) => {
                          numberFilter(event);
                        }}
                      />
                      {formik.touched.driver_phone &&
                      formik.errors.driver_phone != undefined ? (
                        <div className={classes.invalidDataError}>
                          {formik.errors.driver_phone}
                        </div>
                      ) : null}
                    </div>
                    {/* Vehicle No. */}
                    <div className="col-md-4 mt-1 mb-3">
                      <label>
                        Vehicle No.<span className="required">*</span>
                      </label>
                      <input
                        id="vehicle_no"
                        type="text"
                        className="form-control"
                        name={`vehicle_no`}
                        placeholder="Enter Vehicle No(RJ06XX0000)."
                        value={formik.values.vehicle_no}
                        autoCapitalize={true}
                        onChange={(event) => {
                          const regex = /^[A-Za-z0-9]*$/; // Allow only alphanumeric characters
                          let value = event.target.value.toUpperCase(); // Convert to uppercase
                          if (regex.test(value)) {
                            formik.setFieldValue("vehicle_no", value); // Update Formik value if valid
                          }
                        }}
                      />
                      {formik.touched.vehicle_no &&
                      formik.errors.vehicle_no != undefined ? (
                        <div className={classes.invalidDataError}>
                          {formik.errors.vehicle_no}
                        </div>
                      ) : null}
                    </div>

                    <div className="col-md-4 mt-1 mb-3">
                      <label>
                        LR No <span className="required">*</span>
                      </label>
                      <input
                        id="lr_no"
                        type="text"
                        className="form-control"
                        name={`lr_no`}
                        placeholder="LR No"
                        value={formik.values.lr_no}
                        onChange={(event) => {
                          formik.handleChange(event); // Call handleChange with the event
                        }}
                      />
                      {formik.touched.lr_no && formik.errors.lr_no ? (
                        <div className={classes.invalidDataError}>
                          {formik.errors.lr_no}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-md-4 mt-1 mb-3">
                      <label>
                        Freight <span className="required">*</span>
                      </label>
                      <select
                        id="freight"
                        className="form-control"
                        name={`freight`}
                        value={formik.values.freight}
                        onChange={(event) => {
                          formik.handleChange(event);
                        }}
                        onBlur={formik.handleBlur}
                      >
                        <option value="">Select Freight Type</option>
                        <option key={"billing"} value={"billing"}>
                          Billing
                        </option>
                        <option key={"to_pay "} value={"to_pay"}>
                          To Pay
                        </option>
                      </select>
                      {formik.touched.freight && formik.errors.freight ? (
                        <div className={classes.invalidDataError}>
                          {formik.errors.freight}
                        </div>
                      ) : null}
                    </div>
                    {formik?.values?.freight === "to_pay" && (
                      <>
                        <div className="col-md-4 mt-1 mb-3">
                          <label>
                            Freight Amount <span className="required">*</span>
                          </label>
                          <input
                            id="freight_amount"
                            type="text"
                            className="form-control"
                            name={`freight_amount`}
                            placeholder="Freight Amount"
                            value={formik.values.freight_amount}
                            onChange={(event) => {
                              numberFilter(event);
                            }}
                            maxLength={10}
                          />
                          {formik.touched.freight_amount &&
                          formik.errors.freight_amount ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.freight_amount}
                            </div>
                          ) : null}
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div className="col-12 submit-btn my-2" style={{paddingBottom:'40px', paddingRight:'20px'}}>
                  <div className="table-responsive">
                    <table className="table table-striped"></table>
                  </div>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                    onClick={() => navigate(`/admin/dispatch`)}
                  >
                    Close
                  </button>
                  <button type="submit" className="btn btn-primary ml-2">
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditDispatch;
