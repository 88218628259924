import { useContext, useEffect, useRef, useState } from "react";
import { FaGreaterThan } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AlertContext from "../../../context/Alert/AlertContext";
import AuthContext from "../../../context/Auth/AuthContext";
import MergeModal from "../../../context/components/Modals/MergeModel";
import axiosPrivate from "../../../hooks/axiosPrivate";
import "../Logistic/logistic.css";
import LoaderContext from "../../../context/loader/LoaderContext";
import { showErrorToast, showSuccessToast } from "../../../Toster";
import { Dialog } from "primereact/dialog";
import html2pdf from "html2pdf.js/dist/html2pdf.min";
import ReactDOMServer from "react-dom/server";
import "./invoice.css";
import QRCode from "react-qr-code";
import nmLogo from ".././../../assets/images/nm_logo.png";
import paymentQr from ".././../../assets/images/payment_qr.png";
import moment from "moment";

const Logistic = () => {
  const [data, setData] = useState({});
  const [visible, setVisible] = useState(false);
  const { setIsLoading } = useContext(LoaderContext);
  const { checkPermissionExists, authPermissions } = useContext(AuthContext);
  const { alertMessage, setAlertMessage } = useContext(AlertContext);
  const [logistics, setLogisticsState] = useState([]);
  const [userData, setUserData] = useState([]);
  const navigate = useNavigate();

  const invoicePdfModal = useRef();

  useEffect(() => {
    axiosPrivate
      .post("users")
      .then((res) => setUserData(res?.data?.data))
      .catch((error) => {});
  }, []);

  const dispatch = userData.filter((data) => data?.roles == "Dispatch team");
  const dispatchId = dispatch.map((data) => data.id);

  useEffect(() => {
    // if (!authPermissions.includes("Account-List")) {
    //   navigate("/admin/dashboard");
    // }
    setIsLoading(true);
    axiosPrivate
      .get("accounting/list/items")
      .then((response) => {
        setIsLoading(false);
        setLogisticsState(response?.data?.data);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  }, [authPermissions]);

  useEffect(() => {
    var acc = document.getElementsByClassName("accordion-custome");
    var i;

    for (i = 0; i < acc.length; i++) {
      acc[i].addEventListener("click", function () {
        this.classList.toggle("active-custome");
        var panel = this.nextElementSibling;
        if (panel.style.display === "block") {
          panel.style.display = "none";
        } else {
          panel.style.display = "block";
        }
      });
    }
  }, [logistics]);

  //State For selected items start
  const [selectedItems, setSelectedItems] = useState([]);
  const [mergerButton, setMergerButton] = useState(false);
  //State for selected items end

  //State For Merge Modal start
  const [showModal, setShowModal] = useState(false);
  //State For Merge Modal end

  useEffect(() => {
    if (alertMessage != null) {
      toast(alertMessage.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setAlertMessage(null);
    }
  }, [alertMessage]);

  //Function to handle and add selected id start
  const selectItems = async (event, id) => {
    let oldLogisticSelectedIds = new Set(selectedItems);
    let selectedItemsArray = selectedItems;
    if (event.target.checked && oldLogisticSelectedIds.has(id) != true) {
      selectedItemsArray.push(id);
      setSelectedItems(selectedItemsArray);
    } else {
      let index = selectedItemsArray.indexOf(id);
      if (index > -1) {
        selectedItemsArray.splice(index, 1);
        setSelectedItems(selectedItemsArray);
        event.target.checked = false;
      }
    }
    if (selectedItemsArray.length > 0) {
      setMergerButton(true);
    } else {
      setMergerButton(false);
    }
    return true;
  };
  //Function to handle and add selected id end

  //Function to remove the selected items start
  const removeSelectedItems = () => {
    //Loop and remove checked items start
    document.querySelectorAll(".select-merge").forEach(function (checkBox) {
      checkBox.checked = false;
    });
    //Loop and remove checked items end

    setSelectedItems([]);
    setMergerButton(false);
    return true;
  };
  //Function to remove the selected items end

  //Merge Open Modal function start
  const openModal = (event) => {
    event.preventDefault();
    setShowModal(true);
  };
  //Merge Open Modal function end

  //Merge Close Modal function start
  const closeModal = () => {
    setShowModal(false);
  };
  //Merge Close Modal function end

  //Function to handle modal form submission start
  const handleConfirm = async () => {
    closeModal();
    removeSelectedItems();
    navigate(`/admin/logistics`);
    return true;
  };
  //Function to handle modal form submission end

  var acc = document.getElementsByClassName("accordion-custome");
  var i;

  for (i = 0; i < acc.length; i++) {
    acc[i].addEventListener("click", function () {
      this.classList.toggle("active-custome");
      var panel = this.nextElementSibling;
      if (panel.style.display === "block") {
        panel.style.display = "none";
      } else {
        panel.style.display = "block";
      }
    });
  }

  const handlePrint = () => {
    window.print();
  };

  /* Function to generate a invoice start */
  const generateInvoice = (event, splitId, mergeId, orderId, orderNo) => {
    setIsLoading(true);
    event.preventDefault();
    axiosPrivate
      .post("tally/fetch-invoice-by-orderid", {
        order_id: orderId,
        split_id: splitId,
        merge_id: mergeId,
      })
      .then(async (response) => {
        if (response.status == 200) {
          let finalData = response.data.data;

          await finalData?.forEach((item) => {
            let total_quantity = 0;

            // Extract quantities, convert to numbers, and sum them up
            total_quantity = item?.parsedReplacements?.products?.reduce(
              (acc, product) => {
                return acc + Number(product?.quantity || 0);
              },
              0
            );
            item.parsedReplacements.quantity = total_quantity;
          });

          for (let i = 0; i < finalData.length; i++) {
            await printHandler(finalData[i]);
          }

          setIsLoading(false);
        }
        if (response.status == 500) {
          setIsLoading(false);
          showErrorToast("Server Error");
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("Error", err);

        showErrorToast("Invoice not generated from tally");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  async function notificationFunction(orderId) {
    let object = {
      title: "Request for Order Dispatch",
      order_id: orderId,
      user_id: dispatchId[0],
      notification_for: "dispatch",
    };
    await axiosPrivate.post("/notifications/store", object).then((res) => {});
  }
  /* Function to reset state of logistic after invoice genrate */
  const resetLogisticState = async () => {
    await axiosPrivate.get("accounting/list/items").then((response) => {
      setLogisticsState(response?.data?.data);
    });
  };
  function formatDate(inputDate) {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const date = new Date(inputDate);
    const day = date.getUTCDate();
    const month = months[date.getUTCMonth()];
    const year = date.getUTCFullYear();

    return `${day}-${month}-${year}`;
  }

  const pdfJSX = (data) => {
    return (
      <>
        <div className="jumBotron invoice-container">
          <div className="header-invoice">
            <div>
              <div className="header-in d-flex align-items-center">
                <div style={{ width: "190px" }}>
                  <img src={nmLogo} alt="NM Logo" />
                </div>
                <div className="right-sec">
                  <h6 className="tax-heading">Tax Invoice</h6>
                  <h3 className="company_name">NM India Bio Private Limited</h3>
                  <div className="invoice-address">
                    Araji No. 4790, Jhamar Kotra Road, Umarda, Udaipur ,
                    Rajasthan - 313003, INDIA.
                  </div>
                  <div className="my-0 invoice-address ">
                    <span>9829355044</span>
                    <span className="mx-3">bhanuprakash@nmidiabio.com</span>
                  </div>
                  <div className="invoice-address">
                    <span>
                      GSTIN: <b>08AACCE7746A1ZT</b>
                    </span>
                    <span className="mx-3">
                      PAN: <b>AACCE7746A</b>
                    </span>
                  </div>
                </div>
              </div>
              <div>
                <div className="invoice-bill mt-3">
                  <div className="invoice-bill-content">
                    <div className="invoice-date">
                      <span>INVOICE</span>
                      <br></br>
                      <span>{data?.vch_no}</span>
                      <br></br>
                      <span>{formatDate(data?.parsedReplacements?.date)}</span>
                    </div>
                    <div className="invoice-person-detail">
                      <p style={{ marginBottom: "0" }}>Bill to</p>
                      <div className="tax-heading">
                        {data?.parsedReplacements?.tallyLedgerName}
                      </div>
                      <span>Dealer Code : </span>
                      <br></br>
                      <span>
                        {data?.parsedReplacements?.billing_address} ,{" "}
                        {data?.parsedReplacements?.billing_city} ,{" "}
                        {data?.parsedReplacements?.billing_state} ,{" "}
                        {data?.parsedReplacements?.billing_zip}
                      </span>
                      <br></br>
                      <span>
                        GSTIN : {data?.parsedReplacements?.gstin} (24){" "}
                        {data?.parsedReplacements?.billing_state}
                      </span>
                    </div>
                  </div>
                  <div
                    className="invoice-person-detail"
                    style={{ borderLeft: "1px solid black" }}
                  >
                    <p style={{ marginBottom: "0" }}>Ship to</p>
                    <div className="tax-heading">
                      {data?.parsedReplacements?.tallyLedgerName}
                    </div>
                    <span>Dealer Code : </span>
                    <br></br>
                    <span>
                      {data?.parsedReplacements?.shipping_address} ,{" "}
                      {data?.parsedReplacements?.shipping_city} ,{" "}
                      {data?.parsedReplacements?.shipping_state} ,{" "}
                      {data?.parsedReplacements?.shipping_zip}
                    </span>
                    <br></br>
                    <span>
                      GSTIN : {data?.parsedReplacements?.gstin} (24){" "}
                      {data?.parsedReplacements?.shipping_state}
                    </span>
                  </div>
                  <div className="invoice-bill-qrcode">
                    <img src={paymentQr} style={{ width: "100px" }} />
                  </div>
                </div>

                <table className="mid-line-table">
                  <tr>
                    <th className="first-th">IRN</th>
                    <th>{data?.parsedReplacements?.ewayBill?.irn}</th>
                    <th>{data?.parsedReplacements?.ewayBill?.irnAckNo}</th>
                    <th>@ {formatDate(data?.parsedReplacements?.date)}</th>
                  </tr>
                </table>

                <table>
                  <tr>
                    <th></th>
                    <th>Order</th>
                    <th>Dispatch Doc</th>
                    <th>Through</th>
                    <th>LR/RR/BL</th>
                    <th>Carrier</th>
                  </tr>

                  <tr>
                    <td>#</td>
                    <td>{data?.order_no}</td>
                    <td>{data?.vch_no}</td>
                    <td>{data?.parsedReplacements?.transporterName}</td>
                    <td>{data?.parsedReplacements?.products[0]?.lr_no}</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>@</td>
                    <td>{formatDate(data?.parsedReplacements?.date)}</td>
                    <td></td>
                    <td></td>
                    <td className="date">
                      {formatDate(data?.parsedReplacements?.date)}{" "}
                    </td>
                    <td>{data?.parsedReplacements?.carrierNumber}</td>
                  </tr>
                </table>

                <table className="main-table">
                  <thead>
                    <tr>
                      <th className="s_no">S.No</th>
                      <th className="product_name_td">Description of Goods</th>
                      <th className="hsn_td1">HSN</th>
                      <th>Quantity</th>
                      <th>Weight</th>
                      <th>Carton</th>
                      <th>Rate</th>
                      <th>Per</th>
                      <th>Disc. %</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.parsedReplacements?.products?.map(
                      (product, index) => (
                        <>
                          <tr className="border-0" key={index}>
                            <td>{index + 1}</td>
                            <td className="product_name_td">
                              {product.product_name}
                            </td>
                            <td>310100</td>
                            <td>{`${product?.quantity} ${product?.base_unit}`}</td>
                            <td>{`${product?.weight} ${product?.unit}`}</td>
                            <td>-</td>
                            <td>{`${product.rate}.00`}</td>
                            <td>{product.base_unit}</td>
                            <td>-</td>
                            <td>{`${product.item_amount}.00`}</td>
                          </tr>
                        </>
                      )
                    )}
                    <tr className="border-0" style={{ height: "30px" }}>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>
                        <b>-</b>
                      </td>
                    </tr>

                    <tr className="border-0">
                      <td></td>
                      <td
                        className="d-flex justify-content-between"
                        style={{ paddingRight: "5px" }}
                      >
                        <b>
                          <i>Less</i>
                        </b>
                        <b>
                          <i>Discount</i>
                        </b>
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>
                        <b>(-)00.00</b>
                      </td>
                    </tr>

                    {data?.parsedReplacements?.itemsTax?.cgst && (
                      <tr className="border-0">
                        <td></td>
                        <td
                          className="d-flex justify-content-between"
                          style={{ paddingRight: "5px" }}
                        >
                          <b></b>
                          <b>
                            <i>CGST</i>
                          </b>
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                          <b>{data?.parsedReplacements?.total_tax / 2}</b>
                        </td>
                      </tr>
                    )}
                    {data?.parsedReplacements?.itemsTax?.sgst && (
                      <tr className="border-0">
                        <td></td>
                        <td
                          className="d-flex justify-content-between"
                          style={{ paddingRight: "5px" }}
                        >
                          <b></b>
                          <b>
                            <i>SGST</i>
                          </b>
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                          <b>{data?.parsedReplacements?.total_tax / 2}</b>
                        </td>
                      </tr>
                    )}
                    {data?.parsedReplacements?.itemsTax?.igst && (
                      <tr className="border-0">
                        <td></td>
                        <td
                          className="d-flex justify-content-between"
                          style={{ paddingRight: "5px" }}
                        >
                          <b></b>
                          <b>
                            <i>IGST</i>
                          </b>
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                          <b>
                            {data?.parsedReplacements?.itemsTax?.igst_amount}
                          </b>
                        </td>
                      </tr>
                    )}

                    <tr style={{ height: "200px" }} className="border-0">
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>
                        <b>Total Amount</b>
                      </td>
                      <td></td>
                      <td>
                        <b>
                          {data?.parsedReplacements?.quantity}{" "}
                          {data?.parsedReplacements?.baseUnit}
                        </b>
                      </td>
                      <td>{data?.parsedReplacements?.total_weight}</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>
                        <b>Rs. {data?.parsedReplacements?.total}</b>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <table className="amount-chargeble">
                  <tbody>
                    <div
                      className="d-flex"
                      style={{
                        borderLeft: "1px solid #000",
                        borderRight: "1px solid #000",
                      }}
                    >
                      <div
                        style={{
                          borderRight: "1px solid #000",
                          width: "80%",
                          padding: "0px 5px",
                        }}
                      >
                        <div className="d-flex justify-content-between amount-words">
                          <p className="m-0 amount">
                            <b>Amount Chargeable (In words)</b>
                          </p>
                          <p className="mx-1 mb-0 eof text-right">
                            <b>E. & O.E</b>
                          </p>
                        </div>
                        <div className="mb-0 amount-eng">
                          <b>INR {data?.parsedReplacements?.totalInWords}</b>
                        </div>
                        <p></p>
                      </div>

                      <div style={{ padding: "0px 5px", margin: "auto" }}>
                        <div className="d-flex justify-content-between">
                          <b>Freight Payable :</b>
                          <b style={{ paddingLeft: "5px" }}>
                            {" "}
                            {
                              data?.parsedReplacements?.products[0]
                                ?.freight_amount
                            }
                            .00
                          </b>
                        </div>
                        <div className="d-flex justify-content-between">
                          <b>Due Amount :</b>
                          <b style={{ paddingLeft: "5px" }}> 00.00</b>
                        </div>
                      </div>
                    </div>
                  </tbody>
                </table>

                <table className="tax-amount">
                  <thead>
                    <tr>
                      <th style={{ textAlign: "center" }}>HSN/SAC</th>
                      <th style={{ width: "15%", textAlign: "center" }}>
                        Taxable Value
                      </th>
                      {data?.parsedReplacements?.itemsTax?.cgst && (
                        <th className="p-0" style={{ width: "15%" }}>
                          <div
                            className="text-center"
                            style={{ borderBottom: "1px solid #000" }}
                          >
                            <b style={{ fontWeight: "bold" }}>Central Tax</b>
                          </div>
                          <div className="d-flex justify-content-around">
                            <b
                              style={{
                                fontWeight: "bold",
                                padding: "0px 5px",
                                width: "53px",
                              }}
                            >
                              Rate
                            </b>
                            <b style={{ borderRight: "1px solid #000" }}></b>
                            <b
                              style={{
                                fontWeight: "bold",
                                padding: "0px 5px",
                              }}
                            >
                              Amount
                            </b>
                          </div>
                        </th>
                      )}

                      {data?.parsedReplacements?.itemsTax?.sgst && (
                        <th className="p-0" style={{ width: "15%" }}>
                          <div
                            className="text-center"
                            style={{ borderBottom: "1px solid #000" }}
                          >
                            <b style={{ fontWeight: "bold" }}>State Tax</b>
                          </div>
                          <div className="d-flex justify-content-around">
                            <b
                              style={{
                                fontWeight: "bold",
                                padding: "0px 5px",
                                width: "53px",
                              }}
                            >
                              Rate
                            </b>
                            <b style={{ borderRight: "1px solid #000" }}></b>
                            <b
                              style={{
                                fontWeight: "bold",
                                padding: "0px 5px",
                              }}
                            >
                              Amount
                            </b>
                          </div>
                        </th>
                      )}

                      {data?.parsedReplacements?.itemsTax?.igst && (
                        <th className="p-0" style={{ width: "15%" }}>
                          <div
                            className="text-center"
                            style={{ borderBottom: "1px solid #000" }}
                          >
                            <b style={{ fontWeight: "bold" }}>Integrated Tax</b>
                          </div>
                          <div className="d-flex justify-content-around">
                            <b
                              style={{
                                fontWeight: "bold",
                                padding: "0px 5px",
                                width: "53px",
                              }}
                            >
                              Rate
                            </b>
                            <b style={{ borderRight: "1px solid #000" }}></b>
                            <b
                              style={{
                                fontWeight: "bold",
                                padding: "0px 5px",
                              }}
                            >
                              Amount
                            </b>
                          </div>
                        </th>
                      )}
                      <th style={{ width: "15%", textAlign: "center" }}>
                        Total Tax Amount
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={{ textAlign: "center" }}>310100</td>
                      <td style={{ width: "15%", textAlign: "center" }}>
                        {data?.parsedReplacements?.total -
                          data?.parsedReplacements?.total_tax}
                      </td>

                      {data?.parsedReplacements?.itemsTax?.cgst && (
                        <>
                          <td className="p-0" style={{ width: "15%" }}>
                            <div className="d-flex justify-content-around">
                              <div
                                style={{
                                  fontWeight: "none",
                                  padding: "0px 5px",
                                }}
                              >
                                {data?.parsedReplacements?.itemsTax?.cgst}%
                              </div>
                              <div
                                style={{ borderRight: "1px solid #000" }}
                              ></div>
                              <div style={{ padding: "0px 5px" }}>
                                {data?.parsedReplacements?.total_tax / 2}
                              </div>
                            </div>
                          </td>
                        </>
                      )}

                      {data?.parsedReplacements?.itemsTax?.sgst && (
                        <>
                          <td className="p-0" style={{ width: "15%" }}>
                            <div className="d-flex justify-content-around">
                              <div
                                style={{
                                  fontWeight: "none",
                                  padding: "0px 5px",
                                }}
                              >
                                {data?.parsedReplacements?.itemsTax?.sgst}%
                              </div>
                              <div
                                style={{ borderRight: "1px solid #000" }}
                              ></div>
                              <div style={{ padding: "0px 5px" }}>
                                {data?.parsedReplacements?.total_tax / 2}
                              </div>
                            </div>
                          </td>
                        </>
                      )}

                      {data?.parsedReplacements?.itemsTax?.igst && (
                        <>
                          <td className="p-0" style={{ width: "15%" }}>
                            <div className="d-flex justify-content-around">
                              <div
                                style={{
                                  fontWeight: "none",
                                  padding: "0px 5px",
                                }}
                              >
                                {data?.parsedReplacements?.itemsTax?.igst}%
                              </div>
                              <div
                                style={{ borderRight: "1px solid #000" }}
                              ></div>
                              <div style={{ padding: "0px 5px" }}>
                                {
                                  data?.parsedReplacements?.itemsTax
                                    ?.igst_amount
                                }
                              </div>
                            </div>
                          </td>
                        </>
                      )}

                      <td style={{ width: "15%", textAlign: "center" }}>
                        {data?.parsedReplacements?.total_tax}
                      </td>
                    </tr>
                  </tbody>
                </table>

                <div className="pt-2 pl-1 bank_details d-flex justify-content-between border border-dark">
                  <div className="d-flex flex-column justify-content-between w-50 pr-4">
                    <span>
                      Tax Amount (in Words) :<br />
                      <b>INR {data?.parsedReplacements?.totalTaxInWords}</b>
                      {/* <!-- Enter amount in words  --> */}
                    </span>
                    <div>
                      <p className="mb-0 pt-2">
                        <p
                          className="mb-0"
                          style={{ textDecoration: "underline" }}
                        >
                          Declaration :
                        </p>
                        We Declare that this invoice shows the real price of the
                        goods described and that all particular true and
                        correct.
                      </p>
                    </div>
                  </div>
                  <div className="company_div w-50">
                    <div className="mt-0">
                      <p className="mb-0">Company's Bank Details</p>
                      <p className="mb-0">
                        Bank Name : <b>Bank of Baroda (India)</b>
                      </p>
                      <p className="mb-0">
                        A/c No. : <b>01370500000868</b>
                      </p>
                      <p className="mb-0">
                        Branch & IFSC Code :{" "}
                        <b>Udaipur Main Branch & BARB0UDAIPU</b>
                      </p>
                    </div>
                    <div
                      className="invoice-bill-qrcode qr-code border border-dark w-100 h-100 p-1"
                      style={{ borderBottom: "0px !important" }}
                    >
                      <div>
                        <div className="text-left">
                          <b>Scan to Pay</b>
                        </div>
                        <div className="mt-1">
                          <img src={paymentQr} style={{ width: "100px" }} />
                        </div>
                      </div>
                      <div className="d-flex flex-column justify-content-between mt-3">
                        <p style={{ fontWeight: "600" }}>
                          For{" "}
                          <span style={{ fontWeight: "bold" }}>
                            NM India Bio Private Limited
                          </span>{" "}
                        </p>
                        <p className="mb-0">Authorized Signatory</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="border border-dark text-center p-2 border-top-0">
                  This is a computer generated Invoice
                </div>

                {data?.parsedReplacements?.ewayBill && (
                  <>
                    {/* Add this div to introduce a page break */}
                    <div className="pageBreak"></div>
                    <div className=" p-0 mt-4">
                      <div className="header bg-none">
                        <h6>e-Way Bill</h6>
                      </div>

                      <div className="invoiceSectionBorderBottom">
                        <div>
                          <p>
                            Doc No :{" "}
                            <span className="boldText">
                              {" "}
                              {data?.parsedReplacements?.ewayBill?.docNo}
                            </span>
                          </p>
                          <p>
                            Date :
                            <span className="boldText">
                              {" "}
                              {moment(
                                data?.parsedReplacements?.ewayBill?.billDate,
                                "YYYYMMDD"
                              ).format("DD-MMM-YYYY")}
                            </span>
                          </p>
                          <p>
                            IRN :
                            <span className="boldText">
                              {" "}
                              {data?.parsedReplacements?.ewayBill?.irn}
                            </span>
                          </p>
                          <p>
                            Ack No :
                            <span className="boldText">
                              {" "}
                              {data?.parsedReplacements?.ewayBill?.irnAckNo}
                            </span>
                          </p>
                          <p>
                            Ack Date :
                            <span className="boldText">
                              {" "}
                              {moment(
                                data?.parsedReplacements?.ewayBill?.irnAckDate,
                                "YYYYMMDD"
                              ).format("DD-MMM-YYYY")}
                            </span>
                          </p>
                        </div>
                        <div>
                          {" "}
                          <QRCode
                            title="GeeksForGeeks"
                            value={data?.parsedReplacements?.ewayBill?.qrCode}
                            bgColor={"#ffffff"}
                            fgColor={"#000000"}
                            size={100}
                          />
                        </div>
                      </div>

                      {/* <h3>1. e-Way Bill Details</h3> */}
                      <div className="boldText p-1">1. e-Way Bill Details</div>
                      <div className="invoiceSectionBorderBottom">
                        <div>
                          <p>
                            e-Way Bill No :
                            <span className="boldText">
                              {" "}
                              {data?.parsedReplacements?.ewayBill?.billNumber}
                            </span>
                          </p>
                          <p>
                            Generated By :
                            <span className="boldText"> 08AACCE7746A1ZT</span>
                          </p>
                          <p>
                            Supply Type :
                            <span className="boldText"> Outward</span>
                          </p>
                        </div>
                        <div>
                          <p>
                            Mode :<span className="boldText"> 1-Road</span>
                          </p>
                          <p>
                            Approx Distance :
                            <span className="boldText">
                              {
                                data?.parsedReplacements?.ewayBill
                                  ?.approxDistance
                              }
                            </span>
                          </p>
                          <p>
                            Transition Type :
                            <span className="boldText"> Bill To - Ship To</span>
                          </p>
                        </div>
                        <div>
                          <p>
                            Generated Date :
                            <span className="boldText">
                              {moment(
                                data?.parsedReplacements?.ewayBill
                                  ?.generatedDate,
                                "YYYYMMDD"
                              ).format("DD-MMM-YYYY")}
                            </span>
                          </p>
                          <p>
                            Valid Upto :
                            <span className="boldText">
                              {" "}
                              {moment(
                                data?.parsedReplacements?.ewayBill?.validUpto,
                                "YYYYMMDD"
                              ).format("DD-MMM-YYYY")}
                            </span>
                          </p>
                        </div>
                      </div>

                      {/* <h3>2. Address Details</h3> */}
                      <div className="boldText p-1">2. Address Details</div>
                      <div className="rows">
                        <div className="column1">
                          <div className="p-1">
                            <p>
                              <span className="boldText">From </span>
                            </p>
                            <p>NM India Bio Private Limited</p>
                            <p>GSTIN: 08AACCE7746A1ZT</p>
                            <p>
                              {
                                data?.parsedReplacements?.ewayBill
                                  ?.shipFromAddress
                              }
                            </p>
                          </div>
                          <div className="p-1">
                            <p>
                              <span className="boldText">Dispatch From</span>
                            </p>
                            <p>
                              {
                                data?.parsedReplacements?.ewayBill
                                  ?.consignorAddress
                              }
                              {", "}
                              {
                                data?.parsedReplacements?.ewayBill
                                  ?.consignorCity
                              }{" "}
                              {
                                data?.parsedReplacements?.ewayBill
                                  ?.shipFromAddress
                              }{" "}
                              {data?.parsedReplacements?.billing_zip}
                            </p>
                          </div>
                        </div>
                        <div className="column2">
                          <div>
                            <p>
                              <span className="boldText">To</span>
                            </p>
                            <p>{data?.parsedReplacements?.tallyLedgerName}</p>
                            <p>GSTIN: {data?.parsedReplacements?.gstin}</p>
                            <p>
                              {
                                data?.parsedReplacements?.ewayBill
                                  ?.shipToAddress
                              }
                            </p>
                          </div>
                          <div>
                            <p>
                              <span className="boldText">Ship To</span>
                            </p>
                            <p>
                              {
                                data?.parsedReplacements?.ewayBill
                                  ?.consigneeAddress
                              }
                              {", "}
                              {
                                data?.parsedReplacements?.ewayBill
                                  ?.consigneeCity
                              }{" "}
                              {
                                data?.parsedReplacements?.ewayBill
                                  ?.shipToAddress
                              }{" "}
                              {data?.parsedReplacements?.shipping_zip}
                            </p>
                          </div>
                        </div>
                      </div>

                      {/* <h3
                    style={{
                      borderBottom: "1px solid black",
                      paddingBottom: "10px",
                    }}
                  >
                    3. Goods Details
                  </h3> */}
                      <div
                        className="boldText p-1"
                        style={{
                          borderBottom: "1px solid black",
                          paddingBottom: "10px",
                        }}
                      >
                        3. Goods Details
                      </div>
                      <table className="goodsTable">
                        <thead>
                          <tr>
                            <th className="border-right-0">HSN Code</th>
                            <th className="border-right-0">
                              Product Name & Desc
                            </th>
                            <th className="border-right-0">Quantity</th>
                            <th className="border-right-0">Taxable Amt</th>
                            <th className="border-right-0">Tax Rate(C + S)</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data?.parsedReplacements?.products?.map(
                            (product) => (
                              <tr>
                                <td>
                                  <span className="boldText">310100</span>
                                </td>
                                <td>{product?.product_name}</td>
                                <td>
                                  {product?.quantity} {product?.base_unit}
                                </td>
                                <td>
                                  <span className="boldText">
                                    {product?.item_amount}
                                  </span>
                                </td>
                                <td>
                                  {data?.parsedReplacements?.itemsTax?.cgst}
                                  {" + "}
                                  {data?.parsedReplacements?.itemsTax?.sgst}
                                </td>
                              </tr>
                            )
                          )}

                          <tr>
                            <td></td>
                          </tr>
                          <tr>
                            <td></td>
                          </tr>
                        </tbody>
                      </table>
                      <div className="invoiceSectionBorderBottom">
                        <div>
                          <p>
                            Tot. Taxable Amt :
                            <span className="boldText">
                              {" "}
                              {data?.parsedReplacements?.total -
                                data?.parsedReplacements?.total_tax}
                            </span>
                          </p>
                          <p>
                            CGST Amt :
                            <span className="boldText">
                              {" "}
                              {data?.parsedReplacements?.itemsTax?.cgst_amount}
                            </span>
                          </p>
                        </div>
                        <div>
                          <p>
                            Other Amt :<span className="boldText"></span>
                          </p>
                          <p>
                            SGST Amt :
                            <span className="boldText">
                              {" "}
                              {data?.parsedReplacements?.itemsTax?.sgst_amount}
                            </span>
                          </p>
                        </div>
                        <div>
                          <p>
                            Total Inv Amt :
                            <span className="boldText">
                              {data?.parsedReplacements?.total}
                            </span>
                          </p>
                        </div>
                      </div>

                      {/* <h3>4. Transportation Details</h3> */}
                      <div className="boldText p-1">
                        4. Transportation Details
                      </div>
                      <div className="invoiceSectionBorderBottom">
                        <div>
                          <p>
                            Transporter ID :<span className="boldText"></span>
                          </p>
                          <p>
                            Name :
                            <span className="boldText">
                              {" "}
                              {
                                data?.parsedReplacements?.ewayBill
                                  ?.transporterName
                              }
                            </span>
                          </p>
                        </div>
                        <div>
                          <p>
                            Doc No :
                            <span className="boldText">
                              {" "}
                              {data?.parsedReplacements?.ewayBill?.docNo}
                            </span>
                          </p>
                          <p>
                            Date :
                            <span className="boldText">
                              {" "}
                              {moment(
                                data?.parsedReplacements?.ewayBill?.billDate,
                                "YYYYMMDD"
                              ).format("DD-MMM-YYYY")}
                            </span>
                          </p>
                        </div>
                      </div>
                      {/* <h3>4. Vehicle Details</h3> */}
                      <div className="boldText p-1">4. Vehicle Details</div>
                      <div className="invoiceSectionBorderBottom border-bottom-0">
                        <p>
                          Vehicle No :
                          <span className="boldText">
                            {" "}
                            {data?.parsedReplacements?.ewayBill?.vehicleNumber}
                          </span>
                        </p>
                        <p>
                          From :
                          <span className="boldText">
                            {data?.parsedReplacements?.ewayBill?.consignorCity}
                          </span>
                        </p>
                        <p>
                          CEBW No. :<span className="boldText"></span>
                        </p>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const printHandler = async (values) => {
    const printElement = ReactDOMServer.renderToString(pdfJSX(values));
    // html2pdf().from(printElement).save();
    html2pdf()
    .from(printElement)
    .set({
      filename: `${values?.parsedReplacements?.tallyLedgerName} (${values?.vch_no}).pdf`, // Customize the filename
    })
    .save();
  };

  return (
    <>
      <div className="section-body">
        <div className="card flex justify-content-center">
          <>
            {/* <Dialog
            ref={invoicePdfModal}
            visible={visible}
            // style={{ width: "100vw" }}
            onHide={() => setVisible(false)}
            footer={
              <div>
                <button
                  onClick={() => setVisible(false)} // Close button
                  className="btn btn-secondary pad"
                >
                  Close
                </button>
                <button
                  onClick={handlePrint} // Print button
                  className="btn btn-primary pad"
                >
                  Print Invoice
                </button>
              </div>
            } // Add footer buttons
          >
            <h3 className="noprint">Invoice</h3>
            <div class="jumbotron">
              <div class="header-invoice mt-4 mb-2">
                <div class="container">
                  <div class="header-in d-flex align-items-center">
                    <div
                      class="pb-3"
                      style={{ width: "190px", height: "220px" }}
                    >
                      <img src={data?.logo} alt="NM Logo" />
                    </div>
                    <div style={{ width: "53.5%" }}>
                      <h6 class="tax-heading">Tax Invoice</h6>
                      <h3>NM India Bio Private Limited</h3>
                      <p class="mb-1">
                        <b>
                          Araji No. 4790, Jhamar Kotra Road, Umarda, Udaipur ,
                          Rajasthan - 313003, INDIA.
                        </b>
                      </p>
                      <p class="mt-1 mb-0 d-flex">
                        <span>9829355044</span>
                        <span class="mx-3">bhanuprakash@nmidiabio.com</span>
                      </p>
                    </div>
                  </div>
                  <div class="">
                    <div class="invoice-bill mt-3">
                      <div class="invoice-bill-content">
                        <div class="invoice-date">
                          <span>INVOICE</span>
                          <br></br>
                          <span>{data?.vch_no}</span>
                          <br></br>
                          <span>{data?.date}</span>
                        </div>
                        <div class="invoice-person-detail">
                          <span>Bill to</span>
                          <h5>
                            <b>{data?.tallyLedgerName}</b>
                          </h5>
                          <span>Dealer Code : </span>
                          <br></br>
                          <span>
                            {data?.address} , {data?.city} , {data?.state} ,
                            313001
                          </span>
                          <br></br>
                          <span>
                            GSTIN : {data?.gstin} (24) {data?.state}
                          </span>
                        </div>
                      </div>
                      <div class="invoice-bill-qrcode">
                        <img src="QR_code.webp" />
                      </div>
                    </div>

                    <table class="mid-line-table">
                      <tr>
                        <th class="first-th">IRN</th>
                        <th>eeeeeeer4i3eee5345i345345io345i3i45i354oi3</th>
                        <th>184838383838383</th>
                        <th>@ {data?.date}</th>
                      </tr>
                    </table>

                    <table>
                      <tr>
                        <th></th>
                        <th>Order</th>
                        <th>Dispatch Doc</th>
                        <th>Through</th>
                        <th>LR/RR/BL</th>
                        <th>Carrier</th>
                      </tr>

                      <tr>
                        <td>#</td>
                        <td>{data?.orderNo}</td>
                        <td>{data?.vch_no}</td>
                        <td>{data?.transporterName}</td>
                        <td>6522</td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>@</td>
                        <td>{data?.date}</td>
                        <td></td>
                        <td></td>
                        <td class="date">{data?.date} </td>
                        <td>{data?.carrierNumber}</td>
                      </tr>
                    </table>

                    <table className="main-table">
                      <thead>
                        <tr>
                          <th className="s_no">S.No</th>
                          <th className="product_name_td">
                            Description of Goods
                          </th>
                          <th className="hsn_td1">HSN</th>
                          <th>Quantity</th>
                          <th>Weight</th>
                          <th>Carton</th>
                          <th>Rate</th>
                          <th>Per</th>
                          <th>Disc. %</th>
                          <th>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data?.products?.map((product, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td className="product_name_td">
                              {product.product_name}
                            </td>
                            <td>310100</td>
                            <td>{`${product.quantity} ${product.base_unit}`}</td>
                            <td>{`${product.weight} ${product.unit}`}</td>
                            <td>-</td>
                            <td>{`${product.rate}.00`}</td>
                            <td>{product.base_unit}</td>
                            <td>-</td>
                            <td>{`${product.item_amount}.00`}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>

                    <table class="amount-chargeble">
                      <tbody>
                        <tr>
                          <td colSpan={10}>
                            <div class="amount-words">
                              <p class="m-0 amount">
                                <b>Amount Chargeable (In words)</b>
                              </p>
                              <p class="mx-1 mb-0 eof text-right">
                                <b>E. & O.E</b>
                              </p>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={10}>
                            <p class="mb-0 amount-eng">
                              <b>INR {data?.totalInWords}</b>
                              {/* <!-- Put amount in words above --> */}
            {/*
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <table className="tax-amount">
                      <thead>
                        <tr>
                          <td className="hsn_td2" rowSpan="2">
                            HSN/SAC
                          </td>
                          <td rowSpan="2">Taxable Value</td>
                          {data?.itemsTax?.cgst && (
                            <td colSpan="2" className="text-center">
                              Central Tax
                            </td>
                          )}
                          {data?.itemsTax?.sgst && (
                            <td colSpan="2" className="text-center">
                              State Tax
                            </td>
                          )}
                          {data?.itemsTax?.igst && (
                            <td colSpan="2" className="text-center">
                              Integrated Tax
                            </td>
                          )}
                          <td rowSpan="2" className="text-center">
                            Total Tax Amount
                          </td>
                        </tr>
                        <tr>
                          {data?.itemsTax?.cgst && (
                            <>
                              <td>Rate</td>
                              <td>Amount</td>
                            </>
                          )}
                          {data?.itemsTax?.sgst && (
                            <>
                              <td>Rate</td>
                              <td>Amount</td>
                            </>
                          )}
                          {data?.itemsTax?.igst && (
                            <>
                              <td>Rate</td>
                              <td>Amount</td>
                            </>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>310100</td>
                          <td>100</td>
                          {data?.itemsTax?.cgst && (
                            <>
                              <td>{data?.itemsTax?.cgst}%</td>
                              <td>{data?.itemsTax?.cgst_amount}</td>
                            </>
                          )}
                          {data?.itemsTax?.sgst && (
                            <>
                              <td>{data?.itemsTax?.sgst}%</td>
                              <td>{data?.itemsTax?.sgst_amount}</td>
                            </>
                          )}
                          {data?.itemsTax?.igst && (
                            <>
                              <td>{data?.itemsTax?.igst}%</td>
                              <td>{data?.itemsTax?.igst_amount}</td>
                            </>
                          )}
                        </tr>
                      </tbody>
                    </table>

                    <div class="pt-2 pl-1 bank_details d-flex justify-content-between border border-dark">
                      <div class="d-flex flex-column justify-content-between h-100 w-50">
                        <span class="d-flex">
                          Tax Amount (in Words) :{" "}
                          <h5>INR {data?.totalTaxInWords}</h5>
                          {/* <!-- Enter amount in words  --> */}
            {/*
                        </span>
                        <div>
                          <p class="mb-0">
                            <p class="mb-0 underline">Declaration :</p>
                            We Declare that this invoice shows the real price of
                            the goods described and that all particular true and
                            correct
                          </p>
                        </div>
                      </div>
                      <div class="company_div w-50">
                        <div class="mt-4">
                          <p class="mb-0">
                            <b>Company's Bank Details</b>
                          </p>
                          <p class="mb-0">Bank Name : Bank of Baroda (India)</p>
                          <p class="mb-0">A/c No. : 01370500000868</p>
                          <p class="mb-0">
                            Branch & IFSC Code : Udaipur Main Branch &
                            BARB0UDAIPU
                          </p>
                        </div>
                        <div class="invoice-bill-qrcode qr-code border border-dark w-100 h-100 p-1">
                          <div>
                            <span class="">
                              <b class="">Scan to Pay</b>
                            </span>
                            <div class="mt-1">
                              <img
                                src={data?.qr}
                                style={{ width: "140px", height: "100px" }}
                              />
                            </div>
                          </div>
                          <div class="d-flex flex-column justify-content-between mt-3">
                            <p>
                              For <span class="fw-bold">NM</span> India Bio
                              Private Limited
                            </p>
                            <p class="mb-0">Authorized Signatory</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="border border-dark text-center p-2">
                      This is a computer generated Invoice
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Dialog> */}
          </>
        </div>

        <div className="container-fluid">
          {/* hearder breadcrumb */}
          <div className="card-header">
            <strong className="top-left-heading">Accounts</strong>

            <ol className="headerroutes breadcrumb m-0 bg-none ">
              <li className="item color-gray">|</li>
              <li className="item">
                <Link to="/admin/dashboard" className="route-heading item mx-2">
                  Dashboard
                </Link>
              </li>
              <li className="item">
                <FaGreaterThan className="grater-than-route" />
              </li>
              <li className="route-heading item mx-2 mt-1">Accounts</li>
            </ol>
          </div>
          <div className="tab-content ">
            <div
              className="tab-pane fade show active"
              id="user-list"
              role="tabpanel"
            >
              <MergeModal
                isOpen={showModal}
                onConfirm={handleConfirm}
                onCancel={closeModal}
                orderItemsIds={selectedItems}
              />
              <div className="card">
                <div className="card-body">
                  {logistics.map((logistic, index) => {
                    return (logistic &&
                      logistic.splited_group_items.sort((a, b) => a - b)
                        .length > 0) ||
                      logistic.merged_group_items.sort((a, b) => a - b).length >
                        0 ? (
                      <div
                        key={`${logistic.group_name}-${index}`}
                        style={{ padding: "0px 0px 6px 0px" }}
                      >
                        <button className="accordion-custome">
                          <div className="d-flex">
                            <span className="mr-auto p-2 ">
                              DEALER LOCATION{" "}
                              {logistic?.group_name?.toUpperCase()}
                            </span>
                          </div>
                        </button>
                        <div className="panel1">
                          {/* Splite Items Table Start */}
                          {(() => {
                            return logistic.splited_group_items.length <=
                              0 ? null : (
                              <div className="flex">
                                <div className="caret1">
                                  <div className="splited-items">
                                    <p className="account_heading mb-0">
                                      <strong># Splited Orders</strong>
                                    </p>
                                    <div className="table-responsive">
                                      <table className="table account-table">
                                        <thead>
                                          <tr>
                                            <td>
                                              <b>S.No</b>
                                            </td>
                                            <td>
                                              <b>Order No</b>
                                            </td>
                                            <td>
                                              <b>Dealer Name</b>
                                            </td>
                                            <td>
                                              <b>Order Date</b>
                                            </td>
                                            <td>
                                              <b>Vehicle No</b>
                                            </td>
                                            <td>
                                              <b>Actions</b>
                                            </td>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {(() => {
                                            let htmlDataFirst = [];
                                            for (
                                              let i = 0;
                                              i <
                                              logistic.splited_group_items
                                                .length;
                                              i++
                                            ) {
                                              htmlDataFirst.push(
                                                <tr
                                                  key={`${logistic.group_name}-${logistic.splited_group_items[i].name}`}
                                                >
                                                  {
                                                    <>
                                                      <td>{i + 1}</td>
                                                      <td>
                                                        <Link
                                                          // className="btn btn-default btn-sm"
                                                          to={`/admin/account/view/${logistic.splited_group_items[i].split_id}/${logistic.splited_group_items[i].order_id}`}
                                                        >
                                                          #
                                                          {
                                                            logistic
                                                              .splited_group_items[
                                                              i
                                                            ].order_no
                                                          }
                                                        </Link>
                                                      </td>
                                                      <td>
                                                        <span>
                                                          {
                                                            logistic
                                                              .splited_group_items[
                                                              i
                                                            ].name
                                                          }
                                                        </span>
                                                      </td>
                                                      <td>
                                                        <span>
                                                          {formatDate(
                                                            logistic
                                                              .splited_group_items[
                                                              i
                                                            ].order_date
                                                          )}
                                                        </span>
                                                      </td>
                                                      <td>
                                                        {
                                                          logistic
                                                            .splited_group_items[
                                                            i
                                                          ].vehicle_no
                                                        }
                                                      </td>
                                                      {(() => {
                                                        return (
                                                          <td className="">
                                                            {/* {logistic
                                                              .splited_group_items[
                                                              i
                                                            ].invoice_url !=
                                                            undefined ? (
                                                              <Link
                                                                to={`${logistic.splited_group_items[i].invoice_url}`}
                                                                target="_blank"
                                                                download
                                                                className="btn btn-default btn-sm ml-1 download-invoice"
                                                              >
                                                                Download Invoice
                                                              </Link>
                                                            ) : (
                                                              <button
                                                                onClick={(
                                                                  event
                                                                ) => {
                                                                  generateInvoice(
                                                                    event,
                                                                    logistic
                                                                      .splited_group_items[
                                                                      i
                                                                    ].split_id,
                                                                    null,
                                                                    logistic
                                                                      .splited_group_items[
                                                                      i
                                                                    ].order_id
                                                                  );
                                                                  setVisible(
                                                                    true
                                                                  );
                                                                }}
                                                                className="btn btn-primary btn-sm ml-1 generate-invoice"
                                                              >
                                                                Generate Invoice
                                                              </button>
                                                            )} */}
                                                            <button
                                                              onClick={(
                                                                event
                                                              ) => {
                                                                generateInvoice(
                                                                  event,
                                                                  logistic
                                                                    .splited_group_items[
                                                                    i
                                                                  ].split_id,
                                                                  null,
                                                                  logistic
                                                                    .splited_group_items[
                                                                    i
                                                                  ].order_id,
                                                                  logistic
                                                                    .splited_group_items[
                                                                    i
                                                                  ].order_no
                                                                );
                                                                setVisible(
                                                                  true
                                                                );
                                                              }}
                                                              // className="btn btn-primary btn-sm ml-1 generate-invoice"
                                                              className="btn btn-default btn-sm ml-1 download-invoice"
                                                            >
                                                              {/* Generate Invoice */}
                                                              Download Invoice
                                                            </button>
                                                          </td>
                                                        );
                                                      })()}
                                                    </>
                                                  }
                                                </tr>
                                              );
                                            }
                                            return htmlDataFirst;
                                          })()}
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })()}
                          {/* Splite Items Table end */}
                          {/* Merge Items Table Start */}
                          {logistic.merged_group_items.length > 0
                            ? (() => {
                                return (
                                  <div className="flex mt-5">
                                    <div className="caret">
                                      <div className="splited-items">
                                        <p className="account_heading">
                                          <strong># Merged Orders</strong>
                                        </p>
                                        {(() => {
                                          return logistic.merged_group_items
                                            .length <= 0
                                            ? null
                                            : logistic.merged_group_items.map(
                                                (mergedItems, index) => {
                                                  return (
                                                    <>
                                                      {/* <div className="d-flex flex-row">
                                                        <div>
                                                          #{index+1}
                                                        </div>
                                                      </div> */}
                                                      <div className="table-responsive">
                                                        <table className="table account-table">
                                                          <thead>
                                                            <tr>
                                                              <td>
                                                                <b>
                                                                  {index + 1} .
                                                                  Order No
                                                                </b>
                                                              </td>
                                                              <td>
                                                                <b>
                                                                  Dealer Name
                                                                </b>
                                                              </td>

                                                              <td>
                                                                <b>
                                                                  Order Date
                                                                </b>
                                                              </td>
                                                              <td>
                                                                <b>
                                                                  Vehicle No
                                                                </b>
                                                              </td>
                                                              <td>
                                                                <b>Actions</b>
                                                              </td>
                                                            </tr>
                                                          </thead>
                                                          <tbody>
                                                            {(() => {
                                                              let htmlDataFirst =
                                                                [];
                                                              for (
                                                                let i = 0;
                                                                i <
                                                                mergedItems
                                                                  .group_items
                                                                  .length;
                                                                i++
                                                              ) {
                                                                htmlDataFirst.push(
                                                                  <tr
                                                                    key={`${mergedItems.group_items[i].name}-${mergedItems.group_items[i].order_no}`}
                                                                  >
                                                                    {
                                                                      <>
                                                                        <td>
                                                                          <span>
                                                                            <Link
                                                                              // className="btn btn-default btn-sm"
                                                                              to={`/admin/account/view/merge/${mergedItems.merge_id}`}
                                                                            >
                                                                              #
                                                                              {
                                                                                mergedItems
                                                                                  .group_items[
                                                                                  i
                                                                                ]
                                                                                  .order_no
                                                                              }
                                                                            </Link>
                                                                          </span>
                                                                        </td>
                                                                        <td>
                                                                          <span>
                                                                            {
                                                                              mergedItems
                                                                                .group_items[
                                                                                i
                                                                              ]
                                                                                .name
                                                                            }
                                                                          </span>
                                                                        </td>
                                                                        <td>
                                                                          <span>
                                                                            {formatDate(
                                                                              mergedItems
                                                                                .group_items[
                                                                                i
                                                                              ]
                                                                                .order_date
                                                                            )}
                                                                          </span>
                                                                        </td>
                                                                        <td>
                                                                          {
                                                                            mergedItems
                                                                              .group_items[
                                                                              i
                                                                            ]
                                                                              .vehicle_no
                                                                          }
                                                                        </td>
                                                                        <td>
                                                                          {/* {mergedItems
                                                                            .group_items[
                                                                            i
                                                                          ]
                                                                            .invoice_url !=
                                                                          undefined ? (
                                                                            <Link
                                                                              to={`${mergedItems.group_items[i].invoice_url}`}
                                                                              target="_blank"
                                                                              download
                                                                              className="btn btn-default btn-sm ml-1 download-invoice"
                                                                            >
                                                                              Download
                                                                              Invoice
                                                                            </Link>
                                                                          ) : (
                                                                            ""
                                                                          )} */}

                                                                          <button
                                                                            onClick={(
                                                                              event
                                                                            ) => {
                                                                              generateInvoice(
                                                                                event,
                                                                                null,
                                                                                mergedItems.merge_id,
                                                                                mergedItems
                                                                                  .group_items[
                                                                                  i
                                                                                ]
                                                                                  .order_id,
                                                                                mergedItems
                                                                                  .group_items[
                                                                                  i
                                                                                ]
                                                                                  .order_no
                                                                              );
                                                                            }}
                                                                            className="btn btn-default btn-sm ml-1 download-invoice"
                                                                          >
                                                                            Download
                                                                            Invoice
                                                                          </button>
                                                                        </td>
                                                                      </>
                                                                    }
                                                                  </tr>
                                                                );
                                                              }
                                                              return htmlDataFirst;
                                                            })()}
                                                          </tbody>
                                                        </table>
                                                      </div>
                                                    </>
                                                  );
                                                }
                                              );
                                        })()}
                                      </div>
                                    </div>
                                  </div>
                                );
                              })()
                            : null}
                          {/* Merge Items Table end */}
                        </div>
                      </div>
                    ) : (
                      ""
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Logistic;
