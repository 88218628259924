import { Fragment, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import AuthContext from '../../context/Auth/AuthContext';
import { FaUserCircle } from "react-icons/fa";
import { Link } from "react-router-dom";
import AuthContext from "../../Auth/AuthContext";
import axiosPrivate from "../../../hooks/axiosPrivate";
import { error } from "jquery";

const Header = (props) => {
  const { setUser } = useContext(AuthContext);
  const [notification, setNotification] = useState([]);
  const navigate = useNavigate();
  const [user, setUserss] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [userId, setUserId] = useState("");

  const logoutHandler = (e) => {
    setUser({});
    localStorage.removeItem("userData");
    localStorage.removeItem("userRefreshToken");
    localStorage.removeItem("userDetails");
    navigate("/user-panel/signin");
  };

  useEffect(() => {
    if (localStorage.getItem("userDetails")) {
      const userDetails = JSON.parse(localStorage.getItem("userDetails"));
      const { id } = userDetails;
      setUserId(id);

      axiosPrivate
        .get(`notifications/${id}`)
        .then((res) => setNotification(res?.data?.data))
        .catch((error) => {
          if (error.data?.code == 404) {
          }
        });
    }
  }, [refresh]);

  function formatDate(inputDateStr) {
    const inputDate = new Date(inputDateStr);
    const currentDate = new Date();
    const yesterdayDate = new Date();
    yesterdayDate.setDate(currentDate.getDate() - 1);

    if (inputDate.toDateString() === currentDate.toDateString()) {
      return inputDate.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      });
    }

    if (inputDate.toDateString() === yesterdayDate.toDateString()) {
      return "Yesterday";
    }

    return inputDate.toLocaleDateString("en-GB", {
      day: "numeric",
      month: "numeric",
      year: "numeric",
    });
  }

  const notificatonReadHandle = (data) => {
    axiosPrivate
      .put(`notifications/${data.id}/1`, data)
      .then((res) => {
        setRefresh(true);
      })
      .catch((error) => {});
  };
  const allNotificatonReadHandle = (data) => {
    data.map((data) => {
      axiosPrivate
        .put(`notifications/${data.id}/1`, data)
        .then((res) => {
          setRefresh(true);
        })
        .catch((error) => {});
    });
  };

  return (
    <div>
      <div
        id="page_top"
        className="section-body "
        style={{ backgroundColor: "#004583" }}
      >
        <div className="container-fluid">
          <div className="page-header">
            <div className="left"></div>

            <div className="right">
              <div className="notification d-flex">
                <div className="dropdown d-flex">
                  <a
                    href="/#"
                    className="nav-link icon d-none d-md-flex btn btn-default btn-icon ml-1 justify-content-end"
                    data-toggle="dropdown"
                  >
                    <i
                      className="fa fa-bell text-white"
                      style={{ fontSize: "21px" }}
                    ></i>
                    <span
                      className="badge badge-primary nav-unread "
                      style={{ backgroundColor: "green", color: "green" }}
                    ></span>
                  </a>
                  <div
                    className="dropdown-menu dropdown-menu-right dropdown-menu-arrow"
                    style={{
                      zIndex: "99999px",
                      position: "absolute",
                      willChange: "transform",
                      top: "0px",
                      left: "0px",
                      transform: "translate3d(-263px, 35px, 0px)",
                      minWidth: "350px",
                    }}
                  >
                    <ul className="list-unstyled feeds_widget">
                      {notification?.map((data) => (
                        <li onClick={(e) => notificatonReadHandle(data)}>
                          <div className="feeds-left">
                            <i className="fa fa-check"></i>
                          </div>
                          <div className="feeds-body">
                            <h4 className="title text-danger">
                              {data?.title}
                              <small className="float-right text-muted">
                                {formatDate(data?.created_at)}
                              </small>
                            </h4>
                            <small>{data?.description}</small>
                          </div>
                        </li>
                      ))}
                    </ul>
                    <div className="dropdown-divider"></div>
                    <p
                      onClick={(e) => allNotificatonReadHandle(notification)}
                      className="text-center text-muted-dark readall"
                    >
                      {notification?.length > 0
                        ? "Mark all as read"
                        : "No notifications found"}
                    </p>
                  </div>
                </div>

                {/* user Profile */}
                <div className="dropdown d-flex">
                  <a
                    href="/#"
                    className="nav-link icon d-none d-md-flex btn btn-default btn-icon ml-1"
                    data-toggle="dropdown"
                  >
                    {/* <FaUserCircle size={25} /> */}
                    {/* <i className="fa fa-user text-white" style={{ fontSize: '25px' }}></i> */}
                    <FaUserCircle
                      className="text-white"
                      style={{ fontSize: "25px" }}
                    />
                  </a>
                  <div className="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                    <Link
                      className="dropdown-item"
                      to={`/admin/userprofile/${userId}`}
                    >
                      <i
                        className="dropdown-icon fe fe-edit"
                        style={{ fontSize: "15px " }}
                      ></i>
                      {user && user.name ? user.name : "Profile"}
                    </Link>
                    <a className="dropdown-item" onClick={logoutHandler}>
                      <i className="dropdown-icon fe fe-log-out"></i> Log out
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
