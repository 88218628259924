import { Fragment, useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import { showErrorToast } from "../../Toster";
import Piechart from "../../context/components/common/piechart";
import Stackedchart from "../../context/components/common/stackedchart";
import axiosPrivate from "../../hooks/axiosPrivate";
import LoaderContext from "../../context/loader/LoaderContext";
import { BiEdit } from "react-icons/bi";
import { FiTarget, FiUsers } from "react-icons/fi";
import { FaShippingFast } from "react-icons/fa";
import { TbCoinRupee, TbReport } from "react-icons/tb";
import { GoTasklist } from "react-icons/go";
import Columnchart from "../../context/components/common/columnchart";
import { error } from "jquery";
import ColumnWithMarkers from "../../context/components/common/ColumnWithMarkers";
import Donutchart from "../../context/components/common/donutchart";
import Accountpiechart from "../../context/components/common/Accountpiechart";
import DispatchPieChart from "../../context/components/common/DispatchPieChart";
import DispatchStatusChart from "../../context/components/common/DispatchStatusChart";
import AccountInvoice from "../../context/components/common/AccountInvoice";
import Piechart2 from "../../context/components/common/piechart2";
const DashBoard = () => {
  const setIsLoading = useContext(LoaderContext);
  const [userCount, setUserCount] = useState(0);
  const [userName, setUserName] = useState(0);
  const [orderState, setOrderState] = useState([]);
  const [orders, setOrders] = useState([]);
  const [userRole, setUserRole] = useState("");
  const [logistics, setLogistics] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [itemDetails, setItemDetails] = useState([]);
  const [dispatch, setDishpatch] = useState([]);
  const [ageWiseData, setAgeWiseData] = useState([]);
  const [salesTarget, setSalesTarget] = useState([]);
  const [ecomOrders, setEcomOrders] = useState([]);

  // get All nmStaff list and set total user count
  useEffect(() => {
    let user_name = JSON.parse(localStorage.getItem("userDetails"))?.name;
    setUserName(user_name);

    //users count
    axiosPrivate
      .post("users")
      .then((users) => {
        const usersNumber = users.data.data;
        const totalCount = usersNumber.length; // Count the total number of users
        setUserCount(totalCount); // Set the count in the userCount state
      })
      .catch((error) => {
        if (error.response) {
          //response status is an error code
          showErrorToast("Internal server error");
        }
      });

    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    if (userDetails) {
      const { roles, name, id } = userDetails;
    }

    axiosPrivate
      .get("orders")
      .then((order) => {
        setOrders(order?.data.data);
        
        const orderData = order?.data?.data?.map((value, key) => {
          let buttons = [];
          let order_status_1;
          buttons.push(
            <Link
              key={`editButton##${key}`}
              type="button"
              to={`/admin/order/edit/${value.order_uid}`}
              className="btn btn-icon"
              title="Edit"
            >
              <BiEdit style={{ color: "green" }} size={18} />
            </Link>
          );

          order_status_1 =
            value.order_status === "confirmed" ? (
              <button
                className="tag  button-status"
                style={{ background: "#b6cb43", color: "white" }}
              >
                Confirmed
              </button>
              
            ) : value.order_status === "cancelled" ? (
              <button
                className="tag  button-status"
                style={{ background: "#5a6268", color: "white" }}
              >
                Cancelled
              </button>
              
            ) : (
              <button className="tag tag-warning button-status">Pending</button>
            );
          value["action"] = buttons.length > 0 ? buttons : "-";
          value["order_status_1"] = order_status_1;
          return value;
        });

        setOrderState(orderData);
        
        // setIsLoading(false);
      })
      .catch((error) => {
        // setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    if (localStorage.getItem("userDetails")) {
      const userDetails = JSON.parse(localStorage.getItem("userDetails"));
      const { roles } = userDetails;
      const { id } = userDetails;
      setUserRole(roles);
    }
  }, []);

  useEffect(() => {
    axiosPrivate
      .get("logistics/list/items")
      .then((response) => {
        setLogistics(response?.data?.data);
      })
      .catch((error) => {});
  }, []);

  useEffect(() => {
    axiosPrivate
      .get("accounting/list/items")
      .then((response) => {
        // Extracting all splited_group_items
      const splitedData = response?.data?.data
      ?.filter((item) => item?.splited_group_items?.length > 0)
      ?.flatMap((item) => item?.splited_group_items);


       
    // Extracting all merged_group_items
    const mergedData = response?.data?.data
      ?.filter((item) => item?.merged_group_items?.length > 0)
      ?.flatMap((item) => item.merged_group_items);
      // Combine both arrays into finalData
      const allData = [...(splitedData || []), ...(mergedData || [])];
      setAccounts(allData);
      setItemDetails(response?.data?.data);
      })
      .catch((error) => {});
  }, []);

  useEffect(() => {
    axiosPrivate
      .get("dispatch/list/items")
      .then((response) => {
        

      // Extracting all splited_group_items
      const splitedData = response?.data?.data
        ?.filter((item) => item?.splited_group_items?.length > 0)
        ?.flatMap((item) => item.splited_group_items);

      // Extracting all merged_group_items
      const mergedData = response?.data?.data
        ?.filter((item) => item?.merged_group_items?.length > 0)
        ?.flatMap((item) => item.merged_group_items);

      // Combine both arrays into finalData
      const allData = [...(splitedData || []), ...(mergedData || [])];
        setDishpatch(allData);
      })
      .catch((error) => {});

    axiosPrivate
      .post("tally/fetch/age-wise-report")
      .then((res) => {
        setAgeWiseData(res?.data?.data);
      })
      .catch((error) => {});

      const options = {
        headers: { "content-type": "application/json" },
      };

      axiosPrivate
          .post("sales-target", options)
          .then((response) => {
            setSalesTarget(response?.data?.data);
          })
          .catch((error) => {});

          axiosPrivate
          .get("ecom/orders")
          .then((response) => {
            setEcomOrders(response?.data?.data);
          })
          .catch((error) => {});
  }, []);

  

  function formatDate(inputDate) {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const date = new Date(inputDate);
    const day = date.getUTCDate();
    const month = months[date.getUTCMonth()];
    const year = date.getUTCFullYear();

    return `${day} ${month} ${year}`;
  }
  function isSameDate(date1, date2) {
    return (
      date1.getDate() === date2.getDate() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getFullYear() === date2.getFullYear()
    );
  }
  const LogisticData = logistics.filter((res) => {
    return res?.group_items?.length > 0;
  });



  const invoiceMergedItems = itemDetails?.filter((item) => item?.merged_group_items?.length > 0)?.flatMap((invoice) => 
    invoice?.merged_group_items?.flatMap((item) => item?.group_items)?.filter((total) => total?.invoice_generated === 1) // Filter items with invoice_generated === 1
  );
  

  const invoiceSplitedItems = itemDetails?.filter((item) => item?.splited_group_items?.length > 0)?.flatMap((invoice) =>
    invoice?.splited_group_items?.filter((total) => total?.invoice_generated === 1) // Filter for invoice_generated === 1
  );

  

  const accountInvoiceGenerated = [
    ...invoiceMergedItems,
    ...invoiceSplitedItems,
  ];
  const accountInvoiceNotGenerated = [];

  //account
  const mergedAccountItems = itemDetails?.filter(
    (res) => res?.merged_group_items?.length > 0
  );
  
  
  const splitedAccountItems = itemDetails?.filter(
    (res) => res?.splited_group_items?.length > 0
  );
  

  
  //dispatch
  const mergedDishpatchItems = dispatch.filter(
    (res) => res?.merged_group_items?.length > 0
  );
  const splitedDishpatchItems = dispatch.filter(
    (res) => res?.splited_group_items?.length > 0
  );

  const totalMergedDishpatchItems = dispatch?.flatMap(
    (res) => res?.merged_group_items?.map(
      (item) => item)
  );
  const totalSplitedDishpatchItems = dispatch?.flatMap(
    (res) => res?.splited_group_items?.map((item) => item)
  );

  

  return (
    <Fragment>
      <div>
        <div className={`section-body marginTop} `}>
          <div className="container-fluid">
            <div className="row clearfix">
              <div className="col-lg-12 mt-4">
                <div className={`section-body mb-4 mt-3}`}>
                  <h4>Welcome {userName}!</h4>
                  {/* <small>
                    Measure How Fast You’re Growing Monthly Recurring Revenue.
                    <a href="fake_url">Learn More</a>
                  </small> */}
                </div>
              </div>
            </div>
            {userRole == "admin" && (
              <div className="row clearfix">
                <div className="col-lg-12"></div>

                {/* Dashboard user count */}
                <div className="col-6 col-md-4 col-xl-2">
                  <div className="card">
                    <div className="card-body ribbon">
                      <Link
                        to="/admin/users"
                        className="my_sort_cut text-muted"
                      >
                        <div className="ribbon-box orange">{userCount}</div>
                        <FiUsers size="1.2rem" />
                        <span style={{ fontSize: "0.8rem" }}>Users</span>
                      </Link>
                    </div>
                  </div>
                </div>
                {/* Dashboadrd holodays */}
                <div className="col-6 col-md-4 col-xl-2">
                  <div className="card">
                    <div className="card-body ribbon">
                    
                        <Link to="/admin/Ecom-orders" className="my_sort_cut text-muted">
                          <div className="ribbon-box orange">{ecomOrders?.length}</div>
                          <GoTasklist size="1.2rem" />
                          
                          <span style={{ fontSize: "0.8rem" }}>
                            Ecom. Orders
                          </span>
                        </Link>
                     
                    </div>
                  </div>
                </div>

                {/* dashboard event */}
                <div className="col-6 col-md-4 col-xl-2">
                  <div className="card">
                    <div className="card-body ribbon">
                      <Link
                        to="/admin/sales-target"
                        className="my_sort_cut text-muted"
                      >
                        <div className="ribbon-box orange">{salesTarget?.length}</div>
                        {/* <i
                          className="icon-calendar"
                          style={{ fontSize: "1.2rem" }}
                        /> */}
                        <FiTarget size="1.2rem" />
                        <span style={{ fontSize: "0.8rem" }}>
                          Sales Targets
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>

                {/* dashboard payroll */}
                <div className="col-6 col-md-4 col-xl-2">
                  <div className="card">
                    <div className="card-body ribbon">
                      <Link to="/admin/dispatch">
                        <div className="ribbon-box orange">{dispatch?.length}</div>
                        <a className="my_sort_cut text-muted">
                          <FaShippingFast size="1.2rem" />
                          <span style={{ fontSize: "0.8rem" }}>Dispatched</span>
                        </a>
                      </Link>
                    </div>
                  </div>
                </div>

                {/* dashboard Account */}
                <div className="col-6 col-md-4 col-xl-2">
                  <div className="card">
                    <Link to="/admin/account">
                      <div className="card-body ribbon">
                        <div className="ribbon-box orange">{accounts?.length}</div>
                        <a className="my_sort_cut text-muted">
                          <TbCoinRupee size="1.2rem" />
                          <span style={{ fontSize: "0.8rem" }}>Accounts</span>
                        </a>
                      </div>
                    </Link>
                  </div>
                </div>

                {/* dashboard Report */}
                <div className="col-6 col-md-4 col-xl-2">
                  <div className="card">
                    <Link to="/admin/reports">
                      <div className="card-body">
                        <a className="my_sort_cut text-muted">
                          <TbReport size="1.2rem" />
                          <span style={{ fontSize: "0.8rem" }}>Reports</span>
                        </a>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="section-body">
          <div className="container-fluid">
            {/* <Stackedchart props = {orderState} /> */}
            <div className="row clearfix row-deck">
              {userRole == "admin" && (
                <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12">
                  <div className="card">
                    <div className="card-header">
                      <h3 className="card-title font-weight-bold">Revenue</h3>
                    </div>
                    <div className="card-body">
                      <Stackedchart data={orders}></Stackedchart>
                    </div>
                    <div className="card-footer">
                      <div className="d-flex justify-content-between align-items-center">
                        <Link
                          style={{ fontSize: "17px" }}
                          to="/admin/order"
                          className="btn btn-block btn-success btn-sm"
                        >
                          View Details
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* {userRole == "dealer" && (
                <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12">
                  <div className="card">
                    <div className="card-header">
                      <h3 className="card-title font-weight-bold">
                        Outstanding Amount Summary
                      </h3>
                    </div>
                    <div className="card-body">
                      <ColumnWithMarkers ageWiseData={ageWiseData} />
                    </div>
                  </div>
                </div>
              )} */}
              {userRole == "area_manager" && (
                <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12">
                  <div className="card">
                    <div className="card-header">
                      <h3 className="card-title font-weight-bold">
                        Sales Target Summary
                      </h3>
                    </div>
                    <div className="card-body">
                      <Columnchart />
                    </div>
                    {/* <div className="card-footer">
                      <div className="d-flex justify-content-between align-items-center">
                        <a
                          style={{ fontSize: "17px" }}
                          href="/admin/dashboard"
                          className="btn btn-block btn-success btn-sm"
                        >
                          View Details
                        </a>
                      </div>
                    </div> */}
                  </div>
                </div>
              )}
              {(userRole == "admin" || userRole == "area_manager") && (
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                  <div className="card">
                    <div className="card-header">
                      <h3 className="card-title font-weight-bold">Pragati Orders</h3>
                    </div>
                    <Piechart orders={orders} ageWiseData={ageWiseData} />
                    {/* <p className="text-center"></p> */}
                    <div className="card-body text-center">
                      <h5 className="mt-4">{orders?.length}</h5>
                      <div className="mb-0 mt-3 font300">
                        {/* <TbCurrencyRupee /> {totalAmount} */}
                        Total Orders 
                      </div>
                    </div>
                    <div className="card-footer">
                      <Link
                        style={{ fontSize: "17px" }}
                        to="/admin/order"
                        className="btn btn-block btn-success btn-sm "
                      >
                        View Details
                      </Link>
                    </div>
                  </div>
                </div>
              )}

              {/* {userRole == "dealer" && (
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                  <div className="card">
                    <div className="card-header">
                      <h3 className="card-title font-weight-bold">
                        Total Outstanding
                      </h3>
                    </div>
                    <Piechart2 ageWiseData={ageWiseData} />
                    <div className="card-body text-center">
                      <div className="mt-4"></div>
                      <h3 className="mb-0 mt-3 font300">
                      </h3>
                    </div>
                    <div className="card-footer">
                      <Link
                        style={{ fontSize: "17px" }}
                        to="/admin/order"
                        className="btn btn-block btn-success btn-sm "
                      >
                        View Details
                      </Link>
                    </div>
                  </div>
                </div>
              )} */}
              {userRole == "logistic_manager" && (
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                  <div className="card">
                    <div className="card-header">
                      <h3 className="card-title font-weight-bold">
                        State Wise Remaining Order Items Quantity
                      </h3>
                    </div>
                    <Donutchart data={LogisticData} />
                    <div className="card-body text-center">
                      <div className="mt-4"></div>
                      <h3 className="mb-0 mt-3 font300">
                        {/* <TbCurrencyRupee /> {totalAmount} */}
                      </h3>
                    </div>
                    <div className="card-footer">
                      <Link
                        style={{ fontSize: "17px" }}
                        to="/admin/logistics"
                        className="btn btn-block btn-success btn-md "
                      >
                        View Details
                      </Link>
                    </div>
                  </div>
                </div>
              )}
              {/* <div className="col-1"></div> */}
              {userRole == "accounts" && (
                <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12">
                  <div className="card">
                    <div className="card-header">
                      <h3 className="card-title font-weight-bold">
                        Order Items
                      </h3>
                    </div>
                    <Accountpiechart
                      splitedItems={splitedAccountItems}
                      mergedItems={mergedAccountItems}
                    />
                    <div className="card-body text-center">
                      <div className="mt-4"></div>
                      <h3 className="mb-0 mt-3 font300">
                        {/* <TbCurrencyRupee /> {totalAmount} */}
                      </h3>
                    </div>
                    <div className="card-footer">
                      <Link
                        style={{ fontSize: "17px" }}
                        to="/admin/account"
                        className="btn btn-block btn-success btn-md "
                      >
                        View Details
                      </Link>
                    </div>
                  </div>
                </div>
              )}
              {userRole == "accounts" && (
                <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12">
                  <div className="card">
                    <div className="card-header">
                      <h3 className="card-title font-weight-bold">Invoice</h3>
                    </div>
                    <AccountInvoice
                      invoiceGenerated={accountInvoiceGenerated}
                      invoiceNotGenerated={accountInvoiceNotGenerated}
                    />
                    <div className="card-body text-center">
                      <div className="mt-4"></div>
                      <h3 className="mb-0 mt-3 font300">
                        {/* <TbCurrencyRupee /> {totalAmount} */}
                      </h3>
                    </div>
                    <div className="card-footer">
                      <Link
                        style={{ fontSize: "17px" }}
                        to="/admin/account"
                        className="btn btn-block btn-success btn-md "
                      >
                        View Details
                      </Link>
                    </div>
                  </div>
                </div>
              )}
              {/* <div className="col-1"></div>
              <div className="col-1"></div> */}
              {userRole == "dispatch_team" && (
                <>
                <div className="col-xl-5 col-lg-6 col-md-6 col-sm-12">
                  <div className="card">
                    <div className="card-header">
                      <h3 className="card-title  font-weight-bold">
                        Order items
                      </h3>
                    </div>
                    <DispatchPieChart
                      mergedItems={totalMergedDishpatchItems}
                      spilitedItems={totalSplitedDishpatchItems}
                    />
                    <div className="card-body text-center">
                      <div className="mt-4"></div>
                      <h3 className="mb-0 mt-3 font300">
                        {/* <TbCurrencyRupee /> {totalAmount} */}
                      </h3>
                    </div>
                    <div className="card-footer">
                      <Link
                        style={{ fontSize: "17px" }}
                        to="/admin/dispatch"
                        className="btn btn-block btn-success btn-md "
                      >
                        View Details
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="col-xl-5 col-lg-6 col-md-6 col-sm-12">
                  <div className="card">
                    <div className="card-header">
                      <h3 className="card-title  font-weight-bold">
                        Orders Status
                      </h3>
                    </div>
                    <DispatchStatusChart dispatch={dispatch} />
                    <div className="card-body text-center">
                      <div className="mt-4"></div>
                      <h3 className="mb-0 mt-3 font300">
                        {/* <TbCurrencyRupee /> {totalAmount} */}
                      </h3>
                    </div>
                    <div className="card-footer">
                      <Link
                        style={{ fontSize: "17px" }}
                        to="/admin/dispatch"
                        className="btn btn-block btn-success btn-md "
                      >
                        View Details
                      </Link>
                    </div>
                  </div>
                </div>
                </>
              )}

              {/* {userRole == "dispatch_team" && (
                
              )} */}
              {/* <div className="col-1"></div> */}
            </div>
            
            {(userRole == "admin" ||
              userRole == "area_manager" ||
              userRole == "dealer") && (
              <div className="row clearfix">
                <div className="col-12 col-sm-12">
                  <div className="card">
                    <div className="card-header">
                      <h3 className="card-title font-weight-bold">
                        Dealers Order Summary
                      </h3>
                    </div>
                    <div className="card-body">
                      <div
                        className="table-responsive"
                        style={{ border: "1px solid #000" }}
                      >
                        <table
                          className="table table-hover table-striped text-nowrap table-vcenter mb-0"
                          style={{ border: "none" }}
                        >
                          <thead>
                            <tr>
                              <th className="font-weight-bold">Order No.</th>
                              {(userRole == "admin" ||
                                userRole == "area_manager") && (
                                <th className="font-weight-bold">
                                  Dealer Name
                                </th>
                              )}
                              <th className="font-weight-bold">Order Date</th>
                              <th className="font-weight-bold">Order Amount</th>
                              {/* <th className="font-weight-bold">Order Punched By</th> */}
                              <th className="font-weight-bold">Order Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {orderState
                              .sort()
                              .slice(0, 5)
                              .map((res) => (
                                <tr>
                                  <td># {res.order_no}</td>
                                  {(userRole == "admin" ||
                                    userRole == "area_manager") && (
                                    <td>{res.name}</td>
                                  )}
                                  <td>{formatDate(res.order_date)}</td>
                                  <td>{res.order_amount}</td>
                                  {/* <td>
                                    {" "}
                                    {res.order_punched_by === "area_manager"
                                      ? "Area Manager"
                                      : res.order_punched_by === "admin"
                                      ? "Admin"
                                      : res.order_punched_by === "dealer"
                                      ? "Dealer"
                                      : res.order_punched_by}
                                  </td> */}
                                  <td>{res.order_status_1}</td>
                                </tr>
                              ))}
                          </tbody>
                          <div className="card-footer">
                            <Link
                              style={{ fontSize: "17px" }}
                              to="/admin/order"
                              className="btn btn-block btn-success btn-sm "
                            >
                              View Details
                            </Link>
                          </div>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {userRole == "logistic_manager" && (
              <div className="row clearfix">
                <div className="col-12 col-sm-12">
                  <div className="card">
                    <div className="card-header">
                      <h3 className="card-title font-weight-bold">
                        Logistics Order Summary
                      </h3>
                    </div>
                    <div className="card-body">
                      <div
                        className="table-responsive"
                        style={{ border: "1px solid #000" }}
                      >
                        <table
                          className="table table-hover table-striped text-nowrap table-vcenter mb-0"
                          style={{ border: "none" }}
                        >
                          <thead>
                            <tr>
                              <th className="font-weight-bold">Order No.</th>
                              <th className="font-weight-bold">Area</th>
                              <th className="font-weight-bold">City</th>
                              <th className="font-weight-bold">State</th>
                              {/* <th className="font-weight-bold">Order Items</th> */}
                              <th className="font-weight-bold">Date / Time </th>
                            </tr>
                          </thead>
                          <tbody>
                            {LogisticData.sort((a, b) => a - b)
                              .slice(0, 5)
                              .map((res) => (
                                <tr>
                                  <td>
                                    #{res?.group_items[0]?.group?.order_no}
                                  </td>
                                  <td>{res?.group_name}</td>
                                  <td>{res?.city_name}</td>
                                  <td>{res?.state_name}</td>
                                  {/* <td>
                                    {res?.group_items[0]?.order_items?.length
                                      ? res?.group_items[0]?.order_items?.length
                                      : 0}
                                  </td> */}
                                  <td>
                                    {formatDate(
                                      res?.group_items[0]?.group?.created_at
                                    ) || "Not Found"}
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                        <div className="card-footer">
                          <Link
                            style={{ fontSize: "17px" }}
                            to="/admin/logistics"
                            className="btn btn-block btn-success btn-md"
                          >
                            View Details
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {userRole == "accounts" && (
              <div className="row clearfix">
                <div className="col-12 col-sm-12">
                  <div className="card">
                    <div className="card-header">
                      <h3 className="card-title font-weight-bold">
                        Merged Order Summary
                      </h3>
                    </div>
                    <div className="card-body">
                      <div
                        className="table-responsive"
                        style={{ border: "1px solid #000" }}
                      >
                        {mergedAccountItems.length > 0 ? (
                          <table
                            className="table table-hover table-striped text-nowrap table-vcenter mb-0"
                            style={{ border: "none" }}
                          >
                            <thead>
                              <tr>
                                <th className="font-weight-bold">Order No.</th>
                                <th className="font-weight-bold">
                                  Dealer Name
                                </th>
                                <th className="font-weight-bold">Address</th>
                                <th className="font-weight-bold">
                                  Vehicle No.
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {mergedAccountItems?.flatMap((res) => res)?.flatMap((item) =>
                                item?.merged_group_items)?.flatMap((item) => item?.group_items)
                                .slice(0, 5)
                                .map((res) => (
                                  <tr>
                                    <td>
                                      {
                                        res?.order_no
                                      }
                                    </td>
                                    <td>
                                      {
                                        res?.name
                                      }
                                    </td>
                                    <td>
                                      {
                                        res?.shipping_address
                                      }
                                    </td>

                                    <td>
                                      {
                                        res?.vehicle_no
                                      }
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        ) : (
                          <test>Merged orders not found</test>
                        )}
                        <div className="card-footer">
                          <Link
                            style={{ fontSize: "17px" }}
                            to="/admin/account"
                            className="btn btn-block btn-success btn-md"
                          >
                            View Details
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {userRole == "accounts" && (
              <div className="row clearfix">
                <div className="col-12 col-sm-12">
                  <div className="card">
                    <div className="card-header">
                      <h3 className="card-title font-weight-bold">
                        Splited Order Summary
                      </h3>
                    </div>
                    <div className="card-body">
                      <div
                        className="table-responsive"
                        style={{ border: "1px solid #000" }}
                      >
                        {splitedAccountItems.length > 0 ? (
                          <table
                            className="table table-hover table-striped text-nowrap table-vcenter mb-0"
                            style={{ border: "none" }}
                          >
                            <thead>
                              <tr>
                                <th className="font-weight-bold">Order No.</th>
                                <th className="font-weight-bold">
                                  Dealer Name
                                </th>
                                <th className="font-weight-bold">Address</th>
                                <th className="font-weight-bold">
                                  Vehicle No.
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {splitedAccountItems?.flatMap((res) => res)?.flatMap((item) =>
                                item?.splited_group_items)
                                .slice(0, 5)
                                .map((res) => (
                                  <tr
                                    key={res?.order_no}
                                  >
                                    <td>
                                      {res?.order_no}
                                    </td>
                                    <td>{res?.name}</td>
                                    <td>
                                      {
                                        res?.shipping_address
                                      }
                                    </td>

                                    <td>
                                      {res?.vehicle_no}
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        ) : (
                          <text>Splited orders not found</text>
                        )}
                        <div className="card-footer">
                          <Link
                            style={{ fontSize: "17px" }}
                            to="/admin/account"
                            className="btn btn-block btn-success btn-md"
                          >
                            View Details
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {userRole == "dispatch_team" && (
              <div className="row clearfix">
                <div className="col-12 col-sm-12">
                  <div className="card">
                    <div className="card-header">
                      <h3 className="card-title font-weight-bold">
                        Merged Order Summary
                      </h3>
                    </div>
                    <div className="card-body">
                      <div
                        className="table-responsive"
                        style={{ border: "1px solid #000" }}
                      >
                        {mergedDishpatchItems?.length > 0 ? (
                          <table
                            className="table table-hover table-striped text-nowrap table-vcenter mb-0"
                            style={{ border: "none" }}
                          >
                            <thead>
                              <tr>
                                <th className="font-weight-bold">Order No.</th>
                                <th className="font-weight-bold">
                                  Dealer Name
                                </th>
                                <th className="font-weight-bold">Address</th>
                                <th className="font-weight-bold">
                                  Vehicle No.
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {mergedDishpatchItems
                                ?.sort((a, b) => a - b)
                                ?.slice(0, 5)
                                ?.map((res) => (
                                  <tr>
                                    <td>
                                      {
                                        res?.merged_group_items[0]
                                          ?.group_items[0]?.order_no
                                      }
                                    </td>
                                    <td>
                                      {
                                        res?.merged_group_items[0]
                                          ?.group_items[0]?.name
                                      }
                                    </td>
                                    <td>
                                      {
                                        res?.merged_group_items[0]
                                          ?.group_items[0]?.shipping_address
                                      }
                                    </td>

                                    <td>
                                      {
                                        res?.merged_group_items[0]
                                          ?.group_items[0]?.vehicle_no
                                      }
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        ) : (
                          <test>Merged orders not found</test>
                        )}
                        <div className="card-footer">
                          <Link
                            style={{ fontSize: "17px" }}
                            to="/admin/dispatch"
                            className="btn btn-block btn-success btn-md"
                          >
                            View Details
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {userRole == "dispatch_team" && (
              <div className="row clearfix">
                <div className="col-12 col-sm-12">
                  <div className="card">
                    <div className="card-header">
                      <h3 className="card-title font-weight-bold">
                        Splited Order Summary
                      </h3>
                    </div>
                    <div className="card-body">
                      <div
                        className="table-responsive"
                        style={{ border: "1px solid #000" }}
                      >
                        {splitedDishpatchItems.length > 0 ? (
                          <table
                            className="table table-hover table-striped text-nowrap table-vcenter mb-0"
                            style={{ border: "none" }}
                          >
                            <thead>
                              <tr>
                                <th className="font-weight-bold">Order No.</th>
                                <th className="font-weight-bold">
                                  Dealer Name
                                </th>
                                <th className="font-weight-bold">Address</th>
                                <th className="font-weight-bold">
                                  Vehicle No.
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {splitedDishpatchItems
                                ?.sort((a, b) => a - b)
                                .slice(0, 5)
                                .map((res) => (
                                  <tr
                                    key={res?.splited_group_items[0]?.order_no}
                                  >
                                    <td>
                                      {res?.splited_group_items[0]?.order_no}
                                    </td>
                                    <td>{res?.splited_group_items[0]?.name}</td>
                                    <td>
                                      {
                                        res?.splited_group_items[0]
                                          ?.shipping_address
                                      }
                                    </td>

                                    <td>
                                      {res?.splited_group_items[0]?.vehicle_no}
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        ) : (
                          <text>Splited orders not found</text>
                        )}
                        <div className="card-footer">
                          <Link
                            style={{ fontSize: "17px" }}
                            to="/admin/dispatch"
                            className="btn btn-block btn-success btn-md"
                          >
                            View Details
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default DashBoard;
