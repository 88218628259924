import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { FaGreaterThan } from "react-icons/fa";
import { GrAddCircle, GrSubtractCircle } from "react-icons/gr";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import AlertContext from "../../../context/Alert/AlertContext";
import axiosPrivate from "../../../hooks/axiosPrivate";
import classes from "./Order.module.css";
import LoaderContext from "../../../context/loader/LoaderContext";
import html2pdf from "html2pdf.js/dist/html2pdf.min";
import ReactDOMServer from "react-dom/server";
import "../Account/invoice.css";
import { showSuccessToast, showErrorToast } from "../../../Toster";
import ReactSelect from "react-select";
import QRCode from "react-qr-code";
import nmLogo from ".././../../assets/images/nm_logo.png";
import paymentQr from ".././../../assets/images/payment_qr.png";
import moment from "moment";
import Creatable from "react-select/creatable";

const EditOrder = () => {
  const { setIsLoading } = useContext(LoaderContext);
  const currentLogedInUserId = JSON.parse(localStorage.getItem("userDetails"));
  let { id } = useParams();

  const { setAlertMessage } = useContext(AlertContext);
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [discount, setDiscount] = useState(0);
  const [salesRoles, setSalesRoles] = useState([]);
  const [dealerRoles, setDealerRoles] = useState([]);
  const [areaManagerRoles, setAreaManagerRoles] = useState([]);
  const [seasonRoles, setSeasonRoles] = useState([]);
  const [seasonDropDown, setSeasonDropDown] = useState(false);
  const [dearlerDropDown, setDealerDropDown] = useState(false);
  const [salesmanDropDown, setSalesmanDropDown] = useState(false);
  const [areamanagerDropDown, setAreaManagerDropDown] = useState(false);
  const [selectDiscountType, setSelectDiscountType] = useState();
  const [logicticDropDown, setLogisticDropDown] = useState(false);
  const [logicticRoles, setLogisticRoles] = useState([]);
  const [shippingAddress, setShippingAddress] = useState([]);
  const [getData, setGetData] = useState({});
  const [freezed, setFreezed] = useState(false);
  const [user_id, setUserId] = useState();
  const [userData, setUserData] = useState();
  const [shippingStates, setShippingStates] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [selectedCity, setSelectedCity] = useState({});
  const [defaultBillingAddress, setDefaultBillingAddress] = useState("");
  const [addressFlag, setAddressFlag] = useState(false);
  const [userRole, setUserRole] = useState("");

  const validationRules = {
    // dealer_id: Yup.string().required("Dealer is required"),
    // areaManager_id: Yup.string().required("Area manager is required"),
    // salesman_id: Yup.string().required("Sales person is required"),
    // image: Yup.string().required("Image is required"),
    logistic_user_id: Yup.string().required("Logistic manager is required"),
    // order_rows: Yup.array()
    //   .of(
    //     Yup.object().shape({
    //       stock_id: Yup.string().required("Product is required"),
    //       price: Yup.number()
    //         .required("Price is required")
    //         .min(0, "Price cannot be negative"),
    //       qty: Yup.number()
    //         .required("Quantity is required")
    //         .min(1, "Quantity must be at least 1"),
    //     })
    //   )
    //   .min(1, "At least one order row is required"),
  };

  const formik = useFormik({
    initialValues: {
      dealer_id: "",
      areamanager_id: "",
      salesman_id: "",
      stock_id: "",
      price: "",
      qty: "",
      discount: "",
      totalAmount: "",
      season_id: "",
      logistic_user_id: "",
      area_manager_remark: "",
      remark: "",
      image: "",
      order_rows: [
        {
          stock_id: "",
          price: "",
          qty: "",
          shipping_address_id: "",
          shipping_address: "",
          shipping_state: "",
          shipping_city: "",
          shipping_zip: "",
        },
      ],
    },
    validationSchema: Yup.object(validationRules),
    onSubmit: async (values, { setSubmitting, setFieldError }) => {
      setIsLoading(true);

      let formData = new FormData();
      formData.append("dealer_id", values.dealer_id);
      formData.append("area_manager_id", values.areamanager_id);
      formData.append("salesman_id", values.salesman_id);
      formData.append("order_rows", JSON.stringify(formik.values.order_rows));
      formData.append("discount_type", values.discount_type);
      formData.append("discount", values.discount);
      formData.append("totalAmount", calculateTotalAmount());
      formData.append("season_id", values.season_id);
      formData.append("order_status", values.order_status);
      formData.append("logistic_user_id", values.logistic_user_id);
      formData.append("current_user_id", currentLogedInUserId.id);
      formData.append("user_note", values.user_note);
      formData.append("image", values.image);
      formData.append("area_manager_remark", values.area_manager_remark);

      await axiosPrivate
        .post(`orders/update/${id}?_method=PATCH`, formData)
        .then((response) => {
          setAlertMessage({ message: "Order Updated Successfully" });
          setIsLoading(false);
          navigate("/admin/order");
        })
        .catch((error) => {
          setIsLoading(false);
        });

      if (values.order_status == "to_logistics") {
        let object = {
          title: "Order Confirmation",
          order_id: formik.values.order_rows[0].order_id,
          user_id: values.logistic_user_id,
          notification_for: "logistic",
        };
        await axiosPrivate
          .post("/notifications/store", object)
          .then((res) => {})
          .catch((err) => {});
      }
    },
  });

  // get by id data
  useEffect(() => {
    let local_id = JSON.parse(localStorage.getItem("userDetails"));
    setUserId(local_id);
    var loginUser = local_id;
    setIsLoading(true);
    //Getting Order Info by id start
    axiosPrivate
      .get(`orders/${id}`)
      .then((response) => {
        if (response.data.code == 200) {
          setFreezed(
            response.data.data.order_status == "to_logistics" ||
              response.data.data.order_status == "confirmed" ||
              response.data.data.order_status == "cancelled"
              ? true
              : false
          );
          setGetData({ ...response.data.data });
          formik.setValues({ ...response.data.data });
        }
        if (getData.discount_type === "Season Discount") {
          setSeasonDropDown(true);
        } else if (getData.discount_type != "Season Discount") {
          setSeasonDropDown(false);
        }
        setIsLoading(false);
      })
      .catch((error) => {});
    //Getting Order Info by id end
  }, [id]);
  // get by id end

  const getSalesPersonDetails = (areamanager_id) => {
    axiosPrivate
      .get(`users/get/sales/person/${areamanager_id}`)
      .then((salesPerson) => {
        setSalesRoles(salesPerson?.data?.data);
      })
      .catch((error) => {});
  };

  //Getting Dealer Shipping addresses start
  useEffect(() => {
    if (getData.dealer_id != undefined && getData.dealer_id != null) {
      axiosPrivate
        .get(`/users/get/addresses/${getData.dealer_id}`)
        .then((addresses) => {
          const dropdownOptions = addresses?.data?.data?.map((item) => {
            if (item?.address_type == "billing_address") {
              setDefaultBillingAddress(item?.id);
            }
            const parts = [
              item.shipping_address,
              item.shipping_city,
              item.shipping_state,
              item.shipping_zip,
            ].filter(Boolean); // Removes any null or undefined values

            return {
              value: item.id,
              label: parts.join(", "), // Joins non-null parts with a comma
            };
          });

          setShippingAddress(dropdownOptions);
        });
    }
    if (getData.order_items != undefined) {
      // setTableData(getData.order_items);

      const finalData = getData?.order_items?.map((item, index) => {
        item.qty = item?.quantity ? item.quantity : item?.dispatch_quantity;
        return item;
      });

      formik.setFieldValue("order_rows", finalData);
    }
    // sales person list
    getSalesPersonDetails(getData.areamanager_id);
    // sales person end
  }, [getData]);
  //Getting Dealer Shipping addresses end

  const SeasondropdownClick = (event) => {
    if (event.target.value == "Season Discount") {
      setSeasonDropDown(true);
    } else if (event.target.value != "Season Discount") {
      setSeasonDropDown(false);
    }
    formik.setFieldValue("discount_type", event.target.value);

    setSelectDiscountType(event.target.value);
  };

  const getState = () => {
    axiosPrivate
      .get("locations/get/state")
      .then((response) => {
        setShippingStates(response.data.data);
      })
      .catch((err) => {});
  };

  //Component mount function start
  useEffect(() => {
    getState();
    const userData = JSON.parse(localStorage.getItem("userDetails") || "{}");
    setUserData(userData);
    if (userData.role == "3" || userData.role == "1") {
      setDealerDropDown(true);
      setSalesmanDropDown(true);
      setAreaManagerDropDown(true);
      setLogisticDropDown(true);
    } else if (userData.role == "4") {
      setSalesmanDropDown(true);
      setAreaManagerDropDown(true);
      setAreaManagerDropDown(false);
    }
    // Logistic list start
    axiosPrivate
      .get("users/get/logistic/manager")

      .then((logistics) => {
        setLogisticRoles(logistics?.data?.data);
      });

    // Logistic list end
    // Season list start
    axiosPrivate
      .post("seasons")
      .then((seasons) => {
        setSeasonRoles(seasons?.data?.data);
      })
      .catch((error) => {});
    // Season list end

    // dealer list start
    axiosPrivate
      .post("dealers")
      .then((dealers) => {
        setDealerRoles(dealers?.data?.data);
      })
      .catch((error) => {});
    // dealer list end
    // Area Manager list start
    axiosPrivate
      .get("users/get/area/manager")
      .then((AreaManager) => {
        setAreaManagerRoles(AreaManager?.data?.data);
      })
      .catch((error) => {});
    // Area Manager list end
    // product list start
    axiosPrivate
      .get("stocks")
      .then((order) => {
        setProducts(order?.data?.data);
      })
      .catch((error) => {});

    // product list end
  }, []);
  //Component mount function end

  useEffect(() => {
    if (Object.keys(selectedCity).length > 0) {
      axiosPrivate
        .post("locations/get/city", { state: selectedCity.name })
        .then((response) => {
          let cities = cityList;
          cities[selectedCity.index] = response.data.data;
          setCityList(cities);
        });
    }
  }, [selectedCity]);

  const numberFilter = (event) => {
    const inputValue = event.target.value;

    const numericValue = inputValue.replace(/\D/g, "");
    formik.setFieldValue(event.target.name, numericValue);
  };

  //Load address id user is
  const setDealerIdHandler = (dealerId) => {
    setAddressFlag(true);
    if (dealerId != "") {
      //Get Dealer address
      axiosPrivate.get(`/users/get/addresses/${dealerId}`).then((addresses) => {
        const dropdownOptions = addresses?.data?.data?.map((item) => {
          if (item?.address_type == "billing_address") {
            setDefaultBillingAddress(item?.id);
          }
          const parts = [
            item.shipping_address,
            item.shipping_city,
            item.shipping_state,
            item.shipping_zip,
          ].filter(Boolean); // Removes any null or undefined values

          return {
            value: item.id,
            label: parts.join(", "), // Joins non-null parts with a comma
          };
        });

        setShippingAddress(dropdownOptions);
      });
      //Get Dealer address
    }
    formik.setFieldValue("dealer_id", dealerId);
    return true;
  };

  useEffect(() => {
    if (defaultBillingAddress) {
      // Update the order rows in Formik state
      formik.setFieldValue(
        "order_rows",
        formik.values.order_rows.map((item) => ({
          ...item,
          shipping_address_id: !addressFlag
            ? !item.shipping_address_id
              ? defaultBillingAddress
              : item?.shipping_address_id
            : defaultBillingAddress,
        }))
      );
    }
  }, [defaultBillingAddress]);
  //Load address

  useEffect(() => {
    calculateTotalAmount();
  }, [formik.values.order_rows, seasonDropDown, discount]);

  const calculateTotalPrice = () => {
    let totalPrice = 0;
    formik.values.order_rows?.forEach((item) => {
      totalPrice += parseInt(item.price) * parseInt(item.qty);
    });
    return totalPrice;
  };

  const calculateTotalAmount = () => {
    let totalPrice = calculateTotalPrice();
    if (discount != null && discount > 0 && discount <= 100) {
      let discountAmount = parseFloat(discount);
      totalPrice -= (parseInt(totalPrice) / 100) * parseInt(discountAmount);
    }
    formik.setFieldValue("totalAmount", totalPrice);
    return parseInt(totalPrice);
  };

  const handleShippingAddressChange = (selectedOption, index) => {
    if (selectedOption.__isNew__) {
      const newAddress = {
        value: Date.now(), // Unique ID for the new address
        label: selectedOption.label,
      };

      // Add the new address to the shippingAddress state
      setShippingAddress((prev) => [...prev, newAddress]);
      formik.setFieldValue(`order_rows[${index}].shipping_address_id`, 0);
      formik.setFieldValue(
        `order_rows[${index}].shipping_address`,
        selectedOption.label
      );
    } else {
      formik.setFieldValue(
        `order_rows[${index}].shipping_address_id`,
        selectedOption.value
      );
    }
  };

  const totalDispatchAmount = () => {
    let amount = 0;
    formik.values.order_rows?.forEach((element) => {
      amount += element.dispatch_quantity * element.rate;
    });
    return amount;
  };
  let amount = totalDispatchAmount();

  const customStyles = {
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isSelected ? "#767676" : "white",
      color: state.isSelected ? "white" : "black",
      "&:hover": {
        backgroundColor: state.isSelected ? "#767676" : "#e0e0e0",
      },
    }),

    control: (base, state) => ({
      ...base,
      borderColor: "#ddd",
      boxShadow: "none",
      backgroundColor: state.isDisabled ? "#e9ecef" : "white", // Set disabled background color
      "&:hover": {
        boxShadow: state.isDisabled ? "none" : "0px 0px 2px 2px #d6d4dd", // Prevent hover effect when disabled
      },
    }),
  };

  // Transform dealerList into the format required by ReactSelect
  const dealerOptions = dealerRoles?.map((dealer) => ({
    value: dealer.id,
    label: dealer.name,
  }));

  const areaManagerOptions = areaManagerRoles
    .filter((manager) => manager.id != null)
    ?.map((manager) => ({
      value: manager.id,
      label: manager.name,
    }));

  const salesPersonOptions = [
    ...salesRoles.map((salesPerson) => ({
      value: salesPerson.id,
      label: salesPerson.name,
    })),
    ...(salesRoles.length > 0
      ? [
          {
            value: "vacant",
            label: "Vacant",
          },
        ]
      : [
          {
            value: "vacant",
            label: "Vacant",
          },
        ]),
  ];

  useEffect(() => {
    if (localStorage.getItem("userDetails")) {
      const userDetails = JSON.parse(localStorage.getItem("userDetails"));
      const { roles } = userDetails;
      const { id } = userDetails;
      setUserRole(roles);
    }
  }, []);

  function formatDate(inputDate) {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const date = new Date(inputDate);
    const day = date.getUTCDate();
    const month = months[date.getUTCMonth()];
    const year = date.getUTCFullYear();

    return `${day}-${month}-${year}`;
  }

  const printHandler = async (values) => {
    const printElement = ReactDOMServer.renderToString(pdfJSX(values));
    // html2pdf().from(printElement).save();
    html2pdf()
      .from(printElement)
      .set({
        filename: `${values?.parsedReplacements?.tallyLedgerName} (${values?.vch_no}).pdf`, // Customize the filename
      })
      .save();
  };

  const generateInvoice = (event, splitId, mergeId, orderId, orderNo) => {
    setIsLoading(true);
    event.preventDefault();
    axiosPrivate
      .post("tally/fetch-invoice-by-orderid", {
        order_id: orderId,
        split_id: splitId,
        merge_id: mergeId,
      })
      .then(async (response) => {
        if (response.status == 200) {
          let finalData = response.data.data;

          await finalData?.forEach((item) => {
            let total_quantity = 0;

            // Extract quantities, convert to numbers, and sum them up
            total_quantity = item?.parsedReplacements?.products?.reduce(
              (acc, product) => {
                return acc + Number(product?.quantity || 0);
              },
              0
            );
            item.parsedReplacements.quantity = total_quantity;
          });

          for (let i = 0; i < finalData.length; i++) {
            await printHandler(finalData[i]);
          }

          setIsLoading(false);
        }
        if (response.status == 500) {
          setIsLoading(false);
          showErrorToast("Server Error");
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("Error", err);

        showErrorToast("Invoice not generated from tally");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const pdfJSX = (data) => {
    return (
      <>
        <div className="jumBotron invoice-container">
          <div className="header-invoice">
            <div>
              <div className="header-in d-flex align-items-center">
                <div style={{ width: "190px" }}>
                  <img src={nmLogo} alt="NM Logo" />
                </div>
                <div className="right-sec">
                  <h6 className="tax-heading">Tax Invoice</h6>
                  <h3 className="company_name">NM India Bio Private Limited</h3>
                  <div className="invoice-address">
                    Araji No. 4790, Jhamar Kotra Road, Umarda, Udaipur ,
                    Rajasthan - 313003, INDIA.
                  </div>
                  <div className="my-0 invoice-address ">
                    <span>9829355044</span>
                    <span className="mx-3">bhanuprakash@nmidiabio.com</span>
                  </div>
                  <div className="invoice-address">
                    <span>
                      GSTIN: <b>08AACCE7746A1ZT</b>
                    </span>
                    <span className="mx-3">
                      PAN: <b>AACCE7746A</b>
                    </span>
                  </div>
                </div>
              </div>
              <div>
                <div className="invoice-bill mt-3">
                  <div className="invoice-bill-content">
                    <div className="invoice-date">
                      <span>INVOICE</span>
                      <br></br>
                      <span>{data?.vch_no}</span>
                      <br></br>
                      <span>{formatDate(data?.parsedReplacements?.date)}</span>
                    </div>
                    <div className="invoice-person-detail">
                      <p style={{ marginBottom: "0" }}>Bill to</p>
                      <div className="tax-heading">
                        {data?.parsedReplacements?.tallyLedgerName}
                      </div>
                      <span>Dealer Code : </span>
                      <br></br>
                      <span>
                        {data?.parsedReplacements?.billing_address} ,{" "}
                        {data?.parsedReplacements?.billing_city} ,{" "}
                        {data?.parsedReplacements?.billing_state} ,{" "}
                        {data?.parsedReplacements?.billing_zip}
                      </span>
                      <br></br>
                      <span>
                        GSTIN : {data?.parsedReplacements?.gstin} (24){" "}
                        {data?.parsedReplacements?.billing_state}
                      </span>
                    </div>
                  </div>
                  <div
                    className="invoice-person-detail"
                    style={{ borderLeft: "1px solid black" }}
                  >
                    <p style={{ marginBottom: "0" }}>Ship to</p>
                    <div className="tax-heading">
                      {data?.parsedReplacements?.tallyLedgerName}
                    </div>
                    <span>Dealer Code : </span>
                    <br></br>
                    <span>
                      {data?.parsedReplacements?.shipping_address} ,{" "}
                      {data?.parsedReplacements?.shipping_city} ,{" "}
                      {data?.parsedReplacements?.shipping_state} ,{" "}
                      {data?.parsedReplacements?.shipping_zip}
                    </span>
                    <br></br>
                    <span>
                      GSTIN : {data?.parsedReplacements?.gstin} (24){" "}
                      {data?.parsedReplacements?.shipping_state}
                    </span>
                  </div>
                  <div className="invoice-bill-qrcode">
                    <img src={paymentQr} style={{ width: "100px" }} />
                  </div>
                </div>

                <table className="mid-line-table">
                  <tr>
                    <th className="first-th">IRN</th>
                    <th>{data?.parsedReplacements?.ewayBill?.irn}</th>
                    <th>{data?.parsedReplacements?.ewayBill?.irnAckNo}</th>
                    <th>@ {formatDate(data?.parsedReplacements?.date)}</th>
                  </tr>
                </table>

                <table>
                  <tr>
                    <th></th>
                    <th>Order</th>
                    <th>Dispatch Doc</th>
                    <th>Through</th>
                    <th>LR/RR/BL</th>
                    <th>Carrier</th>
                  </tr>

                  <tr>
                    <td>#</td>
                    <td>{data?.order_no}</td>
                    <td>{data?.vch_no}</td>
                    <td>{data?.parsedReplacements?.transporterName}</td>
                    <td>{data?.parsedReplacements?.products[0]?.lr_no}</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>@</td>
                    <td>{formatDate(data?.parsedReplacements?.date)}</td>
                    <td></td>
                    <td></td>
                    <td className="date">
                      {formatDate(data?.parsedReplacements?.date)}{" "}
                    </td>
                    <td>{data?.parsedReplacements?.carrierNumber}</td>
                  </tr>
                </table>

                <table className="main-table">
                  <thead>
                    <tr>
                      <th className="s_no">S.No</th>
                      <th className="product_name_td">Description of Goods</th>
                      <th className="hsn_td1">HSN</th>
                      <th>Quantity</th>
                      <th>Weight</th>
                      <th>Carton</th>
                      <th>Rate</th>
                      <th>Per</th>
                      <th>Disc. %</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.parsedReplacements?.products?.map(
                      (product, index) => (
                        <>
                          <tr className="border-0" key={index}>
                            <td>{index + 1}</td>
                            <td className="product_name_td">
                              {product.product_name}
                            </td>
                            <td>310100</td>
                            <td>{`${product?.quantity} ${product?.base_unit}`}</td>
                            <td>{`${product?.weight} ${product?.unit}`}</td>
                            <td>-</td>
                            <td>{`${product.rate}.00`}</td>
                            <td>{product.base_unit}</td>
                            <td>-</td>
                            <td>{`${product.item_amount}.00`}</td>
                          </tr>
                        </>
                      )
                    )}
                    <tr className="border-0" style={{ height: "30px" }}>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>
                        <b>-</b>
                      </td>
                    </tr>

                    <tr className="border-0">
                      <td></td>
                      <td
                        className="d-flex justify-content-between"
                        style={{ paddingRight: "5px" }}
                      >
                        <b>
                          <i>Less</i>
                        </b>
                        <b>
                          <i>Discount</i>
                        </b>
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>
                        <b>(-)00.00</b>
                      </td>
                    </tr>

                    {data?.parsedReplacements?.itemsTax?.cgst && (
                      <tr className="border-0">
                        <td></td>
                        <td
                          className="d-flex justify-content-between"
                          style={{ paddingRight: "5px" }}
                        >
                          <b></b>
                          <b>
                            <i>CGST</i>
                          </b>
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                          <b>{data?.parsedReplacements?.total_tax / 2}</b>
                        </td>
                      </tr>
                    )}
                    {data?.parsedReplacements?.itemsTax?.sgst && (
                      <tr className="border-0">
                        <td></td>
                        <td
                          className="d-flex justify-content-between"
                          style={{ paddingRight: "5px" }}
                        >
                          <b></b>
                          <b>
                            <i>SGST</i>
                          </b>
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                          <b>{data?.parsedReplacements?.total_tax / 2}</b>
                        </td>
                      </tr>
                    )}
                    {data?.parsedReplacements?.itemsTax?.igst && (
                      <tr className="border-0">
                        <td></td>
                        <td
                          className="d-flex justify-content-between"
                          style={{ paddingRight: "5px" }}
                        >
                          <b></b>
                          <b>
                            <i>IGST</i>
                          </b>
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                          <b>
                            {data?.parsedReplacements?.itemsTax?.igst_amount}
                          </b>
                        </td>
                      </tr>
                    )}

                    <tr style={{ height: "200px" }} className="border-0">
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>
                        <b>Total Amount</b>
                      </td>
                      <td></td>
                      <td>
                        <b>
                          {data?.parsedReplacements?.quantity}{" "}
                          {data?.parsedReplacements?.baseUnit}
                        </b>
                      </td>
                      <td>{data?.parsedReplacements?.total_weight}</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>
                        <b>Rs. {data?.parsedReplacements?.total}</b>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <table className="amount-chargeble">
                  <tbody>
                    <div
                      className="d-flex"
                      style={{
                        borderLeft: "1px solid #000",
                        borderRight: "1px solid #000",
                      }}
                    >
                      <div
                        style={{
                          borderRight: "1px solid #000",
                          width: "80%",
                          padding: "0px 5px",
                        }}
                      >
                        <div className="d-flex justify-content-between amount-words">
                          <p className="m-0 amount">
                            <b>Amount Chargeable (In words)</b>
                          </p>
                          <p className="mx-1 mb-0 eof text-right">
                            <b>E. & O.E</b>
                          </p>
                        </div>
                        <div className="mb-0 amount-eng">
                          <b>INR {data?.parsedReplacements?.totalInWords}</b>
                        </div>
                        <p></p>
                      </div>

                      <div style={{ padding: "0px 5px", margin: "auto" }}>
                        <div className="d-flex justify-content-between">
                          <b>Freight Payable :</b>
                          <b style={{ paddingLeft: "5px" }}>
                            {" "}
                            {
                              data?.parsedReplacements?.products[0]
                                ?.freight_amount
                            }
                            .00
                          </b>
                        </div>
                        <div className="d-flex justify-content-between">
                          <b>Due Amount :</b>
                          <b style={{ paddingLeft: "5px" }}> 00.00</b>
                        </div>
                      </div>
                    </div>
                  </tbody>
                </table>

                <table className="tax-amount">
                  <thead>
                    <tr>
                      <th style={{ textAlign: "center" }}>HSN/SAC</th>
                      <th style={{ width: "15%", textAlign: "center" }}>
                        Taxable Value
                      </th>
                      {data?.parsedReplacements?.itemsTax?.cgst && (
                        <th className="p-0" style={{ width: "15%" }}>
                          <div
                            className="text-center"
                            style={{ borderBottom: "1px solid #000" }}
                          >
                            <b style={{ fontWeight: "bold" }}>Central Tax</b>
                          </div>
                          <div className="d-flex justify-content-around">
                            <b
                              style={{
                                fontWeight: "bold",
                                padding: "0px 5px",
                                width: "53px",
                              }}
                            >
                              Rate
                            </b>
                            <b style={{ borderRight: "1px solid #000" }}></b>
                            <b
                              style={{
                                fontWeight: "bold",
                                padding: "0px 5px",
                              }}
                            >
                              Amount
                            </b>
                          </div>
                        </th>
                      )}

                      {data?.parsedReplacements?.itemsTax?.sgst && (
                        <th className="p-0" style={{ width: "15%" }}>
                          <div
                            className="text-center"
                            style={{ borderBottom: "1px solid #000" }}
                          >
                            <b style={{ fontWeight: "bold" }}>State Tax</b>
                          </div>
                          <div className="d-flex justify-content-around">
                            <b
                              style={{
                                fontWeight: "bold",
                                padding: "0px 5px",
                                width: "53px",
                              }}
                            >
                              Rate
                            </b>
                            <b style={{ borderRight: "1px solid #000" }}></b>
                            <b
                              style={{
                                fontWeight: "bold",
                                padding: "0px 5px",
                              }}
                            >
                              Amount
                            </b>
                          </div>
                        </th>
                      )}

                      {data?.parsedReplacements?.itemsTax?.igst && (
                        <th className="p-0" style={{ width: "15%" }}>
                          <div
                            className="text-center"
                            style={{ borderBottom: "1px solid #000" }}
                          >
                            <b style={{ fontWeight: "bold" }}>Integrated Tax</b>
                          </div>
                          <div className="d-flex justify-content-around">
                            <b
                              style={{
                                fontWeight: "bold",
                                padding: "0px 5px",
                                width: "53px",
                              }}
                            >
                              Rate
                            </b>
                            <b style={{ borderRight: "1px solid #000" }}></b>
                            <b
                              style={{
                                fontWeight: "bold",
                                padding: "0px 5px",
                              }}
                            >
                              Amount
                            </b>
                          </div>
                        </th>
                      )}
                      <th style={{ width: "15%", textAlign: "center" }}>
                        Total Tax Amount
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={{ textAlign: "center" }}>310100</td>
                      <td style={{ width: "15%", textAlign: "center" }}>
                        {data?.parsedReplacements?.total -
                          data?.parsedReplacements?.total_tax}
                      </td>

                      {data?.parsedReplacements?.itemsTax?.cgst && (
                        <>
                          <td className="p-0" style={{ width: "15%" }}>
                            <div className="d-flex justify-content-around">
                              <div
                                style={{
                                  fontWeight: "none",
                                  padding: "0px 5px",
                                }}
                              >
                                {data?.parsedReplacements?.itemsTax?.cgst}%
                              </div>
                              <div
                                style={{ borderRight: "1px solid #000" }}
                              ></div>
                              <div style={{ padding: "0px 5px" }}>
                                {data?.parsedReplacements?.total_tax / 2}
                              </div>
                            </div>
                          </td>
                        </>
                      )}

                      {data?.parsedReplacements?.itemsTax?.sgst && (
                        <>
                          <td className="p-0" style={{ width: "15%" }}>
                            <div className="d-flex justify-content-around">
                              <div
                                style={{
                                  fontWeight: "none",
                                  padding: "0px 5px",
                                }}
                              >
                                {data?.parsedReplacements?.itemsTax?.sgst}%
                              </div>
                              <div
                                style={{ borderRight: "1px solid #000" }}
                              ></div>
                              <div style={{ padding: "0px 5px" }}>
                                {data?.parsedReplacements?.total_tax / 2}
                              </div>
                            </div>
                          </td>
                        </>
                      )}

                      {data?.parsedReplacements?.itemsTax?.igst && (
                        <>
                          <td className="p-0" style={{ width: "15%" }}>
                            <div className="d-flex justify-content-around">
                              <div
                                style={{
                                  fontWeight: "none",
                                  padding: "0px 5px",
                                }}
                              >
                                {data?.parsedReplacements?.itemsTax?.igst}%
                              </div>
                              <div
                                style={{ borderRight: "1px solid #000" }}
                              ></div>
                              <div style={{ padding: "0px 5px" }}>
                                {
                                  data?.parsedReplacements?.itemsTax
                                    ?.igst_amount
                                }
                              </div>
                            </div>
                          </td>
                        </>
                      )}

                      <td style={{ width: "15%", textAlign: "center" }}>
                        {data?.parsedReplacements?.total_tax}
                      </td>
                    </tr>
                  </tbody>
                </table>

                <div className="pt-2 pl-1 bank_details d-flex justify-content-between border border-dark">
                  <div className="d-flex flex-column justify-content-between w-50 pr-4">
                    <span>
                      Tax Amount (in Words) :<br />
                      <b>INR {data?.parsedReplacements?.totalTaxInWords}</b>
                      {/* <!-- Enter amount in words  --> */}
                    </span>
                    <div>
                      <p className="mb-0 pt-2">
                        <p
                          className="mb-0"
                          style={{ textDecoration: "underline" }}
                        >
                          Declaration :
                        </p>
                        We Declare that this invoice shows the real price of the
                        goods described and that all particular true and
                        correct.
                      </p>
                    </div>
                  </div>
                  <div className="company_div w-50">
                    <div className="mt-0">
                      <p className="mb-0">Company's Bank Details</p>
                      <p className="mb-0">
                        Bank Name : <b>Bank of Baroda (India)</b>
                      </p>
                      <p className="mb-0">
                        A/c No. : <b>01370500000868</b>
                      </p>
                      <p className="mb-0">
                        Branch & IFSC Code :{" "}
                        <b>Udaipur Main Branch & BARB0UDAIPU</b>
                      </p>
                    </div>
                    <div
                      className="invoice-bill-qrcode qr-code border border-dark w-100 h-100 p-1"
                      style={{ borderBottom: "0px !important" }}
                    >
                      <div>
                        <div className="text-left">
                          <b>Scan to Pay</b>
                        </div>
                        <div className="mt-1">
                          <img src={paymentQr} style={{ width: "100px" }} />
                        </div>
                      </div>
                      <div className="d-flex flex-column justify-content-between mt-3">
                        <p style={{ fontWeight: "600" }}>
                          For{" "}
                          <span style={{ fontWeight: "bold" }}>
                            NM India Bio Private Limited
                          </span>{" "}
                        </p>
                        <p className="mb-0">Authorized Signatory</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="border border-dark text-center p-2 border-top-0">
                  This is a computer generated Invoice
                </div>

                {data?.parsedReplacements?.ewayBill && (
                  <>
                    {/* Add this div to introduce a page break */}
                    <div className="pageBreak"></div>
                    <div className=" p-0 mt-4">
                      <div className="header bg-none">
                        <h6>e-Way Bill</h6>
                      </div>

                      <div className="invoiceSectionBorderBottom">
                        <div>
                          <p>
                            Doc No :{" "}
                            <span className="boldText">
                              {" "}
                              {data?.parsedReplacements?.ewayBill?.docNo}
                            </span>
                          </p>
                          <p>
                            Date :
                            <span className="boldText">
                              {" "}
                              {moment(
                                data?.parsedReplacements?.ewayBill?.billDate,
                                "YYYYMMDD"
                              ).format("DD-MMM-YYYY")}
                            </span>
                          </p>
                          <p>
                            IRN :
                            <span className="boldText">
                              {" "}
                              {data?.parsedReplacements?.ewayBill?.irn}
                            </span>
                          </p>
                          <p>
                            Ack No :
                            <span className="boldText">
                              {" "}
                              {data?.parsedReplacements?.ewayBill?.irnAckNo}
                            </span>
                          </p>
                          <p>
                            Ack Date :
                            <span className="boldText">
                              {" "}
                              {moment(
                                data?.parsedReplacements?.ewayBill?.irnAckDate,
                                "YYYYMMDD"
                              ).format("DD-MMM-YYYY")}
                            </span>
                          </p>
                        </div>
                        <div>
                          {" "}
                          <QRCode
                            title="GeeksForGeeks"
                            value={data?.parsedReplacements?.ewayBill?.qrCode}
                            bgColor={"#ffffff"}
                            fgColor={"#000000"}
                            size={100}
                          />
                        </div>
                      </div>

                      {/* <h3>1. e-Way Bill Details</h3> */}
                      <div className="boldText p-1">1. e-Way Bill Details</div>
                      <div className="invoiceSectionBorderBottom">
                        <div>
                          <p>
                            e-Way Bill No :
                            <span className="boldText">
                              {" "}
                              {data?.parsedReplacements?.ewayBill?.billNumber}
                            </span>
                          </p>
                          <p>
                            Generated By :
                            <span className="boldText"> 08AACCE7746A1ZT</span>
                          </p>
                          <p>
                            Supply Type :
                            <span className="boldText"> Outward</span>
                          </p>
                        </div>
                        <div>
                          <p>
                            Mode :<span className="boldText"> 1-Road</span>
                          </p>
                          <p>
                            Approx Distance :
                            <span className="boldText">
                              {
                                data?.parsedReplacements?.ewayBill
                                  ?.approxDistance
                              }
                            </span>
                          </p>
                          <p>
                            Transition Type :
                            <span className="boldText"> Bill To - Ship To</span>
                          </p>
                        </div>
                        <div>
                          <p>
                            Generated Date :
                            <span className="boldText">
                              {moment(
                                data?.parsedReplacements?.ewayBill
                                  ?.generatedDate,
                                "YYYYMMDD"
                              ).format("DD-MMM-YYYY")}
                            </span>
                          </p>
                          <p>
                            Valid Upto :
                            <span className="boldText">
                              {" "}
                              {moment(
                                data?.parsedReplacements?.ewayBill?.validUpto,
                                "YYYYMMDD"
                              ).format("DD-MMM-YYYY")}
                            </span>
                          </p>
                        </div>
                      </div>

                      {/* <h3>2. Address Details</h3> */}
                      <div className="boldText p-1">2. Address Details</div>
                      <div className="rows">
                        <div className="column1">
                          <div className="p-1">
                            <p>
                              <span className="boldText">From </span>
                            </p>
                            <p>NM India Bio Private Limited</p>
                            <p>GSTIN: 08AACCE7746A1ZT</p>
                            <p>
                              {
                                data?.parsedReplacements?.ewayBill
                                  ?.shipFromAddress
                              }
                            </p>
                          </div>
                          <div className="p-1">
                            <p>
                              <span className="boldText">Dispatch From</span>
                            </p>
                            <p>
                              {
                                data?.parsedReplacements?.ewayBill
                                  ?.consignorAddress
                              }
                              {", "}
                              {
                                data?.parsedReplacements?.ewayBill
                                  ?.consignorCity
                              }{" "}
                              {
                                data?.parsedReplacements?.ewayBill
                                  ?.shipFromAddress
                              }{" "}
                              {data?.parsedReplacements?.billing_zip}
                            </p>
                          </div>
                        </div>
                        <div className="column2">
                          <div>
                            <p>
                              <span className="boldText">To</span>
                            </p>
                            <p>{data?.parsedReplacements?.tallyLedgerName}</p>
                            <p>GSTIN: {data?.parsedReplacements?.gstin}</p>
                            <p>
                              {
                                data?.parsedReplacements?.ewayBill
                                  ?.shipToAddress
                              }
                            </p>
                          </div>
                          <div>
                            <p>
                              <span className="boldText">Ship To</span>
                            </p>
                            <p>
                              {
                                data?.parsedReplacements?.ewayBill
                                  ?.consigneeAddress
                              }
                              {", "}
                              {
                                data?.parsedReplacements?.ewayBill
                                  ?.consigneeCity
                              }{" "}
                              {
                                data?.parsedReplacements?.ewayBill
                                  ?.shipToAddress
                              }{" "}
                              {data?.parsedReplacements?.shipping_zip}
                            </p>
                          </div>
                        </div>
                      </div>

                      {/* <h3
                      style={{
                        borderBottom: "1px solid black",
                        paddingBottom: "10px",
                      }}
                    >
                      3. Goods Details
                    </h3> */}
                      <div
                        className="boldText p-1"
                        style={{
                          borderBottom: "1px solid black",
                          paddingBottom: "10px",
                        }}
                      >
                        3. Goods Details
                      </div>
                      <table className="goodsTable">
                        <thead>
                          <tr>
                            <th className="border-right-0">HSN Code</th>
                            <th className="border-right-0">
                              Product Name & Desc
                            </th>
                            <th className="border-right-0">Quantity</th>
                            <th className="border-right-0">Taxable Amt</th>
                            <th className="border-right-0">Tax Rate(C + S)</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data?.parsedReplacements?.products?.map(
                            (product) => (
                              <tr>
                                <td>
                                  <span className="boldText">310100</span>
                                </td>
                                <td>{product?.product_name}</td>
                                <td>
                                  {product?.quantity} {product?.base_unit}
                                </td>
                                <td>
                                  <span className="boldText">
                                    {product?.item_amount}
                                  </span>
                                </td>
                                <td>
                                  {data?.parsedReplacements?.itemsTax?.cgst}
                                  {" + "}
                                  {data?.parsedReplacements?.itemsTax?.sgst}
                                </td>
                              </tr>
                            )
                          )}

                          <tr>
                            <td></td>
                          </tr>
                          <tr>
                            <td></td>
                          </tr>
                        </tbody>
                      </table>
                      <div className="invoiceSectionBorderBottom">
                        <div>
                          <p>
                            Tot. Taxable Amt :
                            <span className="boldText">
                              {" "}
                              {data?.parsedReplacements?.total -
                                data?.parsedReplacements?.total_tax}
                            </span>
                          </p>
                          <p>
                            CGST Amt :
                            <span className="boldText">
                              {" "}
                              {data?.parsedReplacements?.itemsTax?.cgst_amount}
                            </span>
                          </p>
                        </div>
                        <div>
                          <p>
                            Other Amt :<span className="boldText"></span>
                          </p>
                          <p>
                            SGST Amt :
                            <span className="boldText">
                              {" "}
                              {data?.parsedReplacements?.itemsTax?.sgst_amount}
                            </span>
                          </p>
                        </div>
                        <div>
                          <p>
                            Total Inv Amt :
                            <span className="boldText">
                              {data?.parsedReplacements?.total}
                            </span>
                          </p>
                        </div>
                      </div>

                      {/* <h3>4. Transportation Details</h3> */}
                      <div className="boldText p-1">
                        4. Transportation Details
                      </div>
                      <div className="invoiceSectionBorderBottom">
                        <div>
                          <p>
                            Transporter ID :<span className="boldText"></span>
                          </p>
                          <p>
                            Name :
                            <span className="boldText">
                              {" "}
                              {
                                data?.parsedReplacements?.ewayBill
                                  ?.transporterName
                              }
                            </span>
                          </p>
                        </div>
                        <div>
                          <p>
                            Doc No :
                            <span className="boldText">
                              {" "}
                              {data?.parsedReplacements?.ewayBill?.docNo}
                            </span>
                          </p>
                          <p>
                            Date :
                            <span className="boldText">
                              {" "}
                              {moment(
                                data?.parsedReplacements?.ewayBill?.billDate,
                                "YYYYMMDD"
                              ).format("DD-MMM-YYYY")}
                            </span>
                          </p>
                        </div>
                      </div>
                      {/* <h3>4. Vehicle Details</h3> */}
                      <div className="boldText p-1">4. Vehicle Details</div>
                      <div className="invoiceSectionBorderBottom border-bottom-0">
                        <p>
                          Vehicle No :
                          <span className="boldText">
                            {" "}
                            {data?.parsedReplacements?.ewayBill?.vehicleNumber}
                          </span>
                        </p>
                        <p>
                          From :
                          <span className="boldText">
                            {data?.parsedReplacements?.ewayBill?.consignorCity}
                          </span>
                        </p>
                        <p>
                          CEBW No. :<span className="boldText"></span>
                        </p>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <div>
      <div className="section-body">
        <div className="container-fluid">
          {/* hearder breadcrumb */}
          <div className="card-header">
            <strong className="top-left-heading">Edit Order</strong>

            <ol className="headerroutes breadcrumb m-0 bg-none ">
              <li className="item color-gray">|</li>
              <li className="item">
                <Link to="/admin/dashboard" className="route-heading item mx-2">
                  Dashboard
                </Link>
              </li>
              <li className="item">
                <FaGreaterThan className="grater-than-route" />
              </li>
              <li className="route-heading item mx-2 mt-1">
                <Link to="/admin/order">Order</Link>
              </li>
              <li className="item">
                <FaGreaterThan className="grater-than-route" />
              </li>

              <li className="route-heading item mx-2 mt-1">Edit Order</li>
            </ol>
          </div>

          <div className="tab-content ">
            <form onSubmit={formik.handleSubmit}>
              <div
                className="tab-pane fade show active"
                id="user-add"
                role="tabpanel"
              >
                <div className="card">
                  {/* order number */}
                  <div className="row mx-3">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <div className="form-group">
                        <label>
                          Order Number :<span className="required">*</span>
                        </label>
                        <input
                          id="order_no"
                          type="number"
                          className="form-control"
                          name="order_no"
                          value={formik.values.order_no}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mx-3">
                    {dearlerDropDown ? (
                      <>
                        <div className="col-lg-4 col-md-4 col-sm-12">
                          <div className="form-group">
                            <label>
                              Dealer :<span className="required">*</span>
                            </label>

                            <ReactSelect
                              id="dealer_id"
                              styles={customStyles}
                              name="dealer_id"
                              options={dealerOptions}
                              isDisabled={freezed}
                              value={dealerOptions.find(
                                (option) =>
                                  option.value === formik.values.dealer_id
                              )}
                              onChange={(selectedOption) => {
                                setDealerIdHandler(selectedOption?.value || "");
                              }}
                              onBlur={formik.handleBlur}
                              placeholder="Select Dealer"
                              isSearchable
                            />
                            {formik.touched.dealer_id &&
                            formik.errors.dealer_id ? (
                              <div className={classes.invalidDataError}>
                                {formik.errors.dealer_id}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    {/* dealer select */}

                    {areamanagerDropDown ? (
                      <>
                        {/* Area Manager Select */}
                        <div className="col-lg-4 col-md-4 col-sm-12">
                          <div className="form-group">
                            <label>
                              Area Manager:<span className="required">*</span>
                            </label>

                            <ReactSelect
                              id="areaManager_id"
                              name="areamanager_id"
                              styles={customStyles}
                              options={areaManagerOptions}
                              value={areaManagerOptions.find(
                                (option) =>
                                  option.value === formik.values.areamanager_id
                              )}
                              isDisabled={freezed}
                              onChange={(selectedOption) => {
                                // Update Formik value
                                formik.setFieldValue(
                                  "areamanager_id",
                                  selectedOption?.value || ""
                                );
                                getSalesPersonDetails(selectedOption?.value);
                              }}
                              onBlur={formik.handleBlur}
                              placeholder="Select Area Manager"
                              isSearchable
                            />
                            {formik.touched.areamanager_id &&
                            formik.errors.areamanager_id ? (
                              <div className={classes.invalidDataError}>
                                {formik.errors.areamanager_id}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    {/* sales person dropdown */}

                    {salesmanDropDown ? (
                      <>
                        <div className="col-lg-4 col-md-4 col-sm-12">
                          <div className="form-group">
                            <label>
                              Sales Person:<span className="required">*</span>
                            </label>

                            <ReactSelect
                              id="salesman_id"
                              name="salesman_id"
                              styles={customStyles}
                              options={salesPersonOptions}
                              value={salesPersonOptions.find(
                                (option) =>
                                  option.value === formik.values.salesman_id
                              )}
                              isDisabled={freezed}
                              onChange={(selectedOption) => {
                                formik.setFieldValue(
                                  "salesman_id",
                                  selectedOption?.value || ""
                                );
                              }}
                              onBlur={formik.handleBlur}
                              placeholder="Select Sales Person"
                              isSearchable
                            />
                            {formik.touched.salesman_id &&
                            formik.errors.salesman_id ? (
                              <div className={classes.invalidDataError}>
                                {formik.errors.salesman_id}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                  {/* table */}
                  <div
                    className="row mx-4 p-2"
                    style={{ border: "1px solid black" }}
                  >
                    {formik.values.order_rows?.map((item, index) => (
                      <React.Fragment key={index}>
                        {/* Product Dropdown */}
                        <div className="col-lg-4 col-md-4 col-sm-12">
                          <div className="form-group">
                            <label>
                              Product:<span className="required">*</span>
                            </label>
                            <select
                              id={`order_rows[${index}].stock_id`}
                              className="form-control"
                              name={`order_rows[${index}].stock_id`}
                              value={item.stock_id}
                              onChange={(event) => {
                                const selectedValue = event.target.value;

                                // Find the selected product object
                                const selectedProduct = products.find(
                                  (product) => product.id === selectedValue
                                );
                                console.log(
                                  "Selected Product",
                                  selectedProduct
                                );

                                // Set the selected stock_id and price in Formik's state
                                formik.setFieldValue(
                                  `order_rows[${index}].stock_id`,
                                  selectedValue
                                );
                                formik.setFieldValue(
                                  `order_rows[${index}].price`,
                                  selectedProduct?.rate || "" // Set price or fallback to empty string if not found
                                );
                              }}
                              onBlur={formik.handleBlur}
                            >
                              <option value="">Select Product</option>
                              {products.map((role) => (
                                <option key={role.id} value={role.id}>
                                  {role.tally_name}
                                </option>
                              ))}
                            </select>
                            {formik.touched.order_rows?.[index]?.stock_id &&
                              formik.errors.order_rows?.[index]?.stock_id && (
                                <div className={classes.invalidDataError}>
                                  {formik.errors.order_rows[index].stock_id}
                                </div>
                              )}
                          </div>
                        </div>

                        {/* Type */}
                        <div className="col-lg-4 col-md-4 col-sm-12">
                          <div className="form-group">
                            <label>
                              Type:<span className="required">*</span>
                            </label>
                            <span
                              className="form-control"
                              style={{ backgroundColor: "#e9ecef" }}
                            >
                              {item?.quantity ? "Ordered" : "Dispatched"}
                            </span>
                          </div>
                        </div>
                        {/* Quantity */}
                        <div className="col-lg-4 col-md-4 col-sm-12">
                          <div className="form-group">
                            <label>
                              Quantity:<span className="required">*</span>
                            </label>
                            <input
                              id={`order_rows[${index}].qty`}
                              type="number"
                              placeholder="Enter Quantity"
                              disabled={freezed}
                              className="form-control"
                              name={`order_rows[${index}].qty`}
                              value={item.qty}
                              onChange={(event) =>
                                formik.setFieldValue(
                                  `order_rows[${index}].qty`,
                                  event.target.value
                                )
                              }
                              onBlur={formik.handleBlur}
                            />
                            {formik.touched.order_rows?.[index]?.qty &&
                              formik.errors.order_rows?.[index]?.qty && (
                                <div className={classes.invalidDataError}>
                                  {formik.errors.order_rows[index].qty}
                                </div>
                              )}
                          </div>
                        </div>

                        {/* Price */}
                        <div className="col-lg-4 col-md-4 col-sm-12">
                          <div className="form-group">
                            <label>
                              Price:<span className="required">*</span>
                            </label>
                            <input
                              id={`order_rows[${index}].price`}
                              type="number"
                              placeholder="Enter Price"
                              disabled={freezed}
                              className="form-control"
                              name={`order_rows[${index}].price`}
                              value={item.price}
                              onChange={(event) =>
                                formik.setFieldValue(
                                  `order_rows[${index}].price`,
                                  event.target.value
                                )
                              }
                            />
                            {formik.touched.order_rows?.[index]?.price &&
                              formik.errors.order_rows?.[index]?.price && (
                                <div className={classes.invalidDataError}>
                                  {formik.errors.order_rows[index].price}
                                </div>
                              )}
                          </div>
                        </div>

                        {/* Shipping Address */}
                        <div className="col-lg-4 col-md-4 col-sm-12">
                          <div className="form-group">
                            <label>
                              Shipping Address:
                              <span className="required">*</span>
                            </label>
                            <Creatable
                              value={shippingAddress.find(
                                (address) =>
                                  address.value === item.shipping_address_id
                              )}
                              styles={customStyles}
                              isDisabled={freezed}
                              onChange={(selectedOption) => {
                                handleShippingAddressChange(
                                  selectedOption,
                                  index
                                );
                              }}
                              options={shippingAddress}
                              onBlur={formik.handleBlur}
                              placeholder="Select or create a shipping address"
                            />
                            {formik.touched.order_rows?.[index]
                              ?.shipping_address_id &&
                              formik.errors.order_rows?.[index]
                                ?.shipping_address_id && (
                                <div className={classes.invalidDataError}>
                                  {
                                    formik.errors.order_rows[index]
                                      .shipping_address_id
                                  }
                                </div>
                              )}
                          </div>
                        </div>

                        {freezed && item?.invoice_generated == 1 && (userRole == "admin" || userRole == "dealer") && (
                              <div className="col-lg-4 col-md-4 col-sm-12">
                              <div className="form-group">
                                <label>
                                  <span className="required">&nbsp;</span>
                                </label>
                                <div>
                                <button
                                  onClick={(event) => {
                                    generateInvoice(
                                      event,
                                      item?.split_id ? item.split_id : null,
                                      item?.merge_id ? item.merge_id : null,
                                      item?.order_id,
                                      item?.order_no
                                    );
                                  }}
                                  // className="btn btn-primary btn-sm ml-1 generate-invoice"
                                  className="btn btn-default btn-sm ml-1 download-invoice"
                                >
                                  {/* Generate Invoice */}
                                  Download Invoice
                                </button>
                                </div>
                                </div>
                              </div>
                            )}

                        {/* Dynamic Additional Inputs */}
                        {formik.values.order_rows[index]
                          ?.shipping_address_id === 0 && (
                          <>
                            <div className="col-lg-4 col-md-4 col-sm-12">
                              <div className="form-group">
                                <label>
                                  State:<span className="required"></span>
                                </label>
                                <select
                                  id={`order_rows[${index}].shipping_state`}
                                  className="form-control"
                                  name={`order_rows[${index}].shipping_state`}
                                  value={item.shipping_state}
                                  onChange={(event) => {
                                    setSelectedCity({
                                      name: event.target.value,
                                      index: index,
                                    });
                                    formik.setFieldValue(
                                      `order_rows[${index}].shipping_state`,
                                      event.target.value
                                    );
                                  }}
                                  onBlur={formik.handleBlur}
                                >
                                  <option value="" disabled selected>
                                    Select State
                                  </option>
                                  {shippingStates?.map((state, key) => (
                                    <option value={state}>{state}</option>
                                  ))}
                                </select>
                                {formik.touched.order_rows?.[index]
                                  ?.shipping_state &&
                                  formik.errors.order_rows?.[index]
                                    ?.shipping_state && (
                                    <div className={classes.invalidDataError}>
                                      {
                                        formik.errors.order_rows[index]
                                          .shipping_state
                                      }
                                    </div>
                                  )}
                              </div>
                            </div>
                            
                            <div className="col-lg-4 col-md-4 col-sm-12">
                              <div className="form-group">
                                <label>
                                  City:<span className="required"></span>
                                </label>
                                <select
                                  id={`order_rows[${index}].shipping_city`}
                                  className="form-control"
                                  name={`order_rows[${index}].shipping_city`}
                                  value={item.shipping_city}
                                  onChange={(event) =>
                                    formik.setFieldValue(
                                      `order_rows[${index}].shipping_city`,
                                      event.target.value
                                    )
                                  }
                                  onBlur={formik.handleBlur}
                                >
                                  <option value="" disabled selected>
                                    Select City
                                  </option>
                                  {cityList[index] &&
                                    cityList[index].length > 0 &&
                                    cityList[index]?.map((city, key) => (
                                      <option
                                        key={key + "city1" + city.name}
                                        value={city.id}
                                      >
                                        {city.city}
                                      </option>
                                    ))}
                                </select>
                                {formik.touched.order_rows?.[index]
                                  ?.shipping_city &&
                                  formik.errors.order_rows?.[index]
                                    ?.shipping_city && (
                                    <div className={classes.invalidDataError}>
                                      {
                                        formik.errors.order_rows[index]
                                          .shipping_city
                                      }
                                    </div>
                                  )}
                              </div>
                            </div>

                            <div className="col-lg-4 col-md-4 col-sm-12">
                              <div className="form-group">
                                <label>
                                  Zip Code:<span className="required"></span>
                                </label>
                                <input
                                  id={`order_rows[${index}].shipping_zip`}
                                  name={`order_rows[${index}].shipping_zip`}
                                  type="text"
                                  minLength={6}
                                  maxLength={6}
                                  className="form-control"
                                  placeholder="Shipping Zip"
                                  onChange={(event) => {
                                    numberFilter(event);
                                  }}
                                  value={item.shipping_zip}
                                />
                                {formik.touched.order_rows?.[index]
                                  ?.shipping_zip &&
                                  formik.errors.order_rows?.[index]
                                    ?.shipping_zip && (
                                    <div className={classes.invalidDataError}>
                                      {
                                        formik.errors.order_rows[index]
                                          .shipping_zip
                                      }
                                    </div>
                                  )}
                              </div>
                            </div>
                          </>
                        )}

                        {/* Action Buttons */}
                        {!freezed && (
                          <div className="col-lg-4 col-md-4 col-sm-12">
                            <div className="form-group d-flex align-items-center mt-4">
                              <button
                                type="button"
                                className={`btn ${classes.addBtn}`}
                                onClick={() =>
                                  formik.setFieldValue("order_rows", [
                                    ...formik.values.order_rows,
                                    {
                                      stock_id: "",
                                      price: "",
                                      qty: "",
                                      shipping_address_id:
                                        defaultBillingAddress,
                                    },
                                  ])
                                }
                              >
                                <GrAddCircle />
                              </button>
                              {formik.values.order_rows.length > 1 && (
                                <button
                                  type="button"
                                  className={`btn ${classes.removeBtn} ml-2`}
                                  onClick={() =>
                                    formik.setFieldValue(
                                      "order_rows",
                                      formik.values.order_rows.filter(
                                        (_, i) => i !== index
                                      )
                                    )
                                  }
                                >
                                  <GrSubtractCircle />
                                </button>
                              )}
                            </div>
                          </div>
                        ) }
                        {/* <div className="col-lg-4 col-md-4 col-sm-12"></div> */}
                      </React.Fragment>
                    ))}
                  </div>
                  {/* discount field */}
                  {/* <div className="col-lg-9 col-md-9 col-sm-12 offset-lg-3 offset-md-3"> */}
                  <div
                    className="row"
                    style={{ width: " 97%", margin: "auto" }}
                  >
                    {/* total Amount */}
                    <div className="col-lg-6 col-md-4 col-sm-12">
                      <label>
                        Total Order Amount:<span className="required"></span>
                      </label>

                      <div className="form-group">
                        <input
                          id="total_amount"
                          type="number"
                          className="form-control"
                          name="total_amount"
                          // value={calculateTotalAmount()}
                          value={formik.values.totalAmount}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-4 col-sm-12">
                      <label>
                        Total Dispatch Amount:<span className="required"></span>
                      </label>

                      <div className="form-group">
                        {}
                        <input
                          id="dispatchAmount"
                          type="number"
                          className="form-control"
                          name="dispatchAmount"
                          // value={calculateTotalAmount()}
                          value={amount}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                  {/* SeasonDrowpDown */}
                  {seasonDropDown ? (
                    <div className="col-lg-12 col-md-12 col-sm-12 px-4 ">
                      <div className="form-group">
                        <label>Season</label>
                        <select
                          id="season_id"
                          className="form-control "
                          name="season_id"
                          onChange={(event) => {
                            let discountPercentage = 0;
                            seasonRoles?.map((seasons) => {
                              if (
                                seasons.id === event.target.value &&
                                seasons.discount_percentage != ""
                              ) {
                                return (discountPercentage =
                                  seasons.discount_percentage);
                              }
                            });
                            formik.handleChange(event);
                            formik.setFieldValue(
                              "discount",
                              discountPercentage
                            );
                            setDiscount(discountPercentage);
                          }}
                          onBlur={formik.handleBlur}
                          value={formik.values.season_id}
                        >
                          <option value="">Select Area Manager</option>
                          {seasonRoles?.map((role) => (
                            <option key={role.name} value={role.id}>
                              {role.season_title}
                            </option>
                          ))}
                        </select>
                        {formik.touched.season_id && formik.errors.season_id ? (
                          <div className={classes.invalidDataError}>
                            {formik.errors.season_id}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <div
                    className="row"
                    style={{ width: " 97%", margin: "auto" }}
                  >
                    {logicticDropDown ? (
                      <div className="col-lg-6 col-md-6 col-sm-6 ">
                        <div className="form-group">
                          <label>
                            Order Assign To:<span className="required">*</span>
                          </label>
                          <select
                            id="logistic_user_id"
                            className="form-control "
                            name="logistic_user_id"
                            disabled={freezed}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.logistic_user_id}
                          >
                            <option value="" disabled>
                              Select Logistic Manager
                            </option>
                            {logicticRoles?.map((role) =>
                              role.id != null ? (
                                <option key={role.name} value={role.id}>
                                  {role.name}
                                </option>
                              ) : (
                                ""
                              )
                            )}
                          </select>
                          {formik.touched.logistic_user_id &&
                          formik.errors.logistic_user_id ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.logistic_user_id}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    {getData.order_status !== "to_logistics" &&
                    getData.order_status !== "confirmed" ? (
                      <div className="col-lg-6 col-md-6 col-sm-6">
                        <div className="form-group">
                          <label>Order Status :</label>
                          <select
                            id="order_status"
                            className="form-control"
                            name="order_status"
                            disabled={freezed}
                            value={formik.order_status}
                            onChange={(event) => {
                              formik.handleChange(event);
                            }}
                            onBlur={formik.handleBlur}
                          >
                            <option value="">Select Status</option>
                            <option key="pendingStatus##1" value="pending">
                              Draft
                            </option>
                            <option
                              key="tologisticStatus##1"
                              value="to_logistics"
                            >
                              Send to logistic
                            </option>
                          </select>
                          {formik.touched.order_status &&
                          formik.errors.order_status ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.order_status}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <label>
                        Image:<span className="required">*</span>
                      </label>

                      <div className="form-group align-items-center">
                        <input
                          id="image"
                          name="image"
                          type="file"
                          className="form-control"
                          placeholder="Images"
                          onChange={(e) =>
                            formik.setFieldValue(
                              "image",
                              e.currentTarget.files[0]
                            )
                          }
                          onBlur={formik.handleBlur}
                          disabled={freezed}
                        />
                        {formik.touched.image && formik.errors.image ? (
                          <div className={classes.invalidDataError}>
                            {formik.errors.image}
                          </div>
                        ) : null}
                        {typeof formik.values.image == "string" ? (
                          <a
                            href={`${process.env.REACT_APP_COMMON_FILE_URL}orders/${formik.values.image}`}
                            target="_blank"
                          >
                            <img
                              className="mt-2"
                              src={
                                process.env.REACT_APP_COMMON_FILE_URL +
                                "orders/" +
                                formik.values.image
                              }
                              style={{ width: "80px" }}
                            />
                          </a>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-6 ">
                      <div className="form-group">
                        <label>Remarks :</label>
                        <textarea
                          id="user_note"
                          className="form-control"
                          rows="4"
                          disabled={freezed}
                          name="user_note"
                          placeholder="Please Enter Remarks"
                          value={formik.values.remark}
                          onChange={(event) => {
                            formik.handleChange(event);
                          }}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.user_note && formik.errors.user_note ? (
                          <div className={classes.invalidDataError}>
                            {formik.errors.user_note}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    {userData?.role == "3" && (
                      <div className="col-lg-6 col-md-6 col-sm-6 ">
                        <div className="form-group">
                          <label>Area Manger Remark :</label>
                          <textarea
                            id="area_manager_remark"
                            className="form-control"
                            rows="4"
                            disabled={freezed}
                            name="area_manager_remark"
                            placeholder="Please Enter Remark"
                            value={formik.values.area_manager_remark}
                            onChange={(event) => {
                              formik.handleChange(event);
                            }}
                            onBlur={formik.handleBlur}
                          />
                        </div>
                      </div>
                    )}

                    <div className="col-12 submit-btn my-2">
                      <hr className="mt-4" />
                      <div className="table-responsive">
                        <table className="table table-striped"></table>
                      </div>
                      <button
                        type="button"
                        className="btn btn-secondary"
                        data-dismiss="modal"
                        onClick={() => navigate("/admin/order")}
                      >
                        Close
                      </button>
                      {!freezed ? (
                        <button type="submit" className="btn btn-primary ml-2">
                          Submit
                        </button>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditOrder;
