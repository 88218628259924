import { useContext, useEffect, useState } from "react";
import { FaGreaterThan } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AlertContext from "../../../context/Alert/AlertContext";
import AuthContext from "../../../context/Auth/AuthContext";
import axiosPrivate from "../../../hooks/axiosPrivate";
import "../Logistic/logistic.css";
import LoaderContext from "../../../context/loader/LoaderContext";
import { showErrorToast, showSuccessToast } from "../../../Toster";
import "../Account/invoice.css";
import { BiEdit } from "react-icons/bi";

const Dispatch = () => {
  const { setIsLoading } = useContext(LoaderContext);
  const { authPermissions } = useContext(AuthContext);
  const { alertMessage, setAlertMessage } = useContext(AlertContext);
  const [logistics, setLogisticsState] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    // if (!authPermissions.includes("Account-List")) {
    //   navigate("/admin/dashboard");
    // }
    setIsLoading(true);
    axiosPrivate
      .get("dispatch/list/items")
      .then((response) => {
        setLogisticsState(response?.data?.data);

        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  }, [authPermissions]);

  useEffect(() => {
    var acc = document.getElementsByClassName("accordion-custome");
    var i;

    for (i = 0; i < acc.length; i++) {
      acc[i].addEventListener("click", function () {
        this.classList.toggle("active-custome");
        var panel = this.nextElementSibling;
        if (panel.style.display === "block") {
          panel.style.display = "none";
        } else {
          panel.style.display = "block";
        }
      });
    }
  }, [logistics]);

  //State For selected items start
  const [selectedItems, setSelectedItems] = useState([]);
  const [mergerButton, setMergerButton] = useState(false);
  //State for selected items end
  //State For Merge Modal end

  useEffect(() => {
    if (alertMessage != null) {
      toast(alertMessage.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setAlertMessage(null);
    }
  }, [alertMessage]);

  //Function to handle and add selected id start
  const selectItems = async (event, id) => {
    let oldLogisticSelectedIds = new Set(selectedItems);
    let selectedItemsArray = selectedItems;
    if (event.target.checked && oldLogisticSelectedIds.has(id) != true) {
      selectedItemsArray.push(id);
      setSelectedItems(selectedItemsArray);
    } else {
      let index = selectedItemsArray.indexOf(id);
      if (index > -1) {
        selectedItemsArray.splice(index, 1);
        setSelectedItems(selectedItemsArray);
        event.target.checked = false;
      }
    }
    if (selectedItemsArray.length > 0) {
      setMergerButton(true);
    } else {
      setMergerButton(false);
    }
    return true;
  };
  //Function to handle and add selected id end

  //Function to remove the selected items start
  const removeSelectedItems = () => {
    //Loop and remove checked items start
    document.querySelectorAll(".select-merge").forEach(function (checkBox) {
      checkBox.checked = false;
    });
    //Loop and remove checked items end

    setSelectedItems([]);
    setMergerButton(false);
    return true;
  };
  //Function to remove the selected items end

  //Merge Close Modal function end

  //Function to handle modal form submission end

  var acc = document.getElementsByClassName("accordion-custome");
  var i;

  for (i = 0; i < acc.length; i++) {
    acc[i].addEventListener("click", function () {
      this.classList.toggle("active-custome");
      var panel = this.nextElementSibling;
      if (panel.style.display === "block") {
        panel.style.display = "none";
      } else {
        panel.style.display = "block";
      }
    });
  }

  /* Function to reset state of logistic after invoice genrate */
  const resetLogisticState = async () => {
    await axiosPrivate.get("dispatch/list/items").then((response) => {
      setLogisticsState(response?.data?.data);
    });
  };

  /* Function to handle dispatch button  */
  const handleDispatchButton = async (
    event,
    orderId,
    splitId = null,
    mergeId = null
  ) => {
    const currentLogedInUserId = JSON.parse(
      localStorage.getItem("userDetails")
    );
    let user_id = currentLogedInUserId.id;
    event.preventDefault();
    setIsLoading(true);
    axiosPrivate
      .post("dispatch/update-order-status", {
        orderId: orderId,
        splitId: splitId,
        mergeId: mergeId,
        status: "1",
        user_id: user_id,
      })
      .then(async (response) => {
        if (response.status == 201) {
          setAlertMessage({ message: response.message });
          await resetLogisticState();
        }
        setIsLoading(false);
      })
      .catch(async (error) => {
        setIsLoading(false);
      });
  };

  /* Function to handle dispatch button  */
  const handleDeliverButton = async (
    event,
    orderId,
    splitId = null,
    mergeId = null
  ) => {
    const currentLogedInUserId = JSON.parse(
      localStorage.getItem("userDetails")
    );
    let user_id = currentLogedInUserId.id;
    event.preventDefault();
    setIsLoading(true);
    axiosPrivate
      .post("dispatch/deliever/update-order-status", {
        orderId: orderId,
        splitId: splitId,
        mergeId: mergeId,
        status: "1",
        user_id: user_id,
      })
      .then(async (response) => {
        if (response.status == 201) {
          setAlertMessage({ message: response.message });
          await resetLogisticState();
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  function formatDate(inputDate) {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const date = new Date(inputDate);
    const day = date.getUTCDate();
    const month = months[date.getUTCMonth()];
    const year = date.getUTCFullYear();

    return `${day} ${month} ${year}`;
  }

  const generateInvoice = async (event, splitId, mergeId, orderId, orderNo) => {
    setIsLoading(true);
    event.preventDefault();
    await axiosPrivate
      .post("accounting/generate/invoice", {
        order_id: orderId,
        split_id: splitId,
        merge_id: mergeId,
        godown_id: "2f7a93e9-1fc4-11ee-9c9b-b02628e88184",
      })
      .then(async (response) => {
        setIsLoading(false);
        if (response.status == 201) {
          await resetLogisticState();
          await response?.data?.invoice?.forEach((item) => {
            let total_quantity = 0;

            // Extract quantities, convert to numbers, and sum them up
            total_quantity = item?.products?.reduce((acc, product) => {
              return acc + Number(product?.quantity);
            }, 0);
            item.quantity = total_quantity;
          });

          showSuccessToast("Invoice generated successfully");
        }
        if (response.status == 200) {
          showErrorToast(response?.data?.message);
        }

        if (response.status == 500) {
          setIsLoading(false);
          showErrorToast("Server Error");
        }
      })
      .catch((err) => {
        setIsLoading(false);
        showErrorToast(err?.response?.data?.message);
      });
  };

  return (
    <>
      <div className="section-body">
        <div className="container-fluid">
          {/* hearder breadcrumb */}
          <div className="card-header">
            <strong className="top-left-heading">Dispatched</strong>

            <ol className="headerroutes breadcrumb m-0 bg-none ">
              <li className="item color-gray">|</li>
              <li className="item">
                <Link to="/admin/dashboard" className="route-heading item mx-2">
                  Dashboard
                </Link>
              </li>
              <li className="item">
                <FaGreaterThan className="grater-than-route" />
              </li>
              <li className="route-heading item mx-2 mt-1">Dispatched</li>
            </ol>
          </div>
          <div className="tab-content ">
            <div
              className="tab-pane fade show active"
              id="user-list"
              role="tabpanel"
            >
              <div className="card">
                <div className="card-body">
                  {logistics.map((logistic, index) => {
                    return (logistic &&
                      logistic.splited_group_items.length > 0) ||
                      logistic.merged_group_items.length > 0 ? (
                      <div
                        key={`${logistic.group_name}-${index}`}
                        style={{ padding: "0px 0px 6px 0px" }}
                      >
                        <button className="accordion-custome">
                          <div className="d-flex">
                            <span className="mr-auto p-2">
                              Dealers Location {logistic.group_name}
                            </span>
                          </div>
                        </button>
                        <div className="panel1">
                          {/* Splite Items Table Start */}
                          {(() => {
                            return logistic.splited_group_items.length <=
                              0 ? null : (
                              <div className="flex">
                                <div className="caret">
                                  <div className="splited-items">
                                    <p className="account_heading">
                                      <strong>#Splited Orders</strong>
                                    </p>
                                    <div className="table-responsive">
                                      <table className="table">
                                        <thead>
                                          <tr>
                                            <td>
                                              <strong>Order No</strong>
                                            </td>
                                            <td>
                                              <strong>Dealer Name</strong>
                                            </td>
                                            <td>
                                              <strong>Order Date</strong>
                                            </td>

                                            <td>
                                              <strong>Status</strong>
                                            </td>
                                            <td>
                                              <strong>Actions</strong>
                                            </td>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {(() => {
                                            let htmlDataFirst = [];
                                            for (
                                              let i = 0;
                                              i <
                                              logistic.splited_group_items
                                                .length;
                                              i++
                                            ) {
                                              htmlDataFirst.push(
                                                <tr
                                                  key={`${logistic.group_name}-${logistic.splited_group_items[i].name}`}
                                                >
                                                  {
                                                    <>
                                                      <td>
                                                        <span>
                                                          <Link
                                                            // className="btn btn-lg"
                                                            // style={{border:'none'}}
                                                            to={`/admin/dispatch/view/${logistic.splited_group_items[i].split_id}/${logistic.splited_group_items[i].order_id}`}
                                                          >
                                                            #
                                                            {
                                                              logistic
                                                                .splited_group_items[
                                                                i
                                                              ].order_no
                                                            }
                                                          </Link>
                                                        </span>
                                                      </td>
                                                      <td>
                                                        <span>
                                                          {
                                                            logistic
                                                              .splited_group_items[
                                                              i
                                                            ].name
                                                          }
                                                        </span>
                                                      </td>
                                                      <td>
                                                        <span>
                                                          {formatDate(
                                                            logistic
                                                              .splited_group_items[
                                                              i
                                                            ].order_date
                                                          )}
                                                        </span>
                                                      </td>

                                                      <td>
                                                        {logistic
                                                          .splited_group_items[
                                                          i
                                                        ]?.dispatch_status ==
                                                        0 ? (
                                                          <span className="tag tag-warning  ml-0 mr-0">
                                                            Pending
                                                          </span>
                                                        ) : null}
                                                        {logistic
                                                          .splited_group_items[
                                                          i
                                                        ]?.dispatch_status ==
                                                          1 &&
                                                        logistic
                                                          .splited_group_items[
                                                          i
                                                        ].delivered_status ==
                                                          0 ? (
                                                          <span
                                                            className="tag tag-success  ml-0 mr-0"
                                                            style={{
                                                              background:
                                                                "#e08b3d",
                                                              color: "white",
                                                            }}
                                                          >
                                                            Dispatched
                                                          </span>
                                                        ) : null}
                                                        {logistic
                                                          .splited_group_items[
                                                          i
                                                        ]?.dispatch_status ==
                                                          1 &&
                                                        logistic
                                                          .splited_group_items[
                                                          i
                                                        ].delivered_status ==
                                                          1 ? (
                                                          <span
                                                            className="tag tag-default  ml-0 mr-0"
                                                            style={{
                                                              background:
                                                                "#50ae31",
                                                              color: "white",
                                                            }}
                                                          >
                                                            Delivered
                                                          </span>
                                                        ) : null}
                                                      </td>
                                                      {(() => {
                                                        return (
                                                          <td
                                                            style={{
                                                              minWidth: "260px",
                                                            }}
                                                          >
                                                            {logistic
                                                              .splited_group_items[
                                                              i
                                                            ]
                                                              ?.dispatch_status ==
                                                              1 &&
                                                            logistic
                                                              .splited_group_items[
                                                              i
                                                            ]
                                                              ?.invoice_generated !=
                                                              1 ? (
                                                              <button
                                                                onClick={(
                                                                  event
                                                                ) => {
                                                                  generateInvoice(
                                                                    event,
                                                                    logistic
                                                                      .splited_group_items[
                                                                      i
                                                                    ].split_id,
                                                                    null,
                                                                    logistic
                                                                      .splited_group_items[
                                                                      i
                                                                    ].order_id,
                                                                    logistic
                                                                      .splited_group_items[
                                                                      i
                                                                    ].order_no
                                                                  );
                                                                }}
                                                                className="btn btn-default btn-sm ml-1 download-invoice"
                                                              >
                                                                {/* Download Invoice */}
                                                                Generate Invoice
                                                              </button>
                                                            ) : null}
                                                            {logistic
                                                              .splited_group_items[
                                                              i
                                                            ]
                                                              ?.dispatch_status ==
                                                            0 ? (
                                                              <button
                                                                className="btn btn-success ml-1 deliver-css"
                                                                onClick={(
                                                                  event
                                                                ) =>
                                                                  handleDispatchButton(
                                                                    event,
                                                                    logistic
                                                                      .splited_group_items[
                                                                      i
                                                                    ].order_id,
                                                                    logistic
                                                                      .splited_group_items[
                                                                      i
                                                                    ].split_id
                                                                  )
                                                                }
                                                              >
                                                                Dispatch
                                                              </button>
                                                            ) : null}
                                                            {logistic
                                                              .splited_group_items[
                                                              i
                                                            ]
                                                              .delivered_status ==
                                                              0 &&
                                                            logistic
                                                              .splited_group_items[
                                                              i
                                                            ]
                                                              ?.dispatch_status ==
                                                              1 ? (
                                                              <button
                                                                className="btn btn-success ml-1 deliver-css"
                                                                onClick={(
                                                                  event
                                                                ) =>
                                                                  handleDeliverButton(
                                                                    event,
                                                                    logistic
                                                                      .splited_group_items[
                                                                      i
                                                                    ].order_id,
                                                                    logistic
                                                                      .splited_group_items[
                                                                      i
                                                                    ].split_id
                                                                  )
                                                                }
                                                              >
                                                                Delivered
                                                              </button>
                                                            ) : null}

                                                            {logistic
                                                              .splited_group_items[
                                                              i
                                                            ]
                                                              ?.dispatch_status ==
                                                            0 ? (
                                                              <button
                                                                className="btn btn-success ml-1 "
                                                                onClick={(
                                                                  event
                                                                ) =>
                                                                  navigate(
                                                                    `/admin/dispatch/edit/${
                                                                      logistic
                                                                        .splited_group_items[
                                                                        i
                                                                      ].split_id
                                                                    }/${null}`
                                                                  )
                                                                }
                                                              >
                                                                Transporter{" "}
                                                                <BiEdit
                                                                  size={18}
                                                                />
                                                              </button>
                                                            ) : null}
                                                          </td>
                                                        );
                                                      })()}
                                                    </>
                                                  }
                                                </tr>
                                              );
                                            }
                                            return htmlDataFirst;
                                          })()}
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })()}
                          {/* Splite Items Table end */}
                          {/* Merge Items Table Start */}
                          {logistic.merged_group_items.length > 0
                            ? (() => {
                                return (
                                  <div className="flex">
                                    <div className="caret">
                                      <div className="splited-items">
                                        <p className="account_heading">
                                          <strong>#Merged Orders</strong>
                                        </p>
                                        {(() => {
                                          return logistic.merged_group_items
                                            .length <= 0
                                            ? null
                                            : logistic.merged_group_items.map(
                                                (mergedItems, index) => {
                                                  return (
                                                    <>
                                                      {/* <div className="d-flex flex-row">
                      <div>
                        {index+1}.
                      </div>
                    </div> */}
                                                      <div className="table-responsive">
                                                        <table className="table dispatch-table">
                                                          <thead>
                                                            <tr>
                                                              <td>
                                                                <b>
                                                                  {" "}
                                                                  {index + 1} .
                                                                  Order No
                                                                </b>
                                                              </td>
                                                              <td>
                                                                <b>
                                                                  Dealer Name
                                                                </b>
                                                              </td>

                                                              <td>
                                                                <b>
                                                                  Order Date
                                                                </b>
                                                              </td>

                                                              <td>
                                                                <b>Status</b>
                                                              </td>
                                                              <td>
                                                                <b> Actions</b>
                                                              </td>
                                                            </tr>
                                                          </thead>
                                                          <tbody>
                                                            {(() => {
                                                              let htmlDataFirst =
                                                                [];
                                                              for (
                                                                let i = 0;
                                                                i <
                                                                mergedItems
                                                                  .group_items
                                                                  .length;
                                                                i++
                                                              ) {
                                                                htmlDataFirst.push(
                                                                  <tr
                                                                    key={`${mergedItems.group_items[i].name}`}
                                                                  >
                                                                    {
                                                                      <>
                                                                        <td>
                                                                          <span>
                                                                            <Link
                                                                              // className="btn "
                                                                              to={`/admin/dispatch/view/merge/${mergedItems.merge_id}`}
                                                                            >
                                                                              #
                                                                              {
                                                                                mergedItems
                                                                                  .group_items[
                                                                                  i
                                                                                ]
                                                                                  .order_no
                                                                              }
                                                                            </Link>
                                                                          </span>
                                                                        </td>
                                                                        <td>
                                                                          <span>
                                                                            {
                                                                              mergedItems
                                                                                .group_items[
                                                                                i
                                                                              ]
                                                                                .name
                                                                            }
                                                                          </span>
                                                                        </td>
                                                                        <td>
                                                                          <span>
                                                                            {formatDate(
                                                                              mergedItems
                                                                                .group_items[
                                                                                i
                                                                              ]
                                                                                .order_date
                                                                            )}
                                                                          </span>
                                                                        </td>

                                                                        <td>
                                                                          {mergedItems
                                                                            .group_items[
                                                                            i
                                                                          ]
                                                                            ?.dispatch_status ==
                                                                          0 ? (
                                                                            <span className="tag tag-warning  ml-0 mr-0">
                                                                              Pending
                                                                            </span>
                                                                          ) : null}
                                                                          {mergedItems
                                                                            .group_items[
                                                                            i
                                                                          ]
                                                                            ?.dispatch_status ==
                                                                            1 &&
                                                                          mergedItems
                                                                            .group_items[
                                                                            i
                                                                          ]
                                                                            .delivered_status ==
                                                                            0 ? (
                                                                            <span
                                                                              className="tag tag-success  ml-0 mr-0"
                                                                              style={{
                                                                                background:
                                                                                  "#e08b3d",
                                                                                color:
                                                                                  "white",
                                                                              }}
                                                                            >
                                                                              Dispatched
                                                                            </span>
                                                                          ) : null}

                                                                          {mergedItems
                                                                            .group_items[
                                                                            i
                                                                          ]
                                                                            ?.dispatch_status ==
                                                                            1 &&
                                                                          mergedItems
                                                                            .group_items[
                                                                            i
                                                                          ]
                                                                            .delivered_status ==
                                                                            1 ? (
                                                                            <span
                                                                              className="tag tag-default  ml-0 mr-0"
                                                                              style={{
                                                                                background:
                                                                                  "#50ae31",
                                                                                color:
                                                                                  "white",
                                                                              }}
                                                                            >
                                                                              Delivered
                                                                            </span>
                                                                          ) : null}
                                                                        </td>
                                                                        <td>

                                                                          {mergedItems
                                                                            ?.group_items[
                                                                            i
                                                                          ]
                                                                            ?.dispatch_status ==
                                                                            1 &&
                                                                          mergedItems
                                                                            ?.group_items[
                                                                            i
                                                                          ]
                                                                            ?.invoice_generated !=
                                                                            1 ? (
                                                                            <button
                                                                              onClick={(
                                                                                event
                                                                              ) => {
                                                                                generateInvoice(
                                                                                  event,
                                                                                  null,
                                                                                  mergedItems?.merge_id,
                                                                                  mergedItems
                                                                                    ?.group_items[
                                                                                    i
                                                                                  ]
                                                                                    .order_id,
                                                                                  mergedItems
                                                                                    ?.group_items[
                                                                                    i
                                                                                  ]
                                                                                    .order_no
                                                                                );
                                                                              }}
                                                                              className="btn btn-default btn-sm ml-1 download-invoice"
                                                                            >
                                                                              {/* Download Invoice */}
                                                                              Generate
                                                                              Invoice
                                                                            </button>
                                                                          ) : null}

                                                                          {mergedItems
                                                                            ?.group_items[
                                                                            i
                                                                          ]
                                                                            ?.dispatch_status ==
                                                                          0 ? (
                                                                            <button
                                                                              className="btn btn-success ml-1"
                                                                              onClick={(
                                                                                event
                                                                              ) =>
                                                                                handleDispatchButton(
                                                                                  event,
                                                                                  mergedItems
                                                                                    ?.group_items[
                                                                                    i
                                                                                  ]
                                                                                    ?.order_id,
                                                                                  null,
                                                                                  mergedItems?.merge_id
                                                                                )
                                                                              }
                                                                            >
                                                                              Dispatch
                                                                            </button>
                                                                          ) : null}

                                                                          {mergedItems
                                                                            ?.group_items[
                                                                            i
                                                                          ]
                                                                            ?.dispatch_status ==
                                                                          0 ? (
                                                                            <button
                                                                              className="btn btn-success ml-1"
                                                                              style={{
                                                                                width:
                                                                                  "auto",
                                                                              }}
                                                                              onClick={(
                                                                                event
                                                                              ) =>
                                                                                navigate(
                                                                                  `/admin/dispatch/edit/${null}/${
                                                                                    mergedItems?.merge_id
                                                                                  }`
                                                                                )
                                                                              }
                                                                            >
                                                                              Transporter{" "}
                                                                              <BiEdit
                                                                                size={
                                                                                  18
                                                                                }
                                                                              />
                                                                            </button>
                                                                          ) : null}
                                                                          {mergedItems
                                                                            .group_items[
                                                                            i
                                                                          ]
                                                                            .delivered_status ==
                                                                            0 &&
                                                                          mergedItems
                                                                            .group_items[
                                                                            i
                                                                          ]
                                                                            ?.dispatch_status ==
                                                                            1 ? (
                                                                            <button
                                                                              className="btn btn-success ml-1"
                                                                              onClick={(
                                                                                event
                                                                              ) =>
                                                                                handleDeliverButton(
                                                                                  event,
                                                                                  mergedItems
                                                                                    .group_items[
                                                                                    i
                                                                                  ]
                                                                                    .order_id,
                                                                                  null,
                                                                                  mergedItems.merge_id
                                                                                )
                                                                              }
                                                                            >
                                                                              Delivered
                                                                            </button>
                                                                          ) : null}
                                                                        </td>
                                                                      </>
                                                                    }
                                                                  </tr>
                                                                );
                                                              }
                                                              return htmlDataFirst;
                                                            })()}
                                                          </tbody>
                                                        </table>
                                                      </div>
                                                    </>
                                                  );
                                                }
                                              );
                                        })()}
                                      </div>
                                    </div>
                                  </div>
                                );
                              })()
                            : null}
                          {/* Merge Items Table end */}
                        </div>
                      </div>
                    ) : (
                      ""
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dispatch;
