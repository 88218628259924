import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { FaGreaterThan } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { showErrorToast } from "../../../Toster";
import { showSuccessToast } from "../../../Toster";
import AlertContext from "../../../context/Alert/AlertContext";
import axiosPrivate from "../../../hooks/axiosPrivate";
import classes from "./addUserStyle.module.css";
import LoaderContext from "../../../context/loader/LoaderContext";
import { toast } from "react-toastify";
import { Dropdown } from "primereact/dropdown";

//mobile validation check rajex
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

// validation
var validationRules = {
  name: Yup.string()
    .max(120, "Name must not exceed 120 characters")
    .required("Name is required"),
  role: Yup.string().required("Role is required"),
  

  email: Yup.string()
    .notRequired()
    .matches(
      /^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/,
      "Please enter a valid email!"
    ),
  
    primary_mobile: Yup.string().test('starts-not-with-0-to-5', 'Primary mobile number is not valid', function(value) {
     
      if (!value) return true; 
      return !/^[0-5]/.test(value);
    }).required("Primary mobile number is required"),

    secondary_mobile: Yup.string().test('starts-not-with-0-to-5', 'Secondary mobile number is not valid', function(value) {
     
      if (!value) return true;
      return !/^[0-5]/.test(value);
    }),
    
};

const AddNMStaff = () => {
  const { setIsLoading } = useContext(LoaderContext);
  const { alertMessage, setAlertMessage } = useContext(AlertContext);
  const navigate = useNavigate();
  const [roles, setRoles] = useState([]);
  const [selectedValue, setSelectedValue] = useState("admin");
  const [isStateCityDropDown, setIsStateCityDropDown] = useState(false);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [stateSelectedState, setStateSelectedState] = useState(null);
  const [cityValue, setCityValue] = useState(null);
  const [isAreaDropDown, setIsAreaDropDown] = useState(false);
  const [areaManagerList, setAreaMangerList] = useState([]);

  // get all nmStaff roles
  useEffect(() => {
    setIsLoading(true);
    axiosPrivate
      .get("roles/get/all ")
      .then((user_roles) => {
        setRoles(user_roles?.data?.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  }, []);

  //drowpdown show for page load according to role base = area_manager,logistic_manager ,dispatch_team
  useEffect(() => {
    const hasLabel = ["3", "5", "6", "7"].includes(selectedValue);
    hasLabel ? setIsStateCityDropDown(true) : setIsStateCityDropDown(false);
    const hasLabelDealear = ["4"].includes(selectedValue);

    hasLabelDealear ? setIsAreaDropDown(true) : setIsAreaDropDown(false);
  }, [selectedValue]);

  // state list
  useEffect(() => {
    axiosPrivate
      .get("locations/get/state")
      .then((response) => {
        setStates(response?.data?.data);
      })
      .catch((error) => {
        if (error.response) {
          setStates([]);
          //response status is an error code
        }
      });
  }, []);

  // Area Manager list
  useEffect(() => {
    axiosPrivate.get("users/get/user/area_manager").then((response) => {
      setAreaMangerList(response.data.data);
    });
  }, []);

  // add data to api (after check validation)
  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      primary_mobile: '',
      secondary_mobile: '',
      role: '',
      addresses: [
        {
          state: '',
          city: '',
          zip: ''
        }
      ],
      area_manager_id: '',
    },
    validationSchema: Yup.object(validationRules),

    onSubmit: async (values, { setSubmitting, setFieldError }) => {
      setIsLoading(true);
      axiosPrivate
        .post('users/store', values)
        .then((response) => {
          showSuccessToast('New User Created Successfully');
          navigate('/admin/users');
        })
        .catch((error) => {
          if (typeof error?.response?.data?.errors == 'object') {
            showErrorToast(error?.response?.data?.errors[0]?.msg);
          }

          else {
            showErrorToast(error?.response?.data?.errors[0]?.msg);
          }
        }).finally(() => {
          setIsLoading(false);
        });
    },
  });

  const onSelectState = (event) => {
    setStateSelectedState(event.target.value);
    // const stateArray = event.target.value.map(obj => obj.state);
    axiosPrivate
      .post("locations/get/city", { state: event.target.value })
      .then((response) => {
        let currentArray = cities;
        currentArray.push(response.data.data);
        setCities(currentArray);
      })
      .catch((error) => {
      });
  };

  const onAddRow = (check) => {
    if (check) {
      let newObj = {
        state: "",
        city: "",
        zip: "",
      };
      let currentObj = formik.values.addresses;
      currentObj.push(newObj);
      formik.setFieldValue("addresses", currentObj);
    } else {
      // if(formik.values.addresses.length > 1) {
      let currentObj = formik.values.addresses;
      currentObj.splice(currentObj.length - 1, 1);
      formik.setFieldValue("addresses", currentObj);
      // }
    }
  };

  function restrictChar(event) {
    const numericValue = event.target.value.replace(/\D/g, "");
    formik.setFieldValue(event.target.name, numericValue);
    formik.handleChange(numericValue);
  }

  //alert message
  // useEffect(() => {
  //   debugger;
  //   if (alertMessage != null) {
  //     toast(alertMessage.message, {
  //       position: "top-right",
  //       autoClose: 5000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //       theme: "light",
  //     });
  //     setAlertMessage(null);
  //   }
  // }, [alertMessage]);


  // State Dropdown
  const selectedStateTemplate = (option, props) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <div>{option}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };


  const stateTemplate = (option) => {    
    return (
      <div className="flex align-items-center">
        <div>{option}</div>
      </div>
    );
  };

  // City dropdown
  const selectedCityTemplate = (option, props) => {
    if (option) {
      return (
          <div className="flex align-items-center">
              <div>{option?.city}</div>
          </div>
      );
  }

  return <span>{props.placeholder}</span>;
  };


  const cityTemplate = (option) => {    
    return (
      <div className="flex align-items-center">
          <div>{option?.city}</div>
      </div>
  );
  };


  const numberFilter = (event) => {
    const inputValue = event.target.value;

    const numericValue = inputValue.replace(/\D/g, "");
    formik.setFieldValue(event.target.name, numericValue);
  };

  const nameFilter = (event) => {
    const inputValue = event.target.value;
    if (/^[A-Za-z\s'-]+$/.test(inputValue) || inputValue === "") {
      formik.setFieldValue(event.target.name, inputValue);
    }
  };

  return (
    <>
      <div className="section-body">
        <div className="container-fluid">
          {/* hearder breadcrumb */}
          <div className="card-header">
            <strong className="top-left-heading">Add New User</strong>

            <ol className="headerroutes breadcrumb m-0 bg-none ">
              <li className="item color-gray">|</li>
              <li className="item">
                <Link to="/admin/dashboard" className="route-heading item mx-2">
                  Dashboard
                </Link>
              </li>
              <li className="item">
                <FaGreaterThan className="grater-than-route" />
              </li>
              <li className="route-heading item mx-2 mt-1">
                <Link to="/admin/users">Users</Link>
              </li>
              <li className="item">
                <FaGreaterThan className="grater-than-route" />
              </li>

              <li className="route-heading item mx-2 mt-1">Add New User</li>
            </ol>
          </div>
          <div className="tab-content">
            <form onSubmit={formik.handleSubmit}>
              <div
                className="tab-pane fade show active"
                id="user-add"
                role="tabpanel"
              >
                <div className="card">
                  <div className="card-body">
                    <div className="row clearfix">
                      {/* name */}
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label>
                          Name:<span className="required">*</span>
                        </label>
                        <div className="form-group">
                          <input
                            id="name"
                            name="name"
                            type="text"
                            className="form-control"
                            placeholder="Name *"
                            onChange={(event) => {
                              nameFilter(event);
                            }}
                            onBlur={formik.handleBlur}
                            value={formik.values.name}
                          />
                          {formik.touched.name && formik.errors.name ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.name}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      {/* email */}
                      <div className="col-md-6 col-sm-12">
                        <label>
                          Email:
                        </label>

                        <div className="form-group">
                          <input
                            id="email"
                            name="email"
                            type="text"
                            className="form-control"
                            placeholder="Email ID *"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.email}
                          />
                          {formik.touched.email && formik.errors.email ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.email}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      {/* primary mobile number */}
                      <div className="col-md-6 col-sm-12">
                        <label>
                          Primary Mobile Number:
                          <span className="required">*</span>
                        </label>

                        <div className="form-group">
                          <input
                            id="primary_mobile"
                            name="primary_mobile"
                            type="text"
                            
                            className="form-control"
                            placeholder="Primary Mobile Number *"
                            onChange={(event) => {
                              numberFilter(event);
                            }}
                            onBlur={formik.handleBlur}
                            value={formik.values.primary_mobile}
                            maxLength={10}
                          />
                          {formik.touched.primary_mobile &&
                            formik.errors.primary_mobile ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.primary_mobile}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      {/* secondary mobile number */}
                      <div className="col-md-6 col-sm-12">
                        <label>Secondary Mobile Number (Optional):</label>

                        <div className="form-group">
                          <input
                            id="secondary_mobile"
                            name="secondary_mobile"
                            type="text"
                            maxLength={10}
                            
                            className="form-control"
                            placeholder="Secondary Mobile Number"
                            onChange={(event) => {
                              numberFilter(event);
                            }}
                            onBlur={formik.handleBlur}
                            value={formik.values.secondary_mobile}
                          />
                          {formik.touched.secondary_mobile &&
                            formik.errors.secondary_mobile ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.secondary_mobile}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      {/* role */}
                      <div className="col-md-6 col-sm-12">
                        <label>
                          Select Role:<span className="required">*</span>
                        </label>

                        <div className="form-group">
                          <select
                            className="form-control"
                            id="role"
                            name="role"
                            onChange={(event) => {
                              setSelectedValue(event.target.value);
                              formik.handleChange(event);
                              if (
                                event.target.value == 3 ||
                                event.target.value == 8
                              ) {
                                validationRules["email"] =
                                  Yup.string().required("Email is required");
                              } else {
                                if (validationRules.email) {
                                  formik.errors.email = "";
                                  delete validationRules.email;
                                }
                              }
                            }}
                            onBlur={formik.handleBlur}
                          >
                            <option disabled selected>Select Role</option>
                            {roles.length > 0 &&
                              roles.map((option, ind) =>
                                ![4, 8].includes(option.id) ? (
                                  <option
                                    key={`role_${ind}`}
                                    value={option.id}
                                  >
                                    {option.name
                                      .split("_")
                                      .map(
                                        (word) =>
                                          word.charAt(0).toUpperCase() +
                                          word.slice(1)
                                      )
                                      .join(" ")}
                                  </option>
                                ) : (
                                  ""
                                )
                              )}
                          </select>

                          {formik.touched.role && formik.errors.role && (
                            <div className={classes.invalidDataError}>
                              {formik.errors.role}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row clearfix">
                      {/* state dropdown */}
                      {isStateCityDropDown ? (
                        <>
                          {formik.values.addresses.map((res, index) => (
                            <>
                              <div className="col-md-4 col-sm-12">
                                <label>
                                  State:<span className="required"></span>
                                </label>
                                <div className="form-control card flex justify-content-center form-group add-nm-staff-state-dropdown" style={{ padding: '0px' }}>
                                  <Dropdown value={stateSelectedState} onChange={(e) => onSelectState(e)} options={states} optionLabel="name" placeholder="Select State"
                                    filter valueTemplate={selectedStateTemplate} itemTemplate={stateTemplate} className="w-full md:w-14rem" style={{ paddingLeft: '12px' }} id={`addresses[${index}].state`}
                                    name={`addresses[${index}].state`}
                                    onBlur={formik.handleBlur} />
                                </div>

                                {/* <div className="form-group">
                                  <MultiSelect display="chip" optionLabel="state" value={stateSelectedState} placeholder="Select State" name='state' filter options={states} onChange={(e) => onSelectState(e)} />
                                  <select
                                    className="form-control show-tick"
                                    id={`addresses[${index}].state`}
                                    name={`addresses[${index}].state`}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.addresses[index].state}
                                    onChange={(event) => {
                                      setStateSelectedState(event.target.value);
                                      formik.handleChange(event);
                                      onSelectState(event);
                                    }}
                                  > 
                                    <option>Select State</option>
                                    {states.length > 0 &&
                                      states?.map((role, ind) => (
                                        <option key={ind} value={role} style={{color: 'black'}}>
                                          {role}
                                        </option>
                                      ))}
                                  </select>
                                </div>*/}
                              </div>
                              {/* city dropdown */}
                              <div className="col-md-4 col-sm-12">
                                <label>
                                  City:<span className="required"></span>
                                </label>
                                <div className="card flex justify-content-center add-nm-staff-state-dropdown form-control" style={{padding: '0px'}}>
                                  <Dropdown value={cityValue} onChange={(e)=>setCityValue(e.target.value)} options={cities[index]} optionLabel="city" placeholder="Select City"  id={`addresses[${index}].city`} 
                                    name={`addresses[${index}].city`} 
                                    filter valueTemplate={selectedCityTemplate} itemTemplate={cityTemplate} className="w-full md:w-14rem" style={{ paddingLeft: '12px' }}/>
                                </div>
                                {/* <div className="form-group">
                                  <select
                                    className="form-control show-tick"
                                    id={`addresses[${index}].city`}
                                    name={`addresses[${index}].city`}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.addresses[index].city}
                                  >
                                    <option>Select City</option>
                                    {cities[index]?.length > 0 &&
                                      cities[index]?.map((role, index) => (
                                        <option key={index} value={role.id}>
                                          {role.city}
                                        </option>
                                      ))}
                                  </select>
                                </div> */}
                              </div>
                              {/* pincode */}
                              <div className="col-md-4 col-sm-12">
                                <label>
                                  Pincode:<span className="required"></span>
                                </label>

                                <div className="form-group">
                                  <input
                                    className="form-control"
                                    placeholder="Enter zip_code"
                                    type="text"
                                    minLength={6}
                                    maxLength={6}
                                    name={`addresses[${index}].zip`}
                                    id={`addresses[${index}].zip`}
                                    onChange={(e) => restrictChar(e)}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.addresses[index].zip}
                                  />
                                </div>
                              </div>
                            </>
                          ))}
                        </>
                      ) : (
                        ""
                      )}
                      {/* Area Manager DropDown */}
                      {isAreaDropDown ? (
                        <div className="col-md-6 col-lg-6 col-sm-12">
                          <label>
                            Area Manager:<span className="required">*</span>
                          </label>

                          <div className="form-group">
                            <select
                              className="form-control show-tick"
                              id="area_manager_id"
                              name="area_manager_id"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            >
                              <option disabled>Select Area Manager</option>
                              {areaManagerList.length > 0 &&
                                areaManagerList.map((role, key) => (
                                  <option key={key + role.name} value={role.id}>
                                    {role.name}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}

                      {/* action buttons for dynamic rows for state city and zip  */}
                      {isStateCityDropDown ? (
                        <div className="action_btn_section mb-5">
                          <button
                            type="button"
                            onClick={() => {
                              onAddRow(true);
                            }}
                          >
                            +
                          </button>
                          <button
                            type="button"
                            onClick={() => {
                              onAddRow(false);
                            }}
                          >
                            -
                          </button>
                        </div>
                      ) : (
                        ""
                      )}

                      {/* button close and submit */}
                      <div className="col-md-12 col-sm-12 submit-btn">
                        <div className="form-group">
                          <button
                            type="button"
                            className="btn btn-secondary"
                            data-dismiss="modal"
                            onClick={() => navigate("/admin/users")}
                          >
                            Close
                          </button>
                          <button
                            type="submit"
                            className="btn btn-primary ml-2"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddNMStaff;
