import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

const Accountpiechart = ({splitedItems,mergedItems}) => {
   const [series, setSeries] =useState([0,0]);
 

   useEffect(() => {
    
    // const cancelledOrders = orders.filter((order) => order?.order_status?.props?.children === "Cancelled");
    // const pendingOrders = orders.filter((order) => order?.order_status?.props?.children === "Pending");
    // const successOrders = orders.filter((order) => order?.order_status?.props?.children === "Confirmed");

    const splitItems = splitedItems?.flatMap((item) => 
      item?.splited_group_items
    );
    
    
    // const mergeItems = mergedItems?.flatMap((item) => 
    //   item?.merged_group_items?.map((merge) => merge) 
    // );
    const mergeItems = mergedItems?.flatMap((item) => item.merged_group_items) // Extract all merged_group_items
    ?.flatMap((mergedItem) => mergedItem.group_items); // Flatten the group_items
    
   
    setSeries([splitItems.length, mergeItems.length]);
    // setSeries([splitItems?.length, mergeItems?.length]);

  }, [splitedItems,mergedItems]);

  const [options] =useState({
    chart: {
      width: 380,
      type: "pie",
    },
    labels: ["Splited Items","Merged Items"],
    legend: {
      position: "bottom",
      markers: {
        fillColors: ["#6495ed", "#ff7f50"],
      },
    },
    fill: {
      colors: ["#6495ed", "#ff7f50"],
    },
    dataLabels: {
      enabled: false,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: "100%",
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  });

  return (
    <div>
      <div id="chart">
        <ReactApexChart
          options={options}
          series={series}
          type="pie"
          width="100%"
        />
      </div>
    </div>
  );
};

export default Accountpiechart;
