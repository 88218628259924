import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { FaGreaterThan } from "react-icons/fa";
import { GrAddCircle, GrSubtractCircle } from "react-icons/gr";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { showErrorToast } from "../../../Toster";
import AlertContext from "../../../context/Alert/AlertContext";
import axiosPrivate from "../../../hooks/axiosPrivate";
import classes from "./Order.module.css";
import AuthContext from "../../../context/Auth/AuthContext";
import LoaderContext from "../../../context/loader/LoaderContext";
import ReactSelect from "react-select";
import Creatable from "react-select/creatable";
import { FieldArray, Field } from "formik";

// validation
const validationRules = {
  dealer_id: Yup.string().required("Dealer is required"),
  areaManager_id: Yup.string().required("Area manager is required"),
  salesman_id: Yup.string().required("Sales person is required"),
  image: Yup.string().required("Image is required"),
  order_rows: Yup.array()
    .of(
      Yup.object().shape({
        stock_id: Yup.string().required("Product is required"),
        shipping_address_id: Yup.string().required("Shipping address is required"),
        price: Yup.number()
          .required("Price is required")
          .min(0, "Price cannot be negative"),
        qty: Yup.number()
          .required("Quantity is required")
          .min(1, "Quantity must be at least 1"),
      })
    )
    .min(1, "At least one order row is required"),
};

const AddOrder = () => {
  const navigate = useNavigate();
  const { setIsLoading } = useContext(LoaderContext);
  const { setAlertMessage } = useContext(AlertContext);
  const { userDetails } = useContext(AuthContext);
  const [products, setProducts] = useState([]);
  const [discount, setDiscount] = useState(0);
  const [salesList, setSalesList] = useState([]);
  const [dealerList, setDealerList] = useState([]);
  const [areaManagerList, setAreaManagerList] = useState([]);
  const [seasonList, setSeasonList] = useState([]);
  const [logicticList, setLogisticList] = useState([]);
  const [shippingAddress, setShippingAddress] = useState([]);
  const [seasonDropDown, setSeasonDropDown] = useState(false);
  const [dearlerDropDown, setDealerDropDown] = useState(false);
  const [salesmanDropDown, setSalesmanDropDown] = useState(false);
  const [areamanagerDropDown, setAreaManagerDropDown] = useState(false);
  const [selectDiscountType, setSelectDiscountType] = useState();
  const [logicticDropDown, setLogisticDropDown] = useState(false);
  const [userData, setUserData] = useState();
  const [shippingStates, setShippingStates] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [selectedCity, setSelectedCity] = useState({});
  const [defaultBillingAddress, setDefaultBillingAddress] = useState("");

  //Function to load sales person List
  const salesPersonListHandler = async (id) => {
    //Sales person list start
    if (id != undefined && id != "") {
      await axiosPrivate
        .get(`users/get/sales/person/${id}`)
        .then((salesPerson) => {
          setSalesList(salesPerson?.data?.data);
        });
    } else {
      setSalesList([]);
    }
    return true;
    //Sales person list end
  };

  //Load address id user is

  //Load address

  const formik = useFormik({
    initialValues: {
      dealer_id: "",
      areaManager_id: "",
      salesman_id: "",
      stock_id: "",
      price: "",
      qty: "",
      discount: "",
      totalAmount: "",
      season_id: "",
      logistic_user_id: "",
      area_manager_remark: "",
      remark: "",
      image: "",
      order_rows: [
        {
          stock_id: "",
          price: "",
          qty: "",
          shipping_address_id: "",
          shipping_address: "",
          shipping_state: "",
          shipping_city: "",
          shipping_zip: "",
        },
      ],
    },
    validationSchema: Yup.object(validationRules),
    onSubmit: async (values, { setSubmitting, setFieldError }) => {
      setIsLoading(true);
      let orderId = "";
      const userData = JSON.parse(localStorage.getItem("userDetails") || "{}");
      let formData = new FormData();
      formData.append("dealer_id", values.dealer_id);
      formData.append("areaManager_id", values.areaManager_id);
      formData.append("salesman_id", values.salesman_id);
      // formData.append("order_rows", JSON.stringify(tableData));
      formData.append("order_rows", JSON.stringify(formik.values.order_rows));
      formData.append("remark", values.remark);
      formData.append("image", values.image);
      formData.append("area_manager_remark", values.area_manager_remark);
      formData.append("total_amount", calculateTotalAmount());
      formData.append("season_id", values.season_id);
      formData.append("logistic_user_id", values.logistic_user_id);
      formData.append("current_user_id", userDetails.id);

      await axiosPrivate
        .post("orders/store", formData)
        .then((response) => {
          orderId = response.data.orderId;
          setAlertMessage({ message: "New Order Created Successfully" });
          setIsLoading(false);
          navigate("/admin/order");
        })
        .catch((error) => {
          setIsLoading(false);
          if (typeof error.response.data.errors == "array") {
            showErrorToast(error.response.data.errors[0]?.msg);
          } else {
            showErrorToast(error.response.data.errors?.msg);
          }
        });

      let object = {
        title: "order created",
        order_id: orderId,
        user_id: values.areaManager_id,
        notification_for: "area_manager",
      };
      await axiosPrivate.post("/notifications/store", object).then((res) => {});
    },
  });

  const setDealerIdHandler = (dealerId) => {
    if (dealerId != "") {
      //Get Dealer address
      axiosPrivate.get(`/users/get/addresses/${dealerId}`).then((addresses) => {
        const dropdownOptions = addresses?.data?.data.map((item) => {
          if (item?.address_type == "billing_address") {
            setDefaultBillingAddress(item?.id);
          }
          const parts = [
            item.shipping_address,
            item.shipping_city,
            item.shipping_state,
            item.shipping_zip,
          ].filter(Boolean); // Removes any null or undefined values

          return {
            value: item.id,
            label: parts.join(", "), // Joins non-null parts with a comma
          };
        });

        setShippingAddress(dropdownOptions);
      });
    }
    formik.setFieldValue("dealer_id", dealerId);
    return true;
  };

  useEffect(() => {
    if (defaultBillingAddress) {
      // Update the order rows in Formik state
      formik.setFieldValue(
        "order_rows",
        formik.values.order_rows.map((item) => ({
          ...item,
          shipping_address_id: defaultBillingAddress,
        }))
      );
    }
  }, [defaultBillingAddress]);

  // calculate total price  (price*qty)
  const calculateTotalPrice = () => {
    let totalPrice = 0;
    // tableData.forEach((item) => {
    //   totalPrice += parseInt(item.price) * parseInt(item.qty);
    // });
    formik.values.order_rows.forEach((item) => {
      totalPrice += parseInt(item.price) * parseInt(item.qty);
    });
    return totalPrice;
  };

  // calculateTotalAmount  (totalprice /100)  including discount
  const calculateTotalAmount = () => {
    let totalPrice = calculateTotalPrice();
    if (discount != null && discount > 0 && discount <= 100) {
      let discountAmount = parseFloat(discount);
      totalPrice -= (parseInt(totalPrice) / 100) * parseInt(discountAmount);
    }
    return parseInt(totalPrice);
  };

  const handleShippingAddressChange = (selectedOption, index) => {
    if (selectedOption.__isNew__) {
      const newAddress = {
        value: Date.now(), // Unique ID for the new address
        label: selectedOption.label,
      };

      // Add the new address to the shippingAddress state
      setShippingAddress((prev) => [...prev, newAddress]);

      formik.setFieldValue(`order_rows[${index}].shipping_address_id`, 0);
      formik.setFieldValue(
        `order_rows[${index}].shipping_address`,
        selectedOption.label
      );
    } else {
      formik.setFieldValue(
        `order_rows[${index}].shipping_address_id`,
        selectedOption.value
      );
    }
  };

  // const handleShippingAddressChange = (selectedOption, index) => {
  //   if (selectedOption.__isNew__) {
  //     const newAddress = {
  //       value: Date.now(), // Unique ID for the new address
  //       label: selectedOption.label,
  //     };

  //     setShippingAddress((prev) => {
  //       const updatedAddresses = prev.filter((address) => !address.value); // Keep only non-custom addresses
  //       return [...updatedAddresses, newAddress]; // Replace with the new custom address
  //     });

  //     formik.setFieldValue(`order_rows[${index}].shipping_address_id`, newAddress.value);
  //     formik.setFieldValue(`order_rows[${index}].shipping_address`, newAddress.label);
  //   } else {
  //     formik.setFieldValue(`order_rows[${index}].shipping_address_id`, selectedOption.value);
  //   }
  // };

  const getState = () => {
    axiosPrivate
      .get("locations/get/state")
      .then((response) => {
        setShippingStates(response.data.data);
      })
      .catch((err) => {});
  };

  // Component Load start all listing
  useEffect(() => {
    setIsLoading(true);
    //Check role start
    const userData = JSON.parse(localStorage.getItem("userDetails") || "{}");
    setUserData(userData);
    if (userData.role == "3" || userData.role == "1") {
      setDealerDropDown(true);
      setSalesmanDropDown(true);
      setAreaManagerDropDown(true);
      setLogisticDropDown(true);
    } else if (userData.role == "4") {
      setSalesmanDropDown(true);
      setAreaManagerDropDown(true);
      if (localStorage.getItem("userDetails")) {
        let area_manager_id = JSON.parse(localStorage.getItem("userDetails"));
        formik.setFieldValue("areaManager_id", area_manager_id.area_manager_id);
        if (area_manager_id.area_manager_id) {
          salesPersonListHandler(area_manager_id.area_manager_id);
        }

        if (area_manager_id.id) {
          setDealerIdHandler(area_manager_id.id);
        }
      }
    }

    getState();
    //Check role end
    //Logistic list start
    axiosPrivate
      .get("users/get/logistic/manager")

      .then((logistics) => {
        setLogisticList(logistics?.data?.data);
      });
    //Logistic list end
    // Season list start
    axiosPrivate.post("seasons").then((seasons) => {
      setSeasonList(seasons?.data?.data);
    });
    //Dealer List start
    axiosPrivate.post("dealers").then((dealers) => {
      setDealerList(dealers?.data?.data);
    });
    //Dealer list end
    // if(userData.role != "4"){
    //Area Manager List start
    axiosPrivate.get("users/get/area/manager").then((AreaManager) => {
      setAreaManagerList(AreaManager?.data?.data);
    });
    //Area Manager List end
    // }
    //Product List start
    axiosPrivate
      .get("stocks")
      .then((order) => {
        setProducts(order?.data?.data);

        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
    //Product List end
  }, []);

  useEffect(() => {
    if (Object.keys(selectedCity).length > 0) {
      axiosPrivate
        .post("locations/get/city", { state: selectedCity.name })
        .then((response) => {
          let cities = cityList;
          cities[selectedCity.index] = response.data.data;
          setCityList(cities);
        });
    }
  }, [selectedCity]);

  const customStyles = {
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isSelected ? "#767676" : "white",
      color: state.isSelected ? "white" : "black",
      "&:hover": {
        backgroundColor: state.isSelected ? "#767676" : "#e0e0e0",
      },
    }),
    control: (base) => ({
      ...base,
      borderColor: "#ddd",
      boxShadow: "none",
      "&:hover": { boxShadow: "0px 0px 2px 2px #d6d4dd" },
    }),
  };

  // Transform dealerList into the format required by ReactSelect
  const dealerOptions = dealerList.map((dealer) => ({
    value: dealer.id,
    label: dealer.name,
  }));

  const areaManagerOptions = areaManagerList
    .filter((manager) => manager.id != null)
    .map((manager) => ({
      value: manager.id,
      label: manager.name,
    }));

  const salesPersonOptions = [
    ...salesList.map((salesPerson) => ({
      value: salesPerson.id,
      label: salesPerson.name,
    })),
    ...(salesList.length > 0
      ? [
          {
            value: "vacant",
            label: "Vacant",
          },
        ]
      : [
          {
            value: "vacant",
            label: "Vacant",
          },
        ]),
  ];

  const numberFilter = (event) => {
    const inputValue = event.target.value;

    const numericValue = inputValue.replace(/\D/g, "");
    formik.setFieldValue(event.target.name, numericValue);
  };
  //Component Load end

  return (
    <div>
      <div className="section-body">
        <div className="container-fluid">
          {/* hearder breadcrumb */}
          <div className="card-header">
            <strong className="top-left-heading">Add Order</strong>

            <ol className="headerroutes breadcrumb m-0 bg-none ">
              <li className="item color-gray">|</li>
              <li className="item">
                <Link to="/admin/dashboard" className="route-heading item mx-2">
                  Dashboard
                </Link>
              </li>
              <li className="item">
                <FaGreaterThan className="grater-than-route" />
              </li>
              <li className="route-heading item mx-2 mt-1">
                <Link to="/admin/order">Order</Link>
              </li>
              <li className="item">
                <FaGreaterThan className="grater-than-route" />
              </li>

              <li className="route-heading item mx-2 mt-1">Add Order</li>
            </ol>
          </div>

          <div className="tab-content ">
            <form onSubmit={formik.handleSubmit}>
              <div
                className="tab-pane fade show active"
                id="user-add"
                role="tabpanel"
              >
                <div className="card">
                  <div className="row mx-3">
                    {dearlerDropDown ? (
                      <>
                        <div className="col-lg-4 col-md-4 col-sm-12">
                          <div className="form-group">
                            <label>
                              Dealer :<span className="required">*</span>
                            </label>
                            {/* <input
                              type="text"
                              className="form-control"
                              name="searchDealer"
                              placeholder="Search for a dealer..."
                              onChange={formik.handleChange}
                              value={searchKeyword}
                            /> */}

                            <ReactSelect
                              id="dealer_id"
                              styles={customStyles}
                              name="dealer_id"
                              options={dealerOptions}
                              value={dealerOptions.find(
                                (option) =>
                                  option.value === formik.values.dealer_id
                              )}
                              onChange={(selectedOption) => {
                                setDealerIdHandler(selectedOption?.value || "");
                              }}
                              onBlur={formik.handleBlur}
                              placeholder="Select Dealer"
                              isSearchable
                            />
                            {formik.touched.dealer_id &&
                            formik.errors.dealer_id ? (
                              <div className={classes.invalidDataError}>
                                {formik.errors.dealer_id}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    {/* dealer select */}

                    {areamanagerDropDown > 0 ? (
                      <>
                        {/* Area Manager Select */}
                        <div className="col-lg-4 col-md-4 col-sm-12">
                          <div className="form-group">
                            <label>
                              Area Manager:<span className="required">*</span>
                            </label>

                            <ReactSelect
                              id="areaManager_id"
                              name="areaManager_id"
                              styles={customStyles}
                              options={areaManagerOptions}
                              value={areaManagerOptions.find(
                                (option) =>
                                  option.value === formik.values.areaManager_id
                              )}
                              onChange={async (selectedOption) => {
                                // Update Formik value
                                formik.setFieldValue(
                                  "areaManager_id",
                                  selectedOption?.value || ""
                                );
                                // Trigger salesPersonListHandler
                                await salesPersonListHandler(
                                  selectedOption?.value || ""
                                );
                              }}
                              onBlur={formik.handleBlur}
                              placeholder="Select Area Manager"
                              isSearchable
                            />
                            {formik.touched.areaManager_id &&
                            formik.errors.areaManager_id ? (
                              <div className={classes.invalidDataError}>
                                {formik.errors.areaManager_id}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    {/* sales person dropdown */}
                    {salesmanDropDown ? (
                      <>
                        <div className="col-lg-4 col-md-4 col-sm-12">
                          <div className="form-group">
                            <label>
                              Sales Person:<span className="required">*</span>
                            </label>
                            <ReactSelect
                              id="salesman_id"
                              name="salesman_id"
                              styles={customStyles}
                              options={salesPersonOptions}
                              value={salesPersonOptions.find(
                                (option) =>
                                  option.value === formik.values.salesman_id
                              )}
                              onChange={(selectedOption) => {
                                formik.setFieldValue(
                                  "salesman_id",
                                  selectedOption?.value || ""
                                );
                              }}
                              onBlur={formik.handleBlur}
                              placeholder="Select Sales Person"
                              isSearchable
                            />
                            {formik.touched.salesman_id &&
                            formik.errors.salesman_id ? (
                              <div className={classes.invalidDataError}>
                                {formik.errors.salesman_id}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                  {/* table */}
                  <div
                    className="row mx-4 p-2"
                    style={{ border: "1px solid black" }}
                  >
                    {formik.values.order_rows.map((item, index) => (
                      <React.Fragment key={index}>
                        {/* Product Dropdown */}
                        <div className="col-lg-4 col-md-4 col-sm-12">
                          <div className="form-group">
                            <label>
                              Product:<span className="required">*</span>
                            </label>
                            <select
                              id={`order_rows[${index}].stock_id`}
                              className="form-control"
                              name={`order_rows[${index}].stock_id`}
                              value={item.stock_id}
                              onChange={(event) => {
                                const selectedValue = event.target.value;

                                // Find the selected product object
                                const selectedProduct = products.find(
                                  (product) => product.id === selectedValue
                                );

                                // Set the selected stock_id and price in Formik's state
                                formik.setFieldValue(
                                  `order_rows[${index}].stock_id`,
                                  selectedValue
                                );
                                formik.setFieldValue(
                                  `order_rows[${index}].price`,
                                  selectedProduct?.rate || "" // Set price or fallback to empty string if not found
                                );
                              }}
                              onBlur={formik.handleBlur}
                            >
                              <option value="">Select Product</option>
                              {products.map((role) => (
                                <option key={role.id} value={role.id}>
                                  {role.tally_name}
                                </option>
                              ))}
                            </select>

                            {formik.touched.order_rows?.[index]?.stock_id &&
                              formik.errors.order_rows?.[index]?.stock_id && (
                                <div className={classes.invalidDataError}>
                                  {formik.errors.order_rows[index].stock_id}
                                </div>
                              )}
                          </div>
                        </div>

                        {/* Quantity */}
                        <div className="col-lg-4 col-md-4 col-sm-12">
                          <div className="form-group">
                            <label>
                              Quantity:<span className="required">*</span>
                            </label>
                            <input
                              id={`order_rows[${index}].qty`}
                              type="number"
                              placeholder="Enter Quantity"
                              className="form-control"
                              name={`order_rows[${index}].qty`}
                              value={item.qty}
                              onChange={(event) =>
                                formik.setFieldValue(
                                  `order_rows[${index}].qty`,
                                  event.target.value
                                )
                              }
                              onBlur={formik.handleBlur}
                            />
                            {formik.touched.order_rows?.[index]?.qty &&
                              formik.errors.order_rows?.[index]?.qty && (
                                <div className={classes.invalidDataError}>
                                  {formik.errors.order_rows[index].qty}
                                </div>
                              )}
                          </div>
                        </div>

                        {/* Price */}
                        <div className="col-lg-4 col-md-4 col-sm-12">
                          <div className="form-group">
                            <label>
                              Price:<span className="required">*</span>
                            </label>
                            <input
                              id={`order_rows[${index}].price`}
                              type="number"
                              placeholder="Enter Price"
                              className="form-control"
                              name={`order_rows[${index}].price`}
                              value={item.price}
                              onChange={(event) =>
                                formik.setFieldValue(
                                  `order_rows[${index}].price`,
                                  event.target.value
                                )
                              }
                            />
                            {formik.touched.order_rows?.[index]?.price &&
                              formik.errors.order_rows?.[index]?.price && (
                                <div className={classes.invalidDataError}>
                                  {formik.errors.order_rows[index].price}
                                </div>
                              )}
                          </div>
                        </div>

                        {/* Shipping Address */}
                        <div className="col-lg-4 col-md-4 col-sm-12">
                          <div className="form-group">
                            <label>
                              Shipping Address:
                              <span className="required">*</span>
                            </label>
                            <Creatable
                              value={shippingAddress.find(
                                (address) =>
                                  address.value === item.shipping_address_id
                              )}
                              styles={customStyles}
                              onChange={(selectedOption) => {
                                handleShippingAddressChange(
                                  selectedOption,
                                  index
                                );
                              }}
                              options={shippingAddress}
                              onBlur={formik.handleBlur}
                              placeholder="Select or create a shipping address"
                            />
                            {formik.touched.order_rows?.[index]
                              ?.shipping_address_id &&
                              formik.errors.order_rows?.[index]
                                ?.shipping_address_id && (
                                <div className={classes.invalidDataError}>
                                  {
                                    formik.errors.order_rows[index]
                                      .shipping_address_id
                                  }
                                </div>
                              )}
                          </div>
                        </div>

                        {/* Dynamic Additional Inputs */}
                        {formik.values.order_rows[index]
                          ?.shipping_address_id === 0 && (
                          <>
                            <div className="col-lg-4 col-md-4 col-sm-12">
                              <div className="form-group">
                                <label>
                                  State:<span className="required"></span>
                                </label>
                                <select
                                  id={`order_rows[${index}].shipping_state`}
                                  className="form-control"
                                  name={`order_rows[${index}].shipping_state`}
                                  value={item.shipping_state}
                                  onChange={(event) => {
                                    setSelectedCity({
                                      name: event.target.value,
                                      index: index,
                                    });
                                    formik.setFieldValue(
                                      `order_rows[${index}].shipping_state`,
                                      event.target.value
                                    );
                                  }}
                                  onBlur={formik.handleBlur}
                                >
                                  <option value="" disabled selected>
                                    Select State
                                  </option>
                                  {shippingStates?.map((state, key) => (
                                    <option value={state}>{state}</option>
                                  ))}
                                </select>
                                {formik.touched.order_rows?.[index]
                                  ?.shipping_state &&
                                  formik.errors.order_rows?.[index]
                                    ?.shipping_state && (
                                    <div className={classes.invalidDataError}>
                                      {
                                        formik.errors.order_rows[index]
                                          .shipping_state
                                      }
                                    </div>
                                  )}
                              </div>
                            </div>

                            <div className="col-lg-4 col-md-4 col-sm-12">
                              <div className="form-group">
                                <label>
                                  City:<span className="required"></span>
                                </label>
                                <select
                                  id={`order_rows[${index}].shipping_city`}
                                  className="form-control"
                                  name={`order_rows[${index}].shipping_city`}
                                  value={item.shipping_city}
                                  onChange={(event) =>
                                    formik.setFieldValue(
                                      `order_rows[${index}].shipping_city`,
                                      event.target.value
                                    )
                                  }
                                  onBlur={formik.handleBlur}
                                >
                                  <option value="" disabled selected>
                                    Select City
                                  </option>
                                  {cityList[index] &&
                                    cityList[index].length > 0 &&
                                    cityList[index].map((city, key) => (
                                      <option
                                        key={key + "city1" + city.name}
                                        value={city.id}
                                      >
                                        {city.city}
                                      </option>
                                    ))}
                                </select>
                                {formik.touched.order_rows?.[index]
                                  ?.shipping_city &&
                                  formik.errors.order_rows?.[index]
                                    ?.shipping_city && (
                                    <div className={classes.invalidDataError}>
                                      {
                                        formik.errors.order_rows[index]
                                          .shipping_city
                                      }
                                    </div>
                                  )}
                              </div>
                            </div>

                            <div className="col-lg-4 col-md-4 col-sm-12">
                              <div className="form-group">
                                <label>
                                  Zip Code:<span className="required"></span>
                                </label>
                                <input
                                  id={`order_rows[${index}].shipping_zip`}
                                  name={`order_rows[${index}].shipping_zip`}
                                  type="text"
                                  minLength={6}
                                  maxLength={6}
                                  className="form-control"
                                  placeholder="Shipping Zip"
                                  onChange={(event) => {
                                    numberFilter(event);
                                  }}
                                  value={item.shipping_zip}
                                />
                                {formik.touched.order_rows?.[index]
                                  ?.shipping_zip &&
                                  formik.errors.order_rows?.[index]
                                    ?.shipping_zip && (
                                    <div className={classes.invalidDataError}>
                                      {
                                        formik.errors.order_rows[index]
                                          .shipping_zip
                                      }
                                    </div>
                                  )}
                              </div>
                            </div>
                          </>
                        )}

                        {/* Action Buttons */}
                        <div className="col-lg-4 col-md-4 col-sm-12">
                          <div className="form-group d-flex align-items-center mt-4">
                            <button
                              type="button"
                              className={`btn ${classes.addBtn}`}
                              onClick={() =>
                                formik.setFieldValue("order_rows", [
                                  ...formik.values.order_rows,
                                  {
                                    stock_id: "",
                                    price: "",
                                    qty: "",
                                    shipping_address_id: defaultBillingAddress,
                                  },
                                ])
                              }
                            >
                              <GrAddCircle />
                            </button>
                            {formik.values.order_rows.length > 1 && (
                              <button
                                type="button"
                                className={`btn ${classes.removeBtn} ml-2`}
                                onClick={() =>
                                  formik.setFieldValue(
                                    "order_rows",
                                    formik.values.order_rows.filter(
                                      (_, i) => i !== index
                                    )
                                  )
                                }
                              >
                                <GrSubtractCircle />
                              </button>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12"></div>
                      </React.Fragment>
                    ))}
                  </div>
                  ;{/* discount field */}
                  <div
                    className="row"
                    style={{ width: " 97%", margin: "auto" }}
                  >
                    {/* Discount Type */}
                    {/* <div className="col-lg-4 col-md-4 col-sm-12">
											<label>
												Discount Type:<span className="required"></span>
											</label>

											<div className="form-group">
												<select
													className="form-control"
													name="discount_type"
													onChange={(event) => SeasondropdownClick(event)}
												>
													<option>Select Discount Type</option>

													<option>Season Discount</option>
													<option>Product Discount</option>
													<option>Spacial Offer</option>
												</select>
											</div>
										</div> */}

                    {/* discount */}

                    {/* <div className="col-lg-4 col-md-4 col-sm-12">
											<label>
												Discount:<span className="required"></span>
											</label>

											<div className="form-group">
												<input
													type="number"
													className="form-control"
													name="discount"
													value={discount}
													onChange={(event) =>
														(event.target.value = changeDiscountHandler(
															event.target.value
														))
													}
												/>
											</div>
										</div> */}

                    {/* total Amount */}
                    {userData?.role != 4 ? (
                      <div className="col-lg-4 col-md-4 col-sm-12">
                        <label>
                          Total Order Amount:<span className="required"></span>
                        </label>

                        <div className="form-group">
                          <input
                            id="totalAmount"
                            type="number"
                            className="form-control"
                            name="totalAmount"
                            value={calculateTotalAmount()}
                            disabled
                          />
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {/* Remark for Dealer */}
                    <div className="col-lg-4 col-md-4 col-sm-12">
                      <label>
                        Remarks:<span className="required"></span>
                      </label>

                      <div className="form-group">
                        <input
                          id="remark"
                          type="text"
                          className="form-control"
                          name="remark"
                          placeholder="Enter Remarks"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.remark}
                        />
                      </div>
                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-12">
                      <label>
                        Image:<span className="required">*</span>
                      </label>
                      <div className="form-group">
                        <input
                          id="image"
                          name="image"
                          type="file"
                          accept="image/*,application/pdf"
                          className="form-control"
                          placeholder="Image *"
                          onChange={(e) =>
                            formik.setFieldValue(
                              "image",
                              e.currentTarget.files[0]
                            )
                          }
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.image && formik.errors.image ? (
                          <div className={classes.invalidDataError}>
                            {formik.errors.image}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    {/* Remark for area manager */}
                    {userData?.role == 3 ? (
                      <div className="col-lg-4 col-md-4 col-sm-12">
                        <label>
                          Area Manager Remark:<span className="required"></span>
                        </label>

                        <div className="form-group">
                          <input
                            id="area_manager_remark"
                            type="text"
                            className="form-control"
                            name="area_manager_remark"
                            placeholder="Enter Remark"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.area_manager_remark}
                          />
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {/* SeasonDrowpDown */}
                    {seasonDropDown ? (
                      <div className="col-lg-4 col-md-6 col-sm-12 px-4 ">
                        <div className="form-group">
                          <label>Season</label>
                          <select
                            id="season_id"
                            className="form-control "
                            name="season_id"
                            onChange={(event) => {
                              let discountPercentage = 0;
                              seasonList.map((seasons) => {
                                if (
                                  seasons.id === event.target.value &&
                                  seasons.discount_percentage != ""
                                ) {
                                  return (discountPercentage =
                                    seasons.discount_percentage);
                                }
                              });
                              formik.handleChange(event);
                              formik.setFieldValue(
                                "discount",
                                discountPercentage
                              );
                              setDiscount(discountPercentage);
                            }}
                            onBlur={formik.handleBlur}
                            value={formik.values.season_id}
                          >
                            <option value="">Select Season</option>
                            {seasonList.map((role) => (
                              <option key={role.name} value={role.id}>
                                {role.season_title}
                              </option>
                            ))}
                          </select>
                          {formik.touched.season_id &&
                          formik.errors.season_id ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.season_id}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {/* {logicticDropDown ? (
                      <div className="col-lg-4 col-md-6 col-sm-12 px-4 ">
                        <div className="form-group">
                          <label>Order Assign To :</label>
                          <select
                            id="logistic_user_id"
                            className="form-control "
                            name="logistic_user_id"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.logistic_user_id}
                          >
                            <option value="">Select Logistic Manager</option>
                            {logicticList.map((role) =>
                              role.id ? (
                                <option key={role.name} value={role.id}>
                                  {role.name}
                                </option>
                              ) : (
                                ""
                              )
                            )}
                          </select>
                          {formik.touched.logistic_user_id &&
                          formik.errors.logistic_user_id ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.logistic_user_id}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    ) : (
                      ""
                    )} */}
                  </div>
                  {/* close and submit button */}
                  <div className="col-12 submit-btn my-2">
                    <hr className="mt-4" />
                    <div className="table-responsive">
                      <table className="table table-striped"></table>
                    </div>
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-dismiss="modal"
                      onClick={() => navigate("/admin/order")}
                    >
                      Close
                    </button>
                    <button type="submit" className="btn btn-primary ml-2">
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddOrder;
